import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterBy",
})
export class FilterByPipe implements PipeTransform {
  transform(value: any, targetProperty: string | string[], searchValue: string): any {
    if (!searchValue) return value;

    return value.filter((val) => {
      // Handle array of properties
      if (Array.isArray(targetProperty)) {
        return targetProperty.some(prop => {
          let propValue = val[prop];
          
          if (propValue === undefined || propValue === null) propValue = "";
          
          return propValue.toString().toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
        });
      } 
      // Handle single property (original behavior)
      else {
        let targetVal = val[targetProperty];
        
        if (targetVal === undefined || targetVal === null) targetVal = "";
        
        return targetVal.toString().toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
      }
    });
  }
}
