import {
  Component,
  Input,
  OnInit,
  AfterViewChecked,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";

import { UntypedFormControl } from "@angular/forms";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";

import * as moment from "moment";
import { DateTime, IANAZone } from "luxon";
import * as timezoneFixes from "src/assets/timezones/ianaToAbbreviation.json";

import { CoolLocalStorage } from "@angular-cool/storage";
import { TitleCasePipe } from "@angular/common";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";
import { Subscription } from "rxjs";
import { IMyOptions } from "ng-uikit-pro-standard";
import zoomPlugin from "chartjs-plugin-zoom";
import "hammerjs";
import "chartjs-plugin-zoom";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';
Chart.register(zoomPlugin);

export class chartDates {
  constructor(public start: string, public end: string) { }
}

import {
  faPhotoVideo,
  faUsersCog,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { EditUserComponent } from "../../user-management/user-catalog/modals/edit-user/edit-user.component";
import { ActivatedRoute } from "@angular/router";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { MetricsServicesService } from "../../metrics/services/metrics-services.service";

@Component({
  selector: "app-welcome-page",
  templateUrl: "./welcome-page.component.html",
  styleUrls: ["./welcome-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class WelcomePageComponent implements OnInit {
  @ViewChild("sessionsChart") private chartRef: ElementRef;
  private chart: Chart;

  //timezone
  public tz: any = moment.tz(moment.tz.guess()).format("z");

  public teamID: number;
  public clientCode: string;
  public copy: string = "";
  public labels: any;
  public user: any;
  public usersCount: number;
  public metrics: {
    events: number;
    users: number;
    assets: number;
  } = {
      events: 0,
      users: 0,
      assets: 0,
    };
  public token: string;
  public assetPrefix: string = "/assets/json";
  public clientSettings: any;

  public team: any;
  public users: any;
  public teamLoading: boolean = true;
  public metricsLoading: boolean = true;

  //subscriptions
  private shareDataSubscription: Subscription;

  //modal options
  public manageAccountFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  //icons
  public faPhotoVideo = faPhotoVideo;
  public faUsersCog = faUsersCog;
  public faCalendarDay = faCalendarDay;

  //chart parameters
  public start;
  public end;
  public breakdownType;

  //persistent
  //chart vars - sample data
  public chartLoading: boolean = true;
  public chartLabel = "";
  public chartType: string = "horizontalBar";
  public chartDatasets: Array<any> = [
    {
      data: [],
      label: "",
      lineTension: 0,
    },
  ];
  public chartLabels: Array<any> = [];
  public chartColors: Array<any> = [
    {
      backgroundColor: ["rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(54, 162, 235, 1)"],
      borderWidth: 2,
    },
  ];
  public chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // public maxDate = moment();
  public model = new chartDates("", "");
  public startDateLabel: string = "Start Date";
  public endDateLable: string = "End Date";

  // charts options
  //chart vars - date selection chart
  public date = new Date();
  public disableDatesObj = { year: 0, month: 0, day: 0 };
  public disabledUntil = { year: 0, month: 0, day: 0 };
  public myDatePickerOptions: IMyOptions = {
    alignSelectorRight: true,
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableSince: this.disableDatesObj,
  };
  public endDateoickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableUntil: this.disabledUntil,
    disableSince: this.disableDatesObj,
  };

  public metricsLoadingDC: boolean = true;
  private chartCreated = false;
  private chartData: any;
  public dateAggregateTitle = "";
  public dateAggregates: {
    value: number;
    label: string;
  }[];
  public chartLabelDC = "";
  public chartLabelsDC: Array<any> = [];
  public chartOptionsDC: any = {
    indexAxis: "x",
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Total Connections",
        },
      },
      x: {
        title: {
          display: true,
          text: "Time Frame",
        },
      },
    },
    interaction: {
      mode: "point",
    },
    //onClick: function adjustLineEndPoints(context) {  }
  };
  public timeDenomination = [
    { value: "hour", label: "Hourly" },
    { value: "day", label: "Daily" },
    { value: "month", label: "Monthly" },
    { value: "year", label: "Yearly" },
  ];

  public timeSelector: UntypedFormControl = new UntypedFormControl("day");

  //chart vars - line data test
  public chartTypeLT: string = "bar";
  public chartDatasetsLT: Array<any> = [];
  public chartLabelsLT: Array<any> = [];

  public yLabels: Array<string>;

  public chartOptionsLT: any = {};
  public chartColorsLT: Array<any> = [
    {
      backgroundColor: ["#7F95D1"],
      borderColor: ["#7F95D1"],
    },
  ];

  constructor(
    private _clientManagementService: ClientManagementService,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private TitleCase: TitleCasePipe,
    private _settingsService: SettingsService,
    private modalService: MDBModalService,
    private _sharedDataService: SharedDataService,
    private route: ActivatedRoute,
    private _notificationsService: NotificationsService,
    private _metricsServicesService: MetricsServicesService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {

      this.retrieveTeamID();
      this.retrieveClientCode();
      this.retrieveUser();
      this.retrieveToken();
      this.retrieveLabels();
      this.resolveTimeZone();

      //also let menu know that we're done loading
      let toSend = {
        type: "pageLoaded",
        data: {
          pageLoaded: true,
        },
      };

      this._sharedDataService.sendSharedData(toSend);

      this.retrieveData();

      this.clientSettings = this._settingsService.getSettingsFromStorage(
        this.teamID
      );

      let content = this._settingsService.getContentFromStorage(this.teamID);
      this.copy = content.dashboardMain.content_value;

      console.log("params", params);
      this.chartInit(params);

    });

    this.shareDataSubscription =
      this._sharedDataService.sharedDataComm$.subscribe((incoming: any) => {
        if (incoming.type === "buttonAction") {
          if (
            incoming.data.buttonAction !== undefined &&
            incoming.data.buttonAction === "manage_account_details"
          ) {
            this.openAccountDetails();
          }
        }
      });
  }

  ngOnDestroy() {
    console.log("destroying subscription from welcome page");
    this.shareDataSubscription.unsubscribe();
  }

  ngAfterViewChecked() {
    if (!this.chartCreated && this.chartRef && this.chartRef.nativeElement && !this.chartLoading) {
      this.chartCreated = true;

      console.log("creating chart");

      this.chart = new Chart(this.chartRef.nativeElement, {
        type: "bar",
        data: this.chartData,
        options: this.chartOptionsDC,
      });
    }
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private async retrieveData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let team = await this.retrieveTeam(options).catch((error) => {
      ;
    });

    this.team = team;
    this.teamLoading = false;

    if (this.user.role_type_id !== 1 && this.user.role_type_id !== 2) return true;

    let retreiveUsers = await this.retrieveUsers(options).catch((error) => {
      ;
    });

    this.users = retreiveUsers;
    this.usersCount = retreiveUsers.length;
    this.metrics.users = this.usersCount;

    let retrieveEvents = await this.retrievEeventCount(options).catch(
      (error) => {
        ;
      }
    );

    this.metrics.events = retrieveEvents.count;

    let retrieveMedia = await this.retrieveAssetsCount(options).catch(
      (error) => {
        ;
      }
    );

    this.metrics.assets = retrieveMedia.assets;

    this.metricsLoading = false;
  }

  private retrieveUsers(options) {
    let retrieveUsers = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "users",
      this.teamID,
      options,
      true
    );

    return retrieveUsers.toPromise();
  }

  private retrieveTeam(options) {
    let retrieveTeam = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID,
      "get",
      {},
      options
    );

    return retrieveTeam.toPromise();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrievEeventCount(options) {
    let eventRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      `/teams/events/${this.teamID}/count`,
      "get",
      {},
      options
    );

    return eventRetrieve.toPromise();
  }

  private retrieveAssetsCount(options) {
    let assetRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      `/team/${this.teamID}/assets/count`,
      "get",
      {},
      options
    );

    return assetRetrieve.toPromise();
  }

  private async chartInit(params) {
    let start_date = params["start_date"];
    let end_date = params["end_date"];
    let breakdownType = params["breakdownType"];
    if (breakdownType != undefined) {
      this.timeSelector = new UntypedFormControl(breakdownType);
    }
    if (start_date != undefined && end_date != undefined) {
      this.model = new chartDates(
        moment(start_date).format("MMM D, YYYY"),
        moment(end_date).format("MMM D, YYYY")
      );
      await this.setupDateChart();
    } else {
      // const date = new Date()
      const today = moment();
      // called moment funct because the local var gets reasigned
      this.model = new chartDates(
        today.subtract(7, "d").format("MMM D, YYYY"),
        moment().format("MMM D, YYYY")
      );
      await this.setupDateChart();
    }

    this.disabledDates(moment());
    this.generateUntilDate();
  }

  public openAccountDetails() {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "add-users-container",
      class: this.modalOptions.class + " modal-full-height modal-right",
    };

    this.modalOptions.data = {
      user: this.user,
      users: this.users,
      targetUser: this.user,
      teamID: this.teamID,
      action: "update-self",
      domain: "default",
      sourceType: "account",
    };

    this.manageAccountFrame = this.modalService.show(
      EditUserComponent,
      this.modalOptions
    );

    this.manageAccountFrame.content.outgoing.subscribe((changedData) => {
      this.user = changedData.user;
      this.coolLocalStorage.setObject("admin_panel_userinfo", this.user);
    });
  }

  public async setupDateChart() {
    this.metricsLoadingDC = true;

    console.log("setupDateChart");

    try {
      const chartResults = await this._metricsServicesService.setupDateChart(
        this.chart,
        this.model,
        this.timeSelector,
        this.timeDenomination,
        this.chartRef,
        this.chartData,
        this.labels
      );

      console.log("chartResults", chartResults);

      // Now you have all the data and everything is complete
      this.chart = chartResults.chart;
      this.start = chartResults.start;
      this.end = chartResults.end;
      this.breakdownType = chartResults.breakdownType;
      this.chartLabelDC = chartResults.chartLabelDC;
      this.chartLabelsDC = chartResults.chartLabelsDC;
      this.dateAggregateTitle = chartResults.dateAggregateTitle;
      this.dateAggregates = chartResults.dateAggregates;
      this.chartData = chartResults.chartData;
      this.chartOptionsDC = chartResults.chartOptionsDC;
      this.chartCreated = chartResults.chartCreated;
      this.timeDenomination = chartResults.timeDenomination;
      this.timeSelector = chartResults.timeSelector;

    } catch (error) {
      console.log("error in setupDateChart", error);
      this._notificationsService.errorNotification("There was a problem loading the analytics data. Please refresh and try again.")
    } finally {
      //paginate sessions
      this.chartLoading = false;
    }
  }

  public disabledDates(currentDate: any) {
    let today = currentDate;
    let endDays = [28, 29, 30, 31];
    // handle year
    if (today.month() === 11 && today.date() === 31) {
      this.disableDatesObj.year = today.year() + 1;
    } else {
      this.disableDatesObj.year = today.year();
    }
    // handle month
    // handle day
    if (endDays.every((day) => day === today.date())) {
      // I need to find a way to test this
      this.disableDatesObj.day = 1;
      this.disableDatesObj.month = today.month() + 2;
    } else {
      this.disableDatesObj.day = today.date() + 1;
      this.disableDatesObj.month = today.month() + 1;
    }
  }

  // todo: edgecases for first dat of the month, and or last day of the month.
  public generateUntilDate() {
    // let today = currentDate;
    let untilDate = this.model.start;
    this.disabledUntil.year = parseInt(moment(untilDate).format("YYYY"));
    this.disabledUntil.day = parseInt(moment(untilDate).format("DD")) - 1;
    this.disabledUntil.month = parseInt(moment(untilDate).format("MM"));
  }

  private resolveTimeZone() {
    let tz_iana = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.tz = moment.tz(tz_iana).format("z");

    //if the string includes a negative number in the form -03 or -02, check for a timezone fix
    if (this.tz.includes("-")) {
      let fix = timezoneFixes[tz_iana];

      if (fix !== undefined) {
        this.tz = fix;
      } else {
        //fall back to UTC from Luxon
        this.tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
      }
    } else if (this.tz.includes("+")) {
      //fall back to UTC from Luxon
      //@todo: update timezone fixes to include plus returns
      this.tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
    }
  }

  public checkDateRange(event, dateField, startDate?, endDate?) {

    console.log("startDate", startDate);
    console.log("endDate", endDate);

    let incoming = this._metricsServicesService.checkDateRange(
      event,
      dateField,
      this.timeDenomination,
      this.timeSelector,
      this.model,
      startDate,
      endDate
    );

    this.timeDenomination = incoming.timeDenomination;
    this.timeSelector = incoming.timeSelector;

    if (dateField === "start") {
      let yesterday = moment(event.actualDateFormatted).subtract(1, "days").format("M-D-YYYY");
      let parseYesterday = yesterday.split("-");
      this.endDateoickerOptions = {
        ...this.endDateoickerOptions,
        disableUntil: {
          year: parseInt(parseYesterday[2]),
          month: parseInt(parseYesterday[0]),
          day: parseInt(parseYesterday[1]),
        },
      };
    }
  }
}
