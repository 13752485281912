<video
  #target
  class="video-js"
  controls
  muted
  playsinline
  preload="none"
></video>
<div
  *ngIf="thumbnailsLoading"
  class="loading-screen d-flex align-items-center h-100 w-100 justify-content-center with-background"
>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
