<div class="interactive-analytics-card aia-analytics">
  <div
    class="d-flex justify-content-between align-items-center w-100 metrics-controls analytics-info"
  >
    <p class="mb-0">Scenario: {{ eventInfo }}</p>
  </div>
  <div
    class="d-flex justify-content-between align-items-center w-100 metrics-controls"
  >
    <div class="back-button">
      <a
        mdbBtn
        class="btn-flat p-0 m-0 back-button"
        mdbWavesEffect
        [routerLink]="backRoute"
        [queryParams]="queryParams"
        mdbTooltip="Back to AIA Session"
        placement="bottom"
      >
        <mdb-icon fas icon="chevron-left"></mdb-icon>
        Back
      </a>
    </div>
  </div>

  <div class="row justify-content-start flex-row metrics-controls">
    <div
      class="col-9 offset-3 d-flex flex-nowrap justify-content-end align-items-center"
    >
      <div class="md-form start-picker">
        <mdb-date-picker
          [inline]="true"
          name="start"
          [options]="myDatePickerOptions"
          [placeholder]="'Selected ' + labels.startTime.singular"
          [(ngModel)]="model.start"
          id="start-date"
          onkeydown="return false"
          [label]="startDateLabel"
          (dateChanged)="checkDateRange($event, 'start')"
        ></mdb-date-picker>
        <!-- <label [class.active]="start" for="start">Start Date</label> -->
      </div>
      <div class="md-form end-picker">
        <mdb-date-picker
          [inline]="true"
          name="end"
          [options]="myDatePickerOptions"
          [placeholder]="'Selected ' + labels.endTime.singular"
          [(ngModel)]="model.end"
          id="end-date"
          onkeydown="return false"
          [label]="endDateLable"
          (dateChanged)="checkDateRange($event, 'end')"
        ></mdb-date-picker>
      </div>
      <div class="md-form denomination">
        <mdb-select
          name="time-denomination"
          [options]="timeDenomination"
          [formControl]="timeSelector"
        >
        </mdb-select>
        <label for="time-denomination">Time Interval</label>
      </div>
      <button
        class="theme-modal-button btn update-btn"
        data-dismiss="modal"
        type="button"
        mdbBtn
        mdbWavesEffect
        (click)="retrieveData()"
      >
        Update Chart
      </button>
    </div>
  </div>
  <div class="row d-flex flex-nowrap">
    <div
      class="col-3 d-flex justify-content-center flex-row metrics-aggregates"
    >
      <div class="aggregate-container w-100">
        <h3 class="text-center">Aggregate Values</h3>
        <p *ngIf="gridLoading" class="mb-0 loading-text mt-0">Loading...</p>
        <table *ngIf="!gridLoading" mdbTable striped="true">
          <tbody>
            <tr *ngFor="let aggregate of dateAggregates">
              <td class="aggregate-value semi-bold">{{ aggregate.label }}</td>
              <td class="aggregate-label">{{ aggregate.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-9">
      <h3 class="text-center">Sessions over time</h3>
      <p *ngIf="gridLoading" class="mb-0 loading-text mt-0">Loading...</p>
      <div
        *ngIf="!gridLoading"
        class="chart-container"
        style="position: relative; height: 42vh; width: auto"
      >
        <canvas #sessionsChart> </canvas>
      </div>
    </div>
  </div>
  <div class="readquest-analytics-highlevel analytics-card">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <h4 class="text-center meeting-header">AI Sessions</h4>
        <button
          class="theme-modal-button btn update-btn download-csv-btn"
          data-dismiss="modal"
          type="button"
          mdbBtn
          mdbWavesEffect
          (click)="onDownloadCSV()"
        >
          Download CSV
        </button>
      </div>
    </div>
    <ng-container *ngIf="gridLoading">
      <div class="row">
        <div class="col-12">
          <!-- if content hasnt load -->
          <div
            class="loading-card d-flex align-content-center justify-content-center"
          >
            <div
              class="d-flex justify-content-center"
              *ngIf="errorRetrievingMsg === ''"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              class="d-flex justify-content-center"
              *ngIf="errorRetrievingMsg !== ''"
            >
              <div
                class="alert alert-danger"
                role="alert"
                [innerHTML]="errorRetrievingMsg"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!gridLoading">
      <ag-grid-angular
        style="width: 100%; height: 100%"
        [columnDefs]="columnDefs"
        [autoSizeStrategy]="autoSizeStrategy"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData"
        [class]="themeClass"
        (cellClicked)="onCellClicked($event)"
        (gridReady)="onGridReady($event)"
      ></ag-grid-angular>
    </ng-container>
  </div>
</div>
