<div id="modelViewerHolder"></div>
<div class="upload-embed">
  <button
    class="theme-modal-button btn"
    type="button"
    mdbBtnp
    mdbWavesEffect
    [disabled]="tableLoading"
    (click)="openAlternateUploadModal()"
  >
    <span class="action-icon"> <fa-icon [icon]="faUpload"></fa-icon></span>
    <span class="btn-title">Upload {{ labels.media.plural }}</span>
  </button>
</div>
<div id="mediaManagement" class="management-card media-table-card content-card">
  <div class="row flex-row flex-nowrap">
    <div class="col-12 p-0">
      <div class="table-responsive">
        <!--loding screen-->
        <div
          class="management-card media-table-card content-card loading-card"
          *ngIf="tableLoading"
        >
          <div class="row">
            <div class="col-12">
              <!-- if content hasnt load -->
              <div
                class="loading-screen d-flex align-content-center justify-content-center"
              >
                <div
                  class="d-flex justify-content-center"
                  *ngIf="errorRetrievingMsg === ''"
                >
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-center"
                  *ngIf="errorRetrievingMsg !== ''"
                >
                  <div
                    class="alert alert-danger"
                    role="alert"
                    [innerHTML]="errorRetrievingMsg"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end loading screen-->
        <table
          class="table table-striped"
          mdbTable
          #tableEl="mdbTable"
          small="true"
        >
          <colgroup class="media">
            <col
              *ngFor="let head of headElements; let i = index"
              [ngClass]="'col-' + sort_elements[i].head"
            />
          </colgroup>
          <thead class="theme-base-medium-dark white-text">
            <tr class="sorting">
              <th
                *ngFor="let head of headElements; let i = index"
                scope="col"
                [ngClass]="sort_elements[i].head"
              >
                <div class="header-wrapper">
                  <span class="text-ellipsis" *ngIf="head !== 'action'">{{
                    head
                  }}</span>
                  <ng-template [ngIf]="sort_elements[i].type !== 'none'">
                    <mdb-icon
                      fas
                      *ngIf="sortBy !== sort_elements[i].head"
                      icon="sort"
                      class="sort-icon"
                      [mdbTooltip]="'Sort by ' + head"
                      placement="bottom"
                      (click)="
                        sortTable(
                          sort_elements[i].head,
                          'desc',
                          sort_elements[i].type
                        )
                      "
                    ></mdb-icon>
                    <mdb-icon
                      *ngIf="
                        sortBy == sort_elements[i].head && sortOrder == 'asc'
                      "
                      (click)="
                        sortTable(
                          sort_elements[i].head,
                          'desc',
                          sort_elements[i].type
                        )
                      "
                      fas
                      icon="caret-up"
                      class="sort-icon"
                    ></mdb-icon>
                    <mdb-icon
                      *ngIf="
                        sortBy == sort_elements[i].head && sortOrder == 'desc'
                      "
                      (click)="
                        sortTable(
                          sort_elements[i].head,
                          'asc',
                          sort_elements[i].type
                        )
                      "
                      fas
                      icon="caret-down"
                      class="sort-icon"
                    ></mdb-icon>
                  </ng-template>
                </div>
              </th>
            </tr>
            <tr class="filters media-filters">
              <th
                *ngFor="let head of headElements; let i = index"
                scope="col"
                [ngClass]="sort_elements[i].head"
              >
                <ng-template
                  [ngIf]="filter_elements[i].filter && !filtersLoading"
                >
                  <!--all filter types except media_select-->
                  <ng-container
                    *ngIf="
                      filter_elements[i].type !== 'media_select' &&
                      filter_elements[i].type !== 'media_autocomplete' &&
                      filter_elements[i].type !== 'media_select_added'
                    "
                  >
                    <div
                      class="md-form md-filter-input mb-0 mt-0"
                      [ngClass]="'md-filter-' + filter_elements[i].type"
                    >
                      <!--start text filter-->
                      <input
                        *ngIf="filter_elements[i].type === 'text'"
                        type="text"
                        [(ngModel)]="filterText[filter_elements[i].target]"
                        class="form-control text-ellipsis"
                        [id]="'filter-' + filter_elements[i].target"
                        [placeholder]="filter_elements[i].placeholder"
                        (ngModelChange)="onFilterChange(true)"
                        mdbInput
                      />
                      <!--end text filter-->
                      <!--start start_at filter-->
                      <mdb-date-picker
                        *ngIf="filter_elements[i].type === 'created'"
                        #datePickerStart
                        class="created-picker"
                        name="createdDateFilter"
                        [id]="'filter-' + filter_elements[i].target"
                        [options]="createdCalendarOptions"
                        placeholder="Select Date"
                        [inline]="true"
                        inlineIcon="fas fa-calendar-alt"
                        (dateChanged)="onDateChange($event)"
                        (inputFocusBlur)="onDateFocusBlur($event)"
                      ></mdb-date-picker>
                      <!--end start_at filter-->
                      <fa-icon
                        class="cursor-pointer"
                        *ngIf="
                          filterText[filter_elements[i].target] !== '' &&
                          filterText[filter_elements[i].target] !== null
                        "
                        [icon]="faTimes"
                        (click)="resetFilter(i)"
                      ></fa-icon>
                    </div>
                  </ng-container>
                  <!--end all filter types except media_select-->
                  <!--start media_select filter type-->
                  <div
                    class="md-form md-filter-input mb-0 mt-0 w-100"
                    *ngIf="filter_elements[i].type === 'media_select'"
                    [ngClass]="{
                      'md-filter-select': !selectable,
                      disabled: filter_elements[i].disabled
                    }"
                  >
                    <!--start media_select filter-->
                    <mdb-select-2
                      class="custom-arrow w-100"
                      [disabled]="filter_elements[i].disabled"
                      [id]="'filter-' + filter_elements[i].target"
                      [dropdownClass]="'media-select-dropdown'"
                      [(ngModel)]="filterText[filter_elements[i].target]"
                      (ngModelChange)="onFilterChange(true)"
                    >
                      <mdb-select-option
                        *ngFor="let option of filter_elements[i].options"
                        [value]="option.value"
                        >{{ option.label }}</mdb-select-option
                      >
                    </mdb-select-2>
                    <!--end media_select filter-->
                    <fa-icon
                      class="cursor-pointer"
                      *ngIf="filterText[filter_elements[i].target] !== 'all'"
                      [icon]="faTimes"
                      (click)="
                        filter_elements[i].disabled ? false : resetFilter(i)
                      "
                    ></fa-icon>
                  </div>
                  <!--end media_select filter type-->
                  <!--start media autocomplet filter type-->
                  <div
                    class="md-form md-filter-input md-filter-access mb-0 mt-0"
                    *ngIf="filter_elements[i].type === 'media_autocomplete'"
                  >
                    <form [formGroup]="ownerForm" class="mt-0 modal-controlled">
                      <div class="md-form ap-autocomplete">
                        <input
                          type="text"
                          id="owner"
                          formControlName="ownerSelection"
                          placeholder="Select Owner"
                          [mdbAutoCompleter]="ownerAuto"
                          class="completer-input form-control mdb-autocomplete"
                          (click)="maybeClearOwners($event)"
                          (blur)="maybeResetOwners($event)"
                          (clearBtnClicked)="onClearButtonClick()"
                          mdbInput
                        />
                        <mdb-auto-completer
                          [displayValue]="onOwnerDisplayValue"
                          #ownerAuto="mdbAutoCompleter"
                          textNoResults="No results found"
                          [clearButton]="true"
                          (selected)="onOwnerSelected($event)"
                        >
                          <mdb-option
                            *ngFor="let option of ownerResults | async"
                            [value]="option"
                          >
                            {{ option.label }}
                          </mdb-option>
                        </mdb-auto-completer>
                      </div>
                    </form>
                  </div>
                  <div
                    class="md-form md-filter-input mb-0 mt-0 w-100"
                    [ngClass]="'md-filter-' + filter_elements[i].type"
                    *ngIf="
                      filter_elements[i].type === 'media_select_added' &&
                      selectable &&
                      selectableType === 'multiple'
                    "
                  >
                    <!--start added filter-->
                    <mdb-select-2
                      class="custom-arrow w-100"
                      id="addedFilter"
                      [dropdownClass]="'media-select-dropdown'"
                      [(ngModel)]="addedFilterText"
                      (ngModelChange)="onFilterChange(true)"
                    >
                      <mdb-select-option
                        *ngFor="let option of addedFilterOptions"
                        [value]="option.value"
                        >{{ option.label }}</mdb-select-option
                      >
                    </mdb-select-2>
                    <!--end added filter-->
                    <fa-icon
                      class="cursor-pointer"
                      *ngIf="addedFilterText !== 'all'"
                      [icon]="faTimes"
                      (click)="resetAddedFilter()"
                    ></fa-icon>
                  </div>
                </ng-template>
              </th>
            </tr>
          </thead>
          <tbody #row [ngClass]="{ transparent: tableLoading }">
            <tr *ngIf="media.length == 0">
              <td class="action-icons">
                <div class="no-media">
                  <p class="loading-text-alt">
                    <ng-template [ngIf]="emptyMedia.allMedia">
                      <ng-container *ngIf="!selectable">
                        <span
                          >No {{ labels.media.singular }} uploaded yet -
                        </span>
                        <span class="color-theme-base-medium"
                          ><button
                            (click)="openAlternateUploadModal()"
                            class="inline"
                            mdbBtn
                            type="button"
                            flat="true"
                            size="lg"
                            mdbWavesEffect
                          >
                            &nbsp;upload {{ labels.media.singular }} now
                          </button></span
                        >
                      </ng-container>
                      <ng-container *ngIf="selectable">
                        <span
                          >No {{ labels.media.singular }} added to this
                          {{ labels.event.singular }} yet -
                        </span>
                        <span class="color-theme-base-medium"
                          ><button
                            (click)="resetAddedFilter()"
                            class="inline"
                            mdbBtn
                            type="button"
                            flat="true"
                            size="lg"
                            mdbWavesEffect
                          >
                            &nbsp;add {{ labels.media.singular }} now
                          </button></span
                        >
                      </ng-container>
                    </ng-template>
                    <span *ngIf="!emptyMedia.allMedia"
                      >No {{ labels.media.singular }} for this query
                    </span>
                  </p>
                </div>
              </td>
              <td class="thumbnail-container"></td>
              <td class="name text-content"></td>
              <td class="size text-content"></td>
              <td class="type text-content"></td>
              <td class="owner_username text-content"></td>
              <td class="created text-content"></td>
            </tr>
            <tr
              mdbTableCol
              *ngFor="let el of tableMedia; let i = index"
              class="media-row"
            >
              <td class="action-icons">
                <div class="action-icons">
                  <ng-container *ngIf="!selectable">
                    <span class="btn-group list-btns-sm btn-icons">
                      <button
                        size="lg"
                        mdbBtn
                        mdbWavesEffect
                        class="btn-flat btn-icon"
                        mdbTooltip="View {{ labels.media.singular }} Details"
                        placement="bottom"
                        (click)="openViewModal(user, el, i)"
                      >
                        <fa-icon [icon]="faInfoCircle"></fa-icon>
                      </button>
                      <button
                        size="lg"
                        mdbBtn
                        mdbWavesEffect
                        [disabled]="
                          isModerator &&
                          !el.public &&
                          el.current_owner !== user.id
                        "
                        class="btn-flat btn-icon"
                        mdbTooltip="View {{ labels.media.singular }} Sharing"
                        placement="bottom"
                        (click)="openShareModal(user, el)"
                      >
                        <fa-icon [icon]="faShareAlt"></fa-icon>
                      </button>
                      <button
                        size="lg"
                        mdbBtn
                        mdbWavesEffect
                        [disabled]="isParticipant"
                        class="btn-flat btn-icon"
                        mdbTooltip="Add {{ labels.media.singular }} to {{
                          labels.event.plural
                        }}"
                        placement="bottom"
                        (click)="openAttachedEventsModal(el, i)"
                      >
                        <fa-icon [icon]="faCalendarPlus"></fa-icon>
                      </button>
                      <button
                        *ngIf="el.public"
                        size="lg"
                        mdbBtn
                        mdbWavesEffect
                        [disabled]="
                          (isModerator && el.current_owner !== user.id) ||
                          isParticipant
                        "
                        class="btn-flat btn-icon"
                        mdbTooltip="Public: click to make private"
                        placement="bottom"
                        (click)="changeVisibility(false, el)"
                      >
                        <fa-icon [icon]="faEye"></fa-icon>
                      </button>
                      <button
                        *ngIf="!el.public"
                        size="lg"
                        mdbBtn
                        mdbWavesEffect
                        [disabled]="
                          (isModerator && el.current_owner !== user.id) ||
                          isParticipant
                        "
                        class="btn-flat btn-icon"
                        mdbTooltip="Private: click to make public"
                        placement="bottom"
                        (click)="changeVisibility(true, el)"
                      >
                        <fa-icon [icon]="faEyeSlash"></fa-icon>
                      </button>
                      <button
                        size="lg"
                        mdbBtn
                        mdbWavesEffect
                        [disabled]="
                          (isParticipant || isModerator) &&
                          el.current_owner !== user.id
                        "
                        class="btn-flat btn-icon"
                        mdbTooltip="Delete {{ labels.media.singular }}"
                        placement="bottom"
                        (click)="clickDeleteAsset(el, i)"
                      >
                        <fa-icon [icon]="faTrashAlt"></fa-icon>
                      </button>
                    </span>
                  </ng-container>
                  <ng-container
                    *ngIf="selectable && selectableType === 'multiple'"
                  >
                    <div class="selected-icons text-center">
                      <!-- icon to remove if selected -->
                      <!-- cna I get a removeItem method that can removed the select item or items if multiple-->
                      <a
                        *ngIf="el.selected"
                        (click)="clickSelected(el, i)"
                        class="minus-square"
                        placement="bottom"
                      >
                        <fa-icon [icon]="faSquareMinus"></fa-icon>
                      </a>
                      <!-- icon to select if not selected "default" -->
                      <a
                        *ngIf="!el.selected"
                        (click)="clickSelected(el, i)"
                        mdbTooltip="Add Selection"
                        class="plus-square"
                        placement="bottom"
                      >
                        <fa-icon [icon]="faSquarePlus"></fa-icon>
                      </a>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="selectable && selectableType === 'single'"
                  >
                    <div class="selected-icons">
                      <!-- icon to remove if selected -->
                      <!-- cna I get a removeItem method that can removed the select item or items if multiple-->
                      <a
                        *ngIf="el.selected"
                        (click)="clickSingleSelected(el, i)"
                        class="minus-square"
                        mdbTooltip="Remove Selection"
                        placement="bottom"
                      >
                        <fa-icon [icon]="faCircle"></fa-icon>
                      </a>
                      <!-- icon to select if not selected "default" -->
                      <a
                        *ngIf="!el.selected"
                        (click)="clickSingleSelected(el, i)"
                        mdbTooltip="Add Selection"
                        class="select-area"
                        placement="bottom"
                      >
                        <fa-icon [icon]="faCircleInverse"></fa-icon>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </td>
              <td class="thumbnail-container">
                <div
                  (click)="openViewModal(user, el, i)"
                  class="img-thumbnail"
                  [style.background-image]="
                    el.thumbnail_asset_url
                      ? 'url(' + el.thumbnail_asset_url + ')'
                      : !el.thumbnail_asset_url && el.file_ext === 'pdf'
                      ? 'url(' + placeHolderPdf + ')'
                      : !el.thumbnail_asset_url && el.file_ext === 'mp4'
                      ? 'url(' + placeHolderMp4 + ')'
                      : !el.thumbnail_asset_url && el.file_ext === 'webp'
                      ? 'url(' + placeHolderWebp + ')'
                      : !el.thumbnail_asset_url && el.file_ext === 'wav'
                      ? 'url(' + placeHolderWav + ')'
                      : !el.thumbnail_asset_url && el.file_ext === 'mp3'
                      ? 'url(' + placeHolderMp3 + ')'
                      : !el.thumbnail_asset_url && el.file_ext === 'png'
                      ? 'url(' + placeHolderImg + ')'
                      : 'url(' + placeHolderImg + ')'
                  "
                >
                  <div *ngIf="el.is_360" class="icon-holder is-360">
                    <img
                      *ngIf="el.type === 'image'"
                      src="assets/img/images_360.png"
                      alt="360 icon"
                    />
                    <img
                      *ngIf="el.type === 'video'"
                      src="assets/img/videos_360.png"
                      alt="360 icon"
                    />
                  </div>
                  <div class="icon-holder">
                    <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                  </div>
                </div>
              </td>

              <td class="name text-content">
                <span class="ellipsis-wrapper">
                  <span class="text-ellipsis">{{ el.name }}</span>
                </span>
              </td>
              <td class="size text-content">
                <span *ngIf="el.size >= 5000"
                  >{{ el.size | mediaSize : "mb" }} MB</span
                >
                <span *ngIf="el.size < 5000">< 0.01MB</span>
              </td>
              <td class="type text-content">
                <div class="chip" [class]="'type-' + el.type">
                  {{ el.type | titlecase }}
                </div>
              </td>
              <td class="owner_username text-content">
                {{ el.owner_username }}
              </td>
              <td class="created text-content">
                <span class="date">{{
                  el.created | amDateFormat : "MMM D YYYY"
                }}</span>
                <br />
                <span class="time"
                  >{{ el.created | amDateFormat : "h:mm a" }} {{ tz }}</span
                >
              </td>
            </tr>
          </tbody>
          <tfoot
            class="grey lighten-5 w-100"
            *ngIf="tab_current_total !== undefined"
          >
            <tr>
              <td colspan="8">
                <div class="flex align-items-center justify-content-start">
                  <p-paginator
                    (onPageChange)="onPageChange($event)"
                    [first]="first"
                    [rows]="rows"
                    [totalRecords]="tab_current_total"
                    [showFirstLastIcon]="true"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="{first} - {last} of {totalRecords}"
                  ></p-paginator>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
