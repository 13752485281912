<div class="client-settings-page page">
  <div class="row header-row">
    <div class="col-md-12">
      <div class="title-callout">
        <div class="callout-headers-wrapper">
          <div class="callout-headers float-left">
            <h2 class="h4">Client Settings</h2>
          </div>
        </div>
        <div class="callout-text-wrapper">
          <p>
            <span [innerHTML]="copy"></span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row body-row">
    <div class="col-md-12">
      <mdb-card class="session-breakdown-card content-card">
        <mdb-tabset
          #staticTabs
          [buttonClass]="'classic-tabs'"
          class="admin-tab-styles sessions-breackdown-tabs"
          [contentClass]="'remove-card'"
        >
          <mdb-tab heading="Main Settings">
            <div class="row">
              <div class="col-4">
                <div class="md-form w-100 mr-2 experience-search">
                  <!-- add model to the input for search -->
                  <input
                    class="form-control"
                    id="search"
                    type="search"
                    [(ngModel)]="searchText"
                    mdbInput
                  />
                  <label for="search">Search Setting Category Names</label>
                </div>
              </div>
            </div>
            <div>
              <div *ngIf="!loading">
                <div class="card-group">
                  <div
                    [class]="category.name"
                    *ngFor="
                      let category of teamCategories | filter: searchText;
                      let i = index
                    "
                  >
                    <mdb-card>
                      <mdb-card-body>
                        <mdb-card-title>
                          <h5 class="mb-3">{{ category.label }}</h5>
                        </mdb-card-title>
                        <mdb-card-text class="mb-0">
                          <div
                            class="col-md-12 setting-container"
                            [id]="setting.name"
                            *ngFor="
                              let setting of category.settings;
                              let setting_index = index
                            "
                          >
                            <div class="d-flex flex-row">
                              <ul class="list-unstyled">
                                <li>
                                  <b>{{ setting.label }}</b>
                                </li>
                                <li>{{ setting.description }}</li>
                                <li>
                                  <div>
                                    <div *ngIf="setting.type == 'boolean'">
                                      <mdb-checkbox
                                        [checked]="
                                          setting.unconstrained_default
                                        "
                                        [default]="
                                          setting.unconstrained_default
                                        "
                                        (change)="
                                          updateTeamSettings(setting, $event)
                                        "
                                      >Enabled</mdb-checkbox>
                                    </div>
                                    <div *ngIf="setting.type == 'select_list'">
                                      <div class="md-form">
                                        <mdb-select-2
                                          placeholder="Choose your option"
                                          (selected)="
                                            updateTeamSettings(setting, $event)
                                          "
                                          [(value)]="setting.default_value"
                                        >
                                          <mdb-select-option
                                            *ngFor="
                                              let option of setting.options
                                            "
                                            [value]="option.id"
                                          >{{
                                              option.label
                                            }}</mdb-select-option>
                                        </mdb-select-2>
                                      </div>
                                    </div>
                                    <div *ngIf="
                                        setting.type == 'multi_select_list'
                                      ">
                                      <div class="md-form">
                                        <mdb-select-2
                                          [multiple]="true"
                                          placeholder="Choose your options"
                                          [(value)]="setting.default_value"
                                        >
                                          <mdb-select-option
                                            *ngFor="
                                              let option of setting.options
                                            "
                                            [value]="option.id"
                                            (click)="
                                              updateTeamSettings(
                                                setting,
                                                option.id
                                              )
                                            "
                                          >{{
                                              customMultiSelectLabels(option.label,setting,option)
                                            }}</mdb-select-option>
                                        </mdb-select-2>
                                      </div>
                                    </div>
                                    <div *ngIf="setting.type == 'asset_id'">
                                      <div
                                        class="logo-container"
                                        *ngIf="setting.asset"
                                      >
                                        <img
                                          [src]="
                                            setting.asset.thumbnail_asset_url
                                          "
                                          class="container-img"
                                        />
                                      </div>
                                      <button
                                        mdbBtn
                                        class="waves-light theme-action-button"
                                        mdbWavesEffect
                                        (click)="openChangeLogoModal(setting)"
                                      >
                                        Change Logo
                                      </button>
                                    </div>
                                    <div *ngIf="setting.type == 'string'">
                                      <div class="d-flex flex-row">
                                        <div class="flex-fill">
                                          <app-text-inline-edit
                                            [data]="
                                                setting.unconstrained_default
                                              "
                                            (saveData)="
                                                updateTeamSettings(
                                                  setting,
                                                  $event
                                                )
                                              "
                                          ></app-text-inline-edit>
                                        </div>
                                        <div class="flex-fill"></div>
                                      </div>
                                    </div>
                                    <div *ngIf="setting.type == 'email'">
                                      <div class="col-md-12">
                                        <div class="d-flex flex-row">
                                          <div class="flex-fill">
                                            <app-text-inline-edit
                                              [data]="
                                                setting.unconstrained_default
                                              "
                                              (saveData)="
                                                updateTeamSettings(
                                                  setting,
                                                  $event
                                                )
                                              "
                                            ></app-text-inline-edit>
                                          </div>
                                          <div class="flex-fill"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="setting.type == 'number'">
                                      <div class="md-form">
                                        <input
                                          mdbInput
                                          type="number"
                                          class="form-control"
                                          [ngModel]="
                                            teamCategories[i].settings[
                                              setting_index
                                            ].unconstrained_default
                                          "
                                          (ngModelChange)="
                                            updateTeamSettings(
                                              teamCategories[i].settings[
                                                setting_index
                                              ],
                                              $event
                                            )
                                          "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </mdb-card-text>
                      </mdb-card-body>
                      <mdb-card-footer> </mdb-card-footer>
                    </mdb-card>
                  </div>
                </div>
              </div>
            </div>
          </mdb-tab>
          <mdb-tab heading="Labels">
            <div class="labels-container">
              <div class="row">
                <div class="col-4">
                  <div class="md-form w-100 mr-2 experience-search">
                    <!-- add model to the input for search -->
                    <input
                      class="form-control"
                      id="search"
                      type="search"
                      [(ngModel)]="labelSearchText"
                      mdbInput
                    />
                    <label for="search">Search Labels</label>
                  </div>
                </div>
              </div>
              <div
                class="col-12"
                *ngIf="!labelsLoading"
              >
                <div class="card-group">
                  <div *ngFor="
                      let label of teamLabels | filter: labelSearchText;
                      let i = index
                    ">
                    <mdb-card>
                      <mdb-card-body>
                        <mdb-card-title>
                          <h5 class="mb-3">{{ label.name }}</h5>
                        </mdb-card-title>

                        <div class="flex-fill">
                          <app-text-inline-edit
                            [data]="label.singular"
                            [label]="'Singular'"
                            (saveData)="
                              updateTeamLabel(label, $event, 'singular')
                            "
                          ></app-text-inline-edit>
                          <app-text-inline-edit
                            [label]="'Plural'"
                            [data]="label.plural"
                            (saveData)="
                              updateTeamLabel(label, $event, 'plural')
                            "
                          ></app-text-inline-edit>
                        </div>
                      </mdb-card-body>
                      <mdb-card-footer> </mdb-card-footer>
                    </mdb-card>
                  </div>
                </div>
              </div>
            </div>
          </mdb-tab>
          <mdb-tab heading="Experiences">
            <div class="experiences-container">
              <div class="row">
                <div class="col-4">
                  <div class="md-form w-100 mr-2 experience-search">
                    <input
                      class="form-control"
                      id="search"
                      type="search"
                      [(ngModel)]="experiencesSearchText"
                      mdbInput
                    />
                    <label for="search">Search Experiences</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="btn-spacing text-right">
                    <button
                      class="theme-modal-button btn"
                      type="button"
                      mdbBtnp
                      mdbWavesEffect
                      (click)="openAddTeamExperienceModal()"
                    >
                      <mdb-icon
                        far
                        icon="plus-square"
                        class="pr-1"
                      ></mdb-icon>
                      Add Experience
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="col-12"
                *ngIf="!teamExperiencesLoading"
              >
                <div class="card-group">
                  <div *ngFor="
                      let experience of teamExperiences | filter: experiencesSearchText;
                      let i = index
                    ">
                    <mdb-card>
                      <mdb-card-body>
                        <a class="activator px-3 mr-6">
                          <mdb-icon
                            fas
                            icon="archive"
                            (click)="
                              openArchivePropsModal(experience, i, 'Team Experience')
                            "
                          ></mdb-icon>
                        </a>
                        <mdb-card-title>
                          <h5 class="mb-3">{{ experience.experience.label }}</h5>
                        </mdb-card-title>
                        <mdb-card-text class="mb-0">
                          <div class="col-md-12">
                            <ul class="list-unstyled">
                              <li>
                                {{ experience.experience.description }}
                              </li>
                            </ul>
                          </div>
                        </mdb-card-text>
                      </mdb-card-body>
                      <mdb-card-footer> </mdb-card-footer>
                    </mdb-card>
                  </div>
                </div>
              </div>
            </div>
          </mdb-tab>
          <mdb-tab heading="Event Props">
            <div class="props-container">
              <div class="row">
                <div class="col-4">
                  <div class="md-form w-100 mr-2 experience-search">
                    <input
                      class="form-control"
                      id="search"
                      type="search"
                      [(ngModel)]="eventPropsSearchText"
                      mdbInput
                    />
                    <label for="search">Search Events Props</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="btn-spacing text-right">
                    <button
                      class="theme-modal-button btn"
                      type="button"
                      mdbBtnp
                      mdbWavesEffect
                      (click)="openAddEventPropsModal()"
                    >
                      <mdb-icon
                        far
                        icon="plus-square"
                        class="pr-1"
                      ></mdb-icon>
                      Add Property
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="col-12"
                *ngIf="!eventPropsLoading"
              >
                <div class="card-group">
                  <div *ngFor="
                      let prop of eventProps | filter: eventPropsSearchText;
                      let i = index
                    ">
                    <mdb-card>
                      <mdb-card-body>
                        <a class="activator px-3 mr-6">
                          <mdb-icon
                            fas
                            icon="archive"
                            (click)="
                              openArchivePropsModal(prop, i, 'Team Event Property')
                            "
                          ></mdb-icon>
                        </a>
                        <mdb-card-title>
                          <h5 class="mb-3">{{ prop.props.label }}</h5>
                        </mdb-card-title>
                        <mdb-card-text class="mb-0">
                          <div class="col-md-12">
                            <ul class="list-unstyled">
                              <li>
                                {{ prop.props.description }}
                              </li>
                              <li>
                                {{ prop.props.type }}
                              </li>
                              <li>{{ prop.props.subtype }}</li>
                            </ul>
                          </div>
                        </mdb-card-text>
                      </mdb-card-body>
                      <mdb-card-footer> </mdb-card-footer>
                    </mdb-card>
                  </div>
                </div>
              </div>
            </div>
          </mdb-tab>
          <mdb-tab heading="Client Meta">
            <div class="content-container">
              <div class="row mt-3">
                <div class="col-12">
                  <div
                    class="card-group"
                    *ngIf="!clientMetaLoading"
                  >
                    <mdb-card>
                      <mdb-card-body>
                        <mdb-card-title>
                          <h5 class="mb-3">Avatar Type</h5>
                        </mdb-card-title>
                        <div
                          class="alert alert-primary custom-alert-saving"
                          role="alert"
                          [ngClass]="{
                          visible:
                            status === 'processing' && processingType === 'avatarType'
                        }"
                        >
                          <div
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                          Saving
                        </div>
                        <div
                          class="alert alert-success custom-alert"
                          role="alert"
                          [ngClass]="{
                          visible: status === 'saved' && processingType === 'avatarType'
                        }"
                        >
                          <mdb-icon
                            fas
                            icon="check"
                          ></mdb-icon>
                          Successfully Saved
                        </div>
                        <mdb-card-text class="mb-0">
                          <div class="md-form">
                            <mdb-select-2
                              placeholder="Choose Avatar Type"
                              (ngModelChange)="updateAvatarDefault($event)"
                              [(ngModel)]="avatarTypeDefault"
                            >
                              <mdb-select-option
                                *ngFor="let option of avatarOptions"
                                [value]="option.value"
                              >{{ option.label }}</mdb-select-option>
                            </mdb-select-2>
                          </div>
                        </mdb-card-text>
                      </mdb-card-body>
                      <mdb-card-footer> </mdb-card-footer>
                    </mdb-card>
                  </div>
                </div>
              </div>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </mdb-card>
    </div>
  </div>
</div>
