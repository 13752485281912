import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UniversalSettingsComponent } from "./universal-settings/universal-settings.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { PersistentModule } from "../persistent/persistent.module";
import { SharedModulesModule } from "../shared-modules/shared-modules.module";
import { AddSettingModalComponent } from "./universal-settings/modals/add-setting-modal/add-setting-modal.component";
import { AddPropOptionModalComponent } from "./universal-settings/modals/add-prop-option-modal/add-prop-option-modal.component";
import { ViewPropModalComponent } from "./universal-settings/modals/view-prop-modal/view-prop-modal.component";
import { ArchivePropModalComponent } from "./universal-settings/modals/archive-prop-modal/archive-prop-modal.component";
import { EditPropModalComponent } from "./universal-settings/modals/edit-prop-modal/edit-prop-modal.component";
import { ExperiencesGlobalComponent } from "./experiences-global/experiences-global.component";
import { ManageGlobalExperienceComponent } from "./experiences-global/modals/manage-global-experience/manage-global-experience.component";
import { ArchiveGlobalExperienceComponent } from "./experiences-global/modals/archive-global-experience/archive-global-experience.component";
import { EnvironmentsComponent } from "./environments/environments.component";
import { ManageEnvironmentsComponent } from "./environments/modals/manage-environments/manage-environments.component";
import { ArchiveEnvironmentsComponent } from "./environments/modals/archive-environments/archive-environments.component";
import { ExperienceAttributesComponent } from "./experience-attributes/experience-attributes.component";
import { ManageExperienceAttributesComponent } from "./experience-attributes/modals/manage-experience-attributes/manage-experience-attributes.component";
import { ArchiveExperienceAttributesComponent } from "./experience-attributes/modals/archive-experience-attributes/archive-experience-attributes.component";
import { ExperienceAttributeToGlobalExperienceComponent } from "./experiences-global/modals/experience-attribute-to-global-experience/experience-attribute-to-global-experience.component";
import { VersionSupportComponent } from "./version-support/version-support.component";
import { AppBundlesComponent } from "./app-bundles/app-bundles.component";
import { AppDeviceTypesComponent } from "./app-device-types/app-device-types.component";
import { AppVersionsComponent } from "./app-versions/app-versions.component";
import { ReusableModule } from "../reusable/reusable.module";
import { ManageAppVersionsComponent } from './app-versions/modals/manage-app-versions/manage-app-versions.component';
import { ManageAppBundlesComponent } from './app-bundles/modals/manage-app-bundles/manage-app-bundles.component';
import { ManageAppDeviceTypesComponent } from './app-device-types/modals/manage-app-device-types/manage-app-device-types.component';

@NgModule({
  declarations: [
    UniversalSettingsComponent,
    AddSettingModalComponent,
    AddPropOptionModalComponent,
    ViewPropModalComponent,
    ArchivePropModalComponent,
    EditPropModalComponent,
    ExperiencesGlobalComponent,
    ManageGlobalExperienceComponent,
    ArchiveGlobalExperienceComponent,
    EnvironmentsComponent,
    ManageEnvironmentsComponent,
    ArchiveEnvironmentsComponent,
    ExperienceAttributesComponent,
    ManageExperienceAttributesComponent,
    ArchiveExperienceAttributesComponent,
    ExperienceAttributeToGlobalExperienceComponent,
    VersionSupportComponent,
    AppBundlesComponent,
    AppDeviceTypesComponent,
    AppVersionsComponent,
    ManageAppVersionsComponent,
    ManageAppBundlesComponent,
    ManageAppDeviceTypesComponent,
  ],
  imports: [
    CommonModule,
    PersistentModule,
    SharedModulesModule,
    ReactiveFormsModule,
    ReusableModule,
    MDBBootstrapModulesPro.forRoot(),
  ],
})
export class UniversalModule {}
