import { Component, ViewChild } from '@angular/core';
import { TooltipDirective } from 'ng-uikit-pro-standard';

import {
  faFileAudio,
  faFileVideo,
  faFileLines,
  faTrashAlt,
  faUser,
  faRobot,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-ag-renderer-clickable-icon',
  templateUrl: './ag-renderer-clickable-icon.component.html',
  styleUrls: ['./ag-renderer-clickable-icon.component.scss']
})
export class AgRendererClickableIconComponent {
  @ViewChild('myTooltip') myTooltip!: TooltipDirective;

  public params;
  public icons: any;

  public faFileAudio = faFileAudio;
  public faFileVideo = faFileVideo;
  public faFileLines = faFileLines;
  public faExclamationTriangle = faExclamationTriangle;
  public faUser = faUser;
  public faRobot = faRobot;

  public hasMedia = false;

  public availableMedia = {
    "faFileLines": false,
    "faFileAudio": false,
    "faFileVideo": false,
    "faUser": false,
    "faRobot": false
  }

  public issueRetrieving = {
    "faFileLines": false,
    "faFileAudio": false,
    "faFileVideo": false,
    "faUser": false,
    "faRobot": false
  }

  agInit(params): void {
    this.params = params;
    console.log("setting up custom renderered icons", this.params);
    this.icons = this.params.icons || [];
    this.processAvailableMedia(this.params.data.generated_media_types);
  }

  private processAvailableMedia(generated_media_types) {

    if (this.isObjectEmpty(generated_media_types)) {
      return;
    }

    console.log("generated_media_types in processAvailableMedia", generated_media_types);

    this.hasMedia = true;

    if (generated_media_types.transcript !== undefined && !generated_media_types.transcript_json[0].error) {
      this.availableMedia.faFileLines = true;
      this.availableMedia.faUser = true;
      this.availableMedia.faRobot = true;
    } else if (generated_media_types.transcript !== undefined && generated_media_types.transcript_json[0].error) {
      this.issueRetrieving.faFileLines = true;
      this.issueRetrieving.faUser = true;
    }

    if (generated_media_types.audio !== undefined && !generated_media_types.audio[0].error) {
      this.availableMedia.faFileAudio = true;
    } else if (generated_media_types.audio !== undefined && generated_media_types.audio[0].error) {
      this.issueRetrieving.faFileAudio = true;
    }

    if (generated_media_types.video !== undefined && !generated_media_types.video[0].error) {
      this.availableMedia.faFileVideo = true;
      this.availableMedia.faUser = true;
      this.availableMedia.faRobot = true;
    } else if (generated_media_types.video !== undefined && generated_media_types.video[0].error) {
      this.issueRetrieving.faFileVideo = true;
      this.issueRetrieving.faUser = true;
    }

    console.log("this.issueRetrieving", this.issueRetrieving);

  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event, targetType) {
    if (this.params.onClick instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data,
        targetType: targetType
      }
      this.params.onClick(params);

    }
  }

  onHover(event: MouseEvent): void {
    // Handle hover enter
    console.log('Button hover enter', this.myTooltip);
    this.myTooltip.show();
  }

  onHoverExit(event: MouseEvent): void {
    // Handle hover exit
    console.log('Button hover exit', this.myTooltip);
    this.myTooltip.hide();
  }

  private isObjectEmpty(obj: object): boolean {
    return Object.keys(obj).length === 0;
  }

}
