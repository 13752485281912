import { MetricsServicesService } from "./../../metrics/services/metrics-services.service";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";

import { Subscription } from "rxjs";

import { CoolLocalStorage } from "@angular-cool/storage";

import { SharedDataService } from "./../../../services/shared-data/shared-data.service";
import { QueryParamsService } from "./../../../services/utilities/query-params.service";
import { environment } from "./../../../../environments/environment";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { NeedAuthGuardService } from "src/app/services/utilities/need-auth-guard.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { SettingsService } from "src/app/services/utilities/settings.service";
@Component({
  selector: "app-client-base",
  templateUrl: "./client-base.component.html",
  styleUrls: ["./client-base.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ClientBaseComponent implements OnInit {
  title = "glimpse-unified-admin";

  public token: string = null;
  public login: string = null;
  public userid: string = null;
  public loadMenus: boolean = true;
  public teamID: number = 0;
  public user: any;
  public defaultPrefix: string = "/assets/json/clients/default";
  public assetPrefix: string = "/assets/json";
  public contentLoaded: boolean = false;
  public teamPermissions: boolean = false;
  public loadingIssue: boolean = false;

  public loginRetrieved: string;
  public oldClientSettings: any;
  public clientSettings: any;

  public showWelcome: boolean = true;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute,
    private router: Router,
    private _clientManagementService: ClientManagementService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _needAuthGuardService: NeedAuthGuardService,
    private _settingsService: SettingsService,
    private _metricsService: MetricsServicesService
  ) { }

  ngOnInit() {
    this.token = this.tokenRetrieve();
    this.retrieveTeamID();

    this.oldClientSettings = this._clientManagementService.getClientSettings(
      this.teamID
    );

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    //check for token query param and hide menus if so
    if (this.route.snapshot.queryParams["token"]) {
      this.loadMenus = false;
    }

    this.retrieveUser();
    if (this.token) this.checkPermissions();
    this.initSetup();
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private checkPermissions() {
    if (this.user.role_type_id > 1 && this.teamID !== this.user.team_id) {
      this.token = null;
      this.clientSettings = null;
      this.teamPermissions = false;
    } else {
      this.teamPermissions = true;
    }
  }

  private initSetup() {

    if (this.clientSettings === null) {
      if (this.token !== null) {
        let retrieveSettings = this._settingsService
          .retrieveSettings(this.teamID)
          .toPromise();
        let retrieveContent = this._settingsService
          .retrieveContent(this.teamID)
          .toPromise();
        let retieveLabels = this._settingsService
          .retrieveLabels(this.teamID)
          .toPromise();

        let promises = [retrieveSettings, retrieveContent, retieveLabels];

        Promise.all(promises)
          .then((response) => {

            let clientSettings = response[0].settings;
            let contents = response[1];
            let labels = response[2].labels;

            this._settingsService.setSettings(this.teamID, clientSettings);
            this._settingsService.setContent(contents.contents, this.teamID);
            this._settingsService.setLabels(labels);

            //initiate settings for metrics service
            this._metricsService.setTeamID(this.teamID);

            this.initActions(clientSettings, this.oldClientSettings);
          })
          .catch((error) => {
            this.loadingIssue = true;
          });
      } else {
        this.initActions(this.clientSettings, this.oldClientSettings);
      }
    } else {
      //initiate settings for metrics service
      this._metricsService.setTeamID(this.teamID);
      this.initActions(this.clientSettings, this.oldClientSettings);
    }
  }

  private initActions(clientSettings, oldClientSettings) {
    this.route.queryParams.subscribe((params: Params) => {
      let login = params["u"];
      let password = params["p"];

      if (
        login !== undefined &&
        password !== undefined &&
        login !== this.loginRetrieved
      ) {
        this.coolLocalStorage.removeItem("admin_panel_login_time_stamp");
        this.coolLocalStorage.removeItem("admin_panel_jwt");
        this.coolLocalStorage.removeItem("admin_panel_username");
        this.coolLocalStorage.removeItem("admin_panel_userinfo");

        this.router.navigateByUrl("/auth?p=" + password + "&u=" + login);
        this.contentLoaded = true;
      } else if (this.teamPermissions) {
        this.contentLoaded = true;
      } else {
        this.contentLoaded = true;
      }
    });

    this._needAuthGuardService.navEnd.subscribe((route) => {
      this.token = this.tokenRetrieve();
      this.contentLoaded = true;
    });
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private labelsRetrieve() {
    let retrieveDefaultLabels = this._xrPlatformRestService
      .retrieveJSON(this.defaultPrefix + "/labels.json")
      .toPromise();

    let retrieveInstanceLabels = this._xrPlatformRestService
      .retrieveJSON(this.assetPrefix + "/labels.json")
      .toPromise()
      .catch(function (err) {
        //catch in case team instance doesn't have labels setup
        //in which case we will just use the defaults
        ;
      });

    let promises = [retrieveDefaultLabels, retrieveInstanceLabels];

    Promise.all(promises)
      .then((response) => {
        //in case there are no label overrides setup for this team
        this.labelProcess(response[0], response[1]);
      })
      .catch(function (err) {
        ;
      });
  }

  private labelProcess(defaultLabels, overrideLabels?) {
    if (overrideLabels === undefined)
      overrideLabels = {
        labels: {},
      };

    let labels = {
      ...defaultLabels.labels,
      ...overrideLabels.labels,
    };

    this.coolLocalStorage.setObject("the_panel_labels", labels);
    this.contentLoaded = true;
  }

  private tokenRetrieve() {
    if (environment.localstorage) {
      return this.coolLocalStorage.getItem("admin_panel_jwt");
    } else {
      return "";
    }
  }

  public toggleWelcome(state: boolean) {
    this.showWelcome = state;
  }
}
