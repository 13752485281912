<div class="heading-content">
  <h4>Inventory Session Analytics</h4>
  <div class="callout-text-wrapper">
    <p [innerHTML]="copy"></p>
  </div>
</div>
<mdb-card class="inventory-table-container content-card py-2" *ngIf="!loading">
  <div class="row align-items-end inventory-controls">
    <div class="col-12" *ngIf="formLoading">
      <mdb-spinner color="primary"></mdb-spinner>
    </div>
    <div class="col-12" *ngIf="!formLoading">
      <form
        [formGroup]="userForm"
        class="d-flex inventory-filtering"
        [ngClass]="{
          'justify-content-between': adminDefault,
          'justify-content-start': !adminDefault
        }"
      >
        <div class="md-form ap-autocomplete col-3" *ngIf="adminDefault">
          <input
            type="text"
            id="inventoryUser"
            formControlName="userSelection"
            placeholder="Select a User:"
            [mdbAutoCompleter]="auto"
            class="completer-input form-control mdb-autocomplete"
            autocomplete="off"
          />
          <mdb-auto-completer
            [displayValue]="onUserDisplayValue"
            #auto="mdbAutoCompleter"
            textNoResults="No results found"
            [clearButton]="true"
            [visibleOptions]="7"
          >
            <mdb-option
              *ngFor="let option of userResults | async"
              [value]="option"
            >
              {{ option.label }}
            </mdb-option>
          </mdb-auto-completer>
          <label class="active" for="inventoryUser"
            ><span class="color-theme-base-medium-dark bold"
              >* User</span
            ></label
          >
        </div>
        <div
          class="md-form filter-types col-3"
          [ngClass]="{
            glow: inventoryTypeSelectionRequired
          }"
        >
          <mdb-select
            [options]="inventoryTypes"
            formControlName="inventoryTypeSelection"
            id="inventoryTypes"
            mdbInput
            placeholder="Select Inventory Type"
          >
          </mdb-select>
          <label class="active" for="inventoryUser"
            ><span class="color-theme-base-medium-dark bold"
              >* Inventory Type</span
            ></label
          >
        </div>
        <div class="md-form filter-exercises col-6">
          <mdb-select
            [options]="inventoryExercises"
            formControlName="inventoryExerciseSelection"
            id="inventoryExercises"
            mdbInput
            placeholder="Select an Entry"
          >
          </mdb-select>
          <label class="active" for="inventoryUser"
            ><span class="color-theme-base-medium-dark"
              >Inventory Entry</span
            ></label
          >
        </div>
      </form>
    </div>
  </div>
  <div class="row inventory-controls justify-content-between">
    <div class="col-12">
      <div class="btn-spacing inventory-btns">
        <button
          *ngIf="tableStatus === 'dataLoaded'"
          class="theme-modal-button btn"
          type="button"
          mdbBtn
          mdbWavesEffect
          [disabled]="csvStatus === 'loading'"
          (click)="downloadCSV()"
        >
          <mdb-icon fas icon="download" class="pr-1"></mdb-icon>
          <span *ngIf="csvStatus !== 'loading' && csvStatus !== 'error'"
            >Download CSV</span
          >
          <span *ngIf="csvStatus === 'loading'">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Loading...</span></span
          >
          <span *ngIf="csvStatus === 'error'">
            <mdb-icon
              fas
              icon="exclamation-triangle"
              class="pr-1"
              spinnerColor="white"
            ></mdb-icon>
            Error
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!loading">
    <div class="col-12">
      <div *ngIf="adminDefault">
        <p class="loading-text mx-0 my-3" *ngIf="tableStatus === 'loading'">
          <mdb-spinner color="primary"></mdb-spinner>
        </p>
        <p class="loading-text mx-0 my-3" *ngIf="tableStatus === 'pending'">
          Select a <span class="color-theme-base-medium-dark">User</span> from
          the drop down to see inventory session results data.
        </p>
        <p class="loading-text mx-0 my-3" *ngIf="tableStatus === 'needType'">
          Select an
          <span class="color-theme-base-medium-dark">Inventory Type</span> to
          view the inventory session results data for
          {{ userForm.controls.userSelection.value.label }}.
        </p>
        <p class="loading-text mx-0 my-3" *ngIf="tableStatus === 'noData'">
          <span class="color-theme-base-medium-dark">{{
            userForm.controls.userSelection.value.label
          }}</span>
          has no inventory session results for
          <span class="color-theme-base-medium-dark">{{
            getInventoryTypeByID(userForm.controls.inventoryTypeSelection.value)
              .label
          }}</span
          >.
        </p>
      </div>
      <div *ngIf="!adminDefault">
        <p class="loading-text mx-0 my-3" *ngIf="tableStatus === 'loading'">
          <mdb-spinner color="primary"></mdb-spinner>
        </p>
        <p class="loading-text mx-0 my-3" *ngIf="tableStatus === 'needType'">
          Select an
          <span class="color-theme-base-medium-dark">Inventory Type</span> to
          view the your inventory session results data.
        </p>
        <p class="loading-text mx-0" *ngIf="tableStatus === 'noData'">
          You have no inventory session results yet for
          <span class="color-theme-base-medium-dark">
            {{
              getInventoryTypeByID(
                userForm.controls.inventoryTypeSelection.value
              ).label
            }}</span
          >
        </p>
      </div>
      <!-- the table -->
      <div class="table-container" *ngIf="tableStatus === 'dataLoaded'">
        <table
          class="table table-responsive-md btn-table meeting-nav-table inventory-table"
          mdbTable
          small="true"
          striped="true"
          mdbTableScroll
          scrollY="true"
          scrollX="false"
          maxHeight="600"
        >
          <thead class="sticky-top theme-base-medium-dark white-text">
            <th *ngFor="let label of inventory.labels; let i = index">
              <div class="cell-content">
                <button
                  class="btn btn-clear"
                  type="button"
                  mdbBtn
                  [mdbTooltip]="label.description"
                  placement="top"
                  container="body"
                  triggers="mouseover mouseout"
                  delay="0"
                >
                  <fa-icon [icon]="faCircleInfo"></fa-icon>
                  {{ label.label }}
                </button>
              </div>
            </th>
          </thead>
          <tbody>
            <tr
              mdbTableCol
              *ngFor="let item of inventory.values; let i = index"
            >
              <td *ngFor="let column of item; let columndex = index">
                <div class="cell-content">
                  {{
                    inventory.labels[columndex].default_value != null &&
                    inventory.labels[columndex].default_value == column
                      ? ""
                      : column
                  }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- the other table -->
    </div>
  </div>
</mdb-card>
