<app-side-menu></app-side-menu>
<app-menu [control]="true"></app-menu>
<div class="heading-content">
  <h4>Universal Settings</h4>
  <div class="callout-text-wrapper">
    <p [innerHTML]="copy"></p>
  </div>
</div>
<mdb-card class="client-settings content-card">
  <div
    class="row"
    *ngIf="eventPropsLoading"
  >
    <div class="col-12">
      <!-- if content hasnt load -->
      <div class="loading-screen d-flex align-content-center">
        <div class="d-flex justify-content-center">
          <div
            class="spinner-border"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- when content loads -->
  <mdb-tabset
    #staticTabs
    [buttonClass]="'classic-tabs'"
    class="admin-tab-styles sessions-breackdown-tabs"
    [contentClass]="'remove-card'"
    *ngIf="!eventPropsLoading"
  >
    <mdb-tab heading="Experiences">
      <app-experiences-global></app-experiences-global>
    </mdb-tab>
    <mdb-tab heading="Environments">
      <app-environments></app-environments>
    </mdb-tab>
    <mdb-tab heading="Experience Attributes">
      <app-experience-attributes></app-experience-attributes>
    </mdb-tab>
    <mdb-tab heading="Event Props">
      <div class="row justify-content-between">
        <div class="col-3 d-flex event-search">
          <div class="md-form mr-2 w-100">
            <input
              class="form-control"
              id="search"
              type="search"
              [(ngModel)]="eventPropsSearchText"
              mdbInput
            />
            <label for="search">Search Events Props</label>
          </div>
        </div>
        <div class="col-3 btn-spacing filter text-right">
          <button
            class="theme-modal-button btn"
            type="button"
            mdbBtnp
            mdbWavesEffect
            (click)="openAddEventPropsModal('Add', -1, null)"
          >
            <mdb-icon
              class="pr-1"
              far
              icon="plus-square"
            ></mdb-icon>
            Add Property
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card-group flex-wrap">
            <div *ngFor="
                let prop of eventProps | filter: eventPropsSearchText;
                let i = index
              ">
              <mdb-card>
                <mdb-card-body>
                  <mdb-card-title>
                    <h5 class="mb-3">{{ prop.label }}</h5>
                  </mdb-card-title>
                  <mdb-card-text class="mb-0">
                    <ul class="list-unstyled">
                      <li class="mb-3">
                        {{ prop.description }}
                      </li>
                      <li>
                        <span class="strong color-theme-base-dark">Prop Type:
                        </span>
                        {{ prop.type }}
                      </li>
                      <li>
                        <span class="strong color-theme-base-dark">Prop Subtype:
                        </span>
                        {{
                          prop.subtype === "Subtype" || prop.subtype === ""
                            ? "No Subtype"
                            : prop.subtype
                        }}
                      </li>
                      <li>
                        <span class="strong color-theme-base-dark">Prop Order:
                        </span>
                        {{ prop.order }}
                      </li>
                    </ul>
                  </mdb-card-text>
                </mdb-card-body>
                <mdb-card-footer class="modal-footer">
                  <a
                    *ngIf="prop.constrained"
                    (click)="openViewEventPropsModal(prop.id)"
                    mdbTooltip="Constrain Options"
                    placement="top"
                  >
                    <mdb-icon
                      fas
                      icon="plus-square"
                    ></mdb-icon>
                  </a>
                  <a
                    (click)="openAddEventPropsModal('Edit', i, prop)"
                    mdbTooltip="Edit"
                    placement="top"
                  >
                    <mdb-icon
                      far
                      icon="edit"
                    ></mdb-icon>
                  </a>
                  <a
                    (click)="openArchivePropsModal(prop, i, 'Event Property')"
                    mdbTooltip="Archive"
                    placement="top"
                  >
                    <mdb-icon
                      fas
                      icon="archive"
                    ></mdb-icon>
                  </a>
                </mdb-card-footer>
              </mdb-card>
            </div>
          </div>
        </div>
      </div>
    </mdb-tab>
  </mdb-tabset>
</mdb-card>
<!-- <div class="client-settings-page page">
  <div class="row body-row">
    <div class="col-md-12">
      <mdb-card class="session-breakdown-card">

      </mdb-card>
    </div>
  </div>
</div> -->
