<ng-container *ngIf="icons">
  <div class="action-icons">
    <span class="italic" *ngIf="!hasMedia">Not Submitted</span>
    <span class="btn-group list-btns-sm btn-icons" *ngIf="hasMedia">
      <ng-container *ngFor="let icon of icons">
        <button
          *ngIf="availableMedia[icon.icon]"
          mdbBtn
          mdbWavesEffect
          class="btn-flat btn-icon ml-2"
          mdbTooltip="{{ icon.tooltip }}"
          placement="bottom"
          (click)="onClick($event, icon.targetType)"
        >
          <fa-icon
            *ngIf="icon.icon === 'faFileAudio'"
            [icon]="faFileAudio"
          ></fa-icon>
          <fa-icon
            *ngIf="icon.icon === 'faFileVideo'"
            [icon]="faFileVideo"
          ></fa-icon>
          <fa-icon
            *ngIf="icon.icon === 'faFileLines'"
            [icon]="faFileLines"
          ></fa-icon>
          <fa-icon *ngIf="icon.icon === 'faUser'" [icon]="faUser"></fa-icon>
          <fa-icon *ngIf="icon.icon === 'faRobot'" [icon]="faRobot"></fa-icon>
        </button>
        <a
          *ngIf="issueRetrieving[icon.icon]"
          class="btn-flat btn-icon ml-2"
          pTooltip="There was an issue retrieving the AI files. Please try again or contact support."
          tooltipPosition="right"
        >
          <fa-icon [icon]="faExclamationTriangle"></fa-icon>
        </a>
      </ng-container>
    </span>
  </div>
</ng-container>
