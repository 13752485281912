import { Injectable } from '@angular/core';
import { CoolLocalStorage } from "@angular-cool/storage";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public teamID: number;
  public token: string;
  public settingsVersions = [1, 2]
  public settingsObjectName = "Client_Settings_"
  public labelsObjectName = "the_panel_labels"
  public contentObjectName = "Content_Management_"

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
  ) { }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }
  
  public retrieveSettings(teamID){

    this.retrieveToken();

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {

    }

    return this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + teamID + "/settings",
      "get",
      data,
      getOptions,
      "v1",
      true
    );
  }

  public retrieveSettingLogo(teamID){

    this.retrieveToken();

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    const data = {

    }

    return this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + teamID + "/settings/logo",
      "get",
      data,
      getOptions
    );
  }

  public retrieveContent(teamID){

    this.retrieveToken();
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    return this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + teamID + "/content-to-place",
      "get",
      {},
      getOptions
    )

  }

  public retrieveLabels(teamID){

    this.retrieveToken();
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    return this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + teamID + "/labels-to-place",
      "get",
      {},
      getOptions
    )

  }

  public getSettingsFromStorage(teamID) : any {
    let settings = this.coolLocalStorage.getObject(this.settingsObjectName + teamID);
    return settings;
  }

  public getLabelsFromStorage() : any {
    let labels = this.coolLocalStorage.getObject(this.labelsObjectName);
    return labels;
  }

  public getContentFromStorage(teamID) : any {
    let contents = this.coolLocalStorage.getObject(this.contentObjectName + teamID);
    return contents;
  }

  public setSettings(teamID, settings) {
    this.coolLocalStorage.setItem("Client_Settings_" + teamID, JSON.stringify(settings));
  }

  public setContent(contents, teamID) {
    this.coolLocalStorage.setItem(this.contentObjectName + teamID, JSON.stringify(contents));
  }

  public setLabels(contents) {
    this.coolLocalStorage.setItem(this.labelsObjectName, JSON.stringify(contents));
  }

  public updateSetting(setting, teamID) {
    let settings = this.getSettingsFromStorage(teamID)
    settings[setting.name] = setting
    this.coolLocalStorage.setItem("Client_Settings_" + teamID, JSON.stringify(settings));
  }

  public updateLabel(label) {
    let labels = this.getLabelsFromStorage()
    labels[label.name] = label
    this.coolLocalStorage.setItem(this.labelsObjectName, JSON.stringify(labels));
  }

  public getSettingOptionValue(setting) : string
  {
    let value = ""
    setting.options.forEach(element => {
      if(element.is_default) {
        value = element.value
      }
    });

    return value;
  }

  public getSettingsVersionsSelect() {
    let selectVersion = [];
    this.settingsVersions.forEach((version) => {
      selectVersion.push({value: version, label: 'Version ' + version})
    })

    return selectVersion;
  }
}
