import { MetricsAiaAssessmentComponent } from '../metrics-aia-assessment/metrics-aia-assessment.component';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { ManageMediaViewComponent } from './../../../../media-management/persistent/media-manager/media-manager-table-view/modals/manage-media-view/manage-media-view.component';
import { AgRendererClickableIconComponent } from './../../renderers/ag-renderer-clickable-icon/ag-renderer-clickable-icon.component';
import { Component } from '@angular/core';
import { Location, TitleCasePipe } from '@angular/common';
import { CoolLocalStorage } from '@angular-cool/storage';

import * as moment from "moment";

import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
  INumberFilterParams,
  ITextFilterParams,
  SizeColumnsToFitGridStrategy,
} from "ag-grid-community";

import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { MetricsServicesService } from 'src/app/modules/metrics/services/metrics-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SizeColumnsToFitProvidedWidthStrategy } from 'ag-grid-community';
import { SizeColumnsToContentStrategy } from 'ag-grid-community';
import { combineLatest } from 'rxjs';
import { SharedDataService } from 'src/app/services/shared-data/shared-data.service';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { SettingsService } from 'src/app/services/utilities/settings.service';

@Component({
  selector: 'app-metrics-aia-single-session',
  templateUrl: './metrics-aia-single-session.component.html',
  styleUrls: ['./metrics-aia-single-session.component.scss'],
  providers: [TitleCasePipe]
})
export class MetricsAiaSingleSessionComponent {
  //persistent
  private token: string;
  public teamID: number;
  public clientCode: string;
  public title: string = "AI Dashboard";
  public users: any;
  public userInfo: string = "";
  public showUserInformation: boolean = false;
  private targetURL: string;
  public eventID: number;
  public event: any;
  public eventInfo: string = "";
  public sessionID: number;
  public userID: number;
  public user: any;
  public startDate: string;
  public endDate: string;
  private clientSettings: any;
  private gridApi!: GridApi;

  //nav related
  public backRoute: string[];

  //custom cell renderers
  public frameworkComponents: any;

  //gpt model
  public aiModel: string = "gpt-4o-mini";
  public aiModelOptions: { value: string; label: string }[] = [
    { value: "gpt-4o", label: "GPT 4o" },
    { value: "gpt-4o-mini", label: "GPT 4o-mini" },
  ];

  //icons
  public faTimes = faTimes;

  //modal frames
  public modalFrame: MDBModalRef;

  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {}
  };

  //visibility
  public showDropdown: boolean = true;
  public gridLoading: boolean = true;
  public errorRetrievingMsg: string = "";

  //grid config
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
      type: "fitGridWidth",
    };
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 220
  };
  public rowData!: any[];
  public skillRowData!: any[];
  public durtionChrtData!: any[];
  public themeClass: string =
    "ag-theme-quartz-dark";

  //date/time
  public tz: any;
  public tz_iana: any;

  //query params
  public queryParams = {
    interval: "",
    start: "",
    end: "",
  };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _notificationService: NotificationsService,
    private _metricsServicesService: MetricsServicesService,
    private modalService: MDBModalService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _sharedDataService: SharedDataService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private TitleCase: TitleCasePipe,
    private _settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.retrieveToken();
    this.retrieveTeamID();
    this.retrieveClientCode();

    this.frameworkComponents = {
      iconRenderer: AgRendererClickableIconComponent,
    }

    let getTimezones = this._metricsServicesService.resolveTimeZone();
    this.tz_iana = getTimezones.tz_iana
    this.tz = getTimezones.tz;

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    this.showUserInformation = this.clientSettings.metricsUsername.unconstrained_default;

    this.buildColumnDefs();

    // Listen for route changes
    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([params, queryParams]) => {
      this.sessionID = +params.get('session_id');

      const retrievedParams = this._metricsServicesService.retrieveQueryParams(queryParams);

      this.queryParams.start = retrievedParams.start;
      this.queryParams.end = retrievedParams.end;
      this.queryParams.interval = retrievedParams.interval;

      //format the start and end dates for display with the following format: "Mar 18 2025" 
      this.startDate = moment(this.queryParams.start).tz(this.tz_iana).format("MMM DD YYYY");
      try {
        this.endDate = moment(this.queryParams.end).tz(this.tz_iana).format("MMM DD YYYY");
      } catch (error) {
        this.endDate = "In Progress";
      }

      //check to see if ai model is passed in query params
      if (queryParams.get('ai_model') !== null) {
        this.aiModel = queryParams.get('ai_model');
      }

      this.targetURL = `foretell/display/aia/session/${this.sessionID}`;

      this.backRoute = [
        '/client',
        this.clientCode,
        'analytics',
        'aia'
      ];

      this.retrieveData();
    });
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private buildColumnDefs() {

    let columns = [
      {
        headerName: 'Practice Session #', field: 'practice_session_number', type: 'number', filter: 'agNumberColumnFilter', filterParams: {
          buttons: ["clear"],
        } as INumberFilterParams
      },
      {
        headerName: 'Practice Start Time', field: 'start_time', type: 'date'
      },
      {
        headerName: 'Practice End Time', field: 'end_time', type: 'date'
      },
      {
        headerName: 'Files', type: 'icons',
        callback: this.onIconClick.bind(this),
        cellRenderer: AgRendererClickableIconComponent,
        icons: [
          {
            icon: "faFileLines",
            targetType: "transcript",
            tooltip: "View transcript"
          },
          {
            icon: "faFileAudio",
            targetType: "audio",
            tooltip: "Listen to audio recording"
          },
          {
            icon: "faFileVideo",
            targetType: "video",
            tooltip: "Watch video recording"
          }
        ]
      },
      {
        headerName: 'Assessments', type: 'icons',
        callback: this.onAssessmentClick.bind(this),
        cellRenderer: AgRendererClickableIconComponent,
        icons: [
          {
            icon: "faUser",
            targetType: "trainee",
            tooltip: "View user assessment"
          },
          {
            icon: "faRobot",
            targetType: "aiagent",
            tooltip: "View AI agent assessment"
          },
        ]
      },
    ]

    this.columnDefs = this._metricsServicesService.createColumnDefinitions(columns);
  }

  onIconClick(event, column) {
    let media = event.rowData.generated_media_types;
    let targetType = event.targetType;

    if (media[targetType] === undefined) {
      this._notificationService.errorNotification("No media available for this session");
      return;
    }

    let media_item = media[targetType][0].asset;
    media_item["name"] = `${this.TitleCase.transform(targetType)}: ${this.eventInfo} for ${this.userInfo}`;

    this.modalOptions.data = {
      user: this.user,
      media_item: media_item,
      teamID: this.teamID,
      previewOnly: true,
      mediaNameOnly: true,
      secondaryTitle: `${this.startDate} - ${this.endDate}`
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "media-details-modal",
      class:
        this.modalOptions.class +
        " modal-full-height modal-right media-display aia-display",
    };

    this.modalFrame = this.modalService.show(
      ManageMediaViewComponent,
      this.modalOptions
    );

  }

  onAssessmentClick(event, column) {
    console.log("event in onAssessmentClick", event);
    let media = event.rowData.generated_media_types;
    let targetType = event.targetType;

    let recordingID = event.rowData.id;
    let aiExperienceID = media["transcript_json"][0]["transcript_json"]["sessionExperienceId"];

    this.router.navigate(['client', this.clientCode, 'analytics', 'aia', 'session', this.sessionID, 'assessment', targetType], {
      queryParams: {
        ...this.queryParams,
        ai_exp_id: aiExperienceID,
        recording_id: recordingID,
        ai_model: this.aiModel
      }
    });

  }

  private async retrieveData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let gridData = await this.retrieveGridData().catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    this.rowData = gridData.aia_session_data.aia_sessions;
    this.event = gridData.aia_session_data.event;

    let startDate = gridData.aia_session_data.start_time;
    let endDate = gridData.aia_session_data.end_time;

    if (startDate !== null) {
      this.startDate = moment(startDate).tz(this.tz_iana).format("MMM DD YYYY hh:mm A");
    }

    if (endDate !== null && !endDate.toLowerCase().includes("progress")) {
      this.endDate = moment(endDate).tz(this.tz_iana).format("MMM DD YYYY hh:mm A");

      //if endDate has the same year as startDate, remove the year from endDate
      if (this.endDate.includes(moment(startDate).tz(this.tz_iana).format("YYYY"))) {
        this.endDate = this.endDate.replace(moment(startDate).tz(this.tz_iana).format("YYYY"), "");
      }

      //if endDate has the same month as startDate, remove the month from endDate
      if (this.endDate.includes(moment(startDate).tz(this.tz_iana).format("MMM"))) {
        this.endDate = this.endDate.replace(moment(startDate).tz(this.tz_iana).format("MMM"), "");
      }

      //if endDate has the same day as startDate, remove the day from endDate
      if (this.endDate.includes(moment(startDate).tz(this.tz_iana).format("DD"))) {
        this.endDate = this.endDate.replace(moment(startDate).tz(this.tz_iana).format("DD"), "");
      }
    }

    this.backRoute = [
      '/client',
      this.clientCode,
      'analytics',
      'aia'
    ];

    this.eventInfo = this.event.event_name;

    this.retrieveAIExperience();
    this.user = gridData.aia_session_data.user;

    this.userInfo = this.showUserInformation ? `${this.user.username}` : `User ID: ${this.user.id}`;

    console.log("this.rowData", this.rowData);

    this.gridLoading = false;
  }

  private retrieveGridData() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let data = {}

    return this._metricsServicesService.restfulForetellAPIRequest(this.targetURL, "get", data, getOptions, true).toPromise();

  }

  private retrieveAIExperience() {

    let aiExperience = this.event.experiences[0].ai_experience;
    this.event["ai_experience"] = aiExperience;

  }

  public onDownloadCSV() {
    this.gridApi.exportDataAsCsv(this.getParams());
  }

  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  private getParams() {

    let start_date = moment(this.queryParams.start).format("YYYY-MM-DD");
    let end_date = moment(this.queryParams.end).format("YYYY-MM-DD");

    return {
      fileName: `AI_Session_${this.event.event_name}_for_${this.userInfo}_${start_date}_to_${end_date}_pulled_${moment().format("YYYY-MM-DD")}.csv`
    };
  }
}
