import {
  Component,
  Input,
  OnInit,
  AfterViewChecked,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TitleCasePipe } from "@angular/common";

import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
  INumberFilterParams,
  ITextFilterParams,
  SizeColumnsToFitGridStrategy,
} from "ag-grid-community";

import { IMyOptions } from "ng-uikit-pro-standard";
import { ActivatedRoute, Router } from "@angular/router";
import { MetricsServicesService } from "../services/metrics-services.service";
import zoomPlugin from "chartjs-plugin-zoom";
import * as moment from "moment";
import { DateTime, IANAZone } from "luxon";
import * as timezoneFixes from "src/assets/timezones/ianaToAbbreviation.json";
import "hammerjs";
import "chartjs-plugin-zoom";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';
Chart.register(zoomPlugin);
import { SizeColumnsToFitProvidedWidthStrategy } from 'ag-grid-community';
import { SizeColumnsToContentStrategy } from 'ag-grid-community';

import { CoolLocalStorage } from "@angular-cool/storage";
import { NotificationsService } from "src/app/services/utilities/notifications.service";

export class chartDates {
  constructor(public start: string, public end: string) { }
}

@Component({
  selector: "app-metrics-interactive",
  templateUrl: "./metrics-interactive.component.html",
  styleUrls: ["./metrics-interactive.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe],
})
export class MetricsInteractiveComponent implements OnInit, AfterViewChecked {
  //incoming
  @Input("teamID") teamID: number;
  @Input("clientCode") clientCode: string;

  //timezone
  public tz: any = moment.tz(moment.tz.guess()).format("z");

  //persistent
  public metricsLoading: boolean = true;
  public init: boolean = true;
  private baseURL: string;
  public labels;
  private gridApi!: GridApi;

  //grid config
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
      type: "fitGridWidth",
    };
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 220
  };
  public rowData!: any[];
  public skillRowData!: any[];
  public durtionChrtData!: any[];
  public themeClass: string =
    "ag-theme-quartz-dark";

  //table sorting
  public sortBy = "none";
  public sortOrder = "none";

  //table paginiation
  public first: number = 0;
  public rows: number = 50;
  public page: number = 1;
  public sessionsLength: number = 0;
  public allSessions: any[] = [];
  public originalSessions: any[] = [];

  @ViewChild("sessionsChart") private chartRef: ElementRef;
  private chart: Chart;

  //form vars
  public metricsForm: UntypedFormGroup;

  //chart vars - sample data
  public chartLabel = "";
  public chartType: string = "horizontalBar";
  public chartDatasets: Array<any> = [
    {
      data: [],
      label: "",
      lineTension: 0,
    },
  ];
  public chartLabels: Array<any> = [];
  public chartColors: Array<any> = [
    {
      backgroundColor: ["rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(54, 162, 235, 1)"],
      borderWidth: 2,
    },
  ];
  public chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // public maxDate = moment();
  public model = new chartDates("", "");
  public startDateLabel: string = "Start Date";
  public endDateLable: string = "End Date";

  // charts options
  //chart vars - date selection chart
  public date = new Date();
  public disableDatesObj = { year: 0, month: 0, day: 0 };
  public disabledUntil = { year: 0, month: 0, day: 0 };
  public myDatePickerOptions: IMyOptions = {
    alignSelectorRight: true,
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableSince: this.disableDatesObj,
  };
  public endDateoickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableUntil: this.disabledUntil,
    disableSince: this.disableDatesObj,
  };

  public metricsLoadingDC: boolean = true;
  private chartCreated = false;
  private chartData: any;
  public dateAggregateTitle = "";
  public dateAggregates: {
    value: number;
    label: string;
  }[];
  public chartLabelDC = "";
  public chartLabelsDC: Array<any> = [];
  public chartOptionsDC: any = {
    indexAxis: "x",
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Total Connections",
        },
      },
      x: {
        title: {
          display: true,
          text: "Time Frame",
        },
      },
    },
    interaction: {
      mode: "point",
    },
    //onClick: function adjustLineEndPoints(context) {  }
  };
  public timeDenomination = [
    { value: "hour", label: "Hourly" },
    { value: "day", label: "Daily" },
    { value: "month", label: "Monthly" },
    { value: "year", label: "Yearly" },
  ];

  public timeSelector: UntypedFormControl = new UntypedFormControl("day");

  //chart vars - line data test
  public chartTypeLT: string = "bar";
  public chartDatasetsLT: Array<any> = [];
  public chartLabelsLT: Array<any> = [];

  public yLabels: Array<string>;

  public chartOptionsLT: any = {};
  public chartColorsLT: Array<any> = [
    {
      backgroundColor: ["#7F95D1"],
      borderColor: ["#7F95D1"],
    },
  ];

  public lineBreakLoading = false;
  public lineBreakSelected = false;

  public basicLoad = false;

  public sessions: any[] = [];
  public sessionsLoaded: boolean = false;
  headElements = [];
  sort_elements = [
    "session_name",
    "distinct_users",
    "total_connections",
    "start_time",
    "end_time",
    "duration",
  ];

  public start;
  public end;
  public breakdownType;

  searchText: string = "";
  previous: string;

  public currentSort = "";
  public ascSort = false;

  //data
  private queryParams = {
    is_teacher: true,
    page_number: '1',
    page_size: '25',
    start: '',
    end: '',
    tmz: 'UTC'
  };

  constructor(
    private route: ActivatedRoute,
    private _metricsServicesService: MetricsServicesService,
    private coolLocalStorage: CoolLocalStorage,
    private _notificationsService: NotificationsService,
    private TitleCase: TitleCasePipe,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.retrieveLabels();

    this.headElements = [
      `${this.TitleCase.transform(this.labels.event.singular)} Name`,
      "Distinct Users",
      "Total Entries",
      "Start Time",
      "End Time",
      "Duration",

    ];

    this.resolveTimeZone();

    //labels in headElements
    this.headElements[0] = `${this.TitleCase.transform(
      this.labels.event.singular
    )} Name`;
    this.headElements[1] = `Distinct ${this.TitleCase.transform(
      this.labels.user.plural
    )}`;
    this.headElements[3] = `${this.TitleCase.transform(
      this.labels.startTime.singular
    )}`;
    this.headElements[4] = `${this.TitleCase.transform(
      this.labels.endTime.singular
    )}`;

    this.startDateLabel = this.TitleCase.transform(
      this.labels.startDate.singular
    );
    this.endDateLable = this.TitleCase.transform(this.labels.endDate.singular);

    this.lineBreakLoading = true;
    //this.setupUserBreakdown()

    this.route.queryParams.subscribe((params) => {

      console.log("params", params);
      this.chartInit(params);

    });
  }

  private async chartInit(params) {
    let start_date = params["start_date"];
    let end_date = params["end_date"];

    let breakdownType = params["breakdownType"];
    if (breakdownType != undefined) {
      this.timeSelector = new UntypedFormControl(breakdownType);
    }
    if (start_date != undefined && end_date != undefined) {
      this.model = new chartDates(
        moment(start_date).format("MMM D, YYYY"),
        moment(end_date).format("MMM D, YYYY")
      );
      await this.setupDateChart();
    } else {
      // const date = new Date()
      const today = moment();
      // called moment funct because the local var gets reasigned
      this.model = new chartDates(
        today.subtract(7, "d").format("MMM D, YYYY"),
        moment().format("MMM D, YYYY")
      );
      await this.setupDateChart();
    }

    this.disabledDates(moment());
    this.generateUntilDate();
  }

  ngAfterViewChecked() {
    if (!this.chartCreated && this.chartRef && this.chartRef.nativeElement && !this.metricsLoadingDC) {
      this.chartCreated = true;

      console.log("creating chart");

      this.chart = new Chart(this.chartRef.nativeElement, {
        type: "bar",
        data: this.chartData,
        options: this.chartOptionsDC,
      });
    }
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  public async setupDateChart() {
    this.metricsLoadingDC = true;

    console.log("setupDateChart");

    let start = this.model.start;
    let end = this.model.end;
    this.queryParams.start = moment(start).format("YYYY-MM-DD");
    this.queryParams.end = moment(end).format("YYYY-MM-DD");

    try {
      const chartResults = await this._metricsServicesService.setupDateChart(
        this.chart,
        this.model,
        this.timeSelector,
        this.timeDenomination,
        this.chartRef,
        this.chartData,
        this.labels
      );

      console.log("chartResults", chartResults);

      // Now you have all the data and everything is complete
      this.chart = chartResults.chart;
      this.start = chartResults.start;
      this.end = chartResults.end;
      this.breakdownType = chartResults.breakdownType;
      this.chartLabelDC = chartResults.chartLabelDC;
      this.chartLabelsDC = chartResults.chartLabelsDC;
      this.dateAggregateTitle = chartResults.dateAggregateTitle;
      this.dateAggregates = chartResults.dateAggregates;
      this.chartData = chartResults.chartData;
      this.chartOptionsDC = chartResults.chartOptionsDC;
      this.chartCreated = chartResults.chartCreated;
      this.timeDenomination = chartResults.timeDenomination;
      this.timeSelector = chartResults.timeSelector;

      const sessionResults = await this._metricsServicesService.retrieveAnalticsSessions(this.model, this.timeSelector);

      this.sessions = sessionResults.sessions;
      this.allSessions = [...this.sessions];
      this.originalSessions = [...this.sessions];
      this.rowData = sessionResults.sessions;

    } catch (error) {
      console.log("error in setupDateChart", error);
      this._notificationsService.errorNotification("There was a problem loading the analytics data. Please refresh and try again.")
    } finally {
      //paginate sessions

      this.buildColumnDefs();

      this.metricsLoadingDC = false;
      this.onPageChange({ page: 0 });
      this.sessionsLoaded = true;
    }
  }

  private buildColumnDefs() {

    let columns = [
      {
        headerName: 'Meeting Session Name', field: 'session_name', type: 'text', filter: "agTextColumnFilter", cellStyle: { 'cursor': 'pointer' }, filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: 'Distinct Users', field: 'distinct_users', type: 'number', filter: 'agNumberColumnFilter', cellStyle: { 'cursor': 'pointer' }, filterParams: {
          buttons: ["clear"],
        } as INumberFilterParams
      },
      {
        headerName: 'Total Entries', field: 'total_connections', type: 'number', filter: 'agNumberColumnFilter', cellStyle: { 'cursor': 'pointer' }, filterParams: {
          buttons: ["clear"],
        } as INumberFilterParams
      },
      {
        headerName: 'Start Time', field: 'start_time', type: 'date', cellStyle: { 'cursor': 'pointer' },
      },
      {
        headerName: 'End Time', field: 'end_time', type: 'date', cellStyle: { 'cursor': 'pointer' },
      },
      {
        headerName: 'Duration', field: 'duration', type: 'text', filter: "agTextColumnFilter", cellStyle: { 'cursor': 'pointer' }, filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
    ]

    this.columnDefs = this._metricsServicesService.createColumnDefinitions(columns);
  }

  private resolveTimeZone() {
    let tz_iana = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.tz = moment.tz(tz_iana).format("z");

    //if the string includes a negative number in the form -03 or -02, check for a timezone fix
    if (this.tz.includes("-")) {
      let fix = timezoneFixes[tz_iana];

      if (fix !== undefined) {
        this.tz = fix;
      } else {
        //fall back to UTC from Luxon
        this.tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
      }
    } else if (this.tz.includes("+")) {
      //fall back to UTC from Luxon
      //@todo: update timezone fixes to include plus returns
      this.tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
    }
  }

  public checkDateRange(event, dateField, startDate?, endDate?) {

    console.log("startDate", startDate);
    console.log("endDate", endDate);

    let incoming = this._metricsServicesService.checkDateRange(
      event,
      dateField,
      this.timeDenomination,
      this.timeSelector,
      this.model,
      startDate,
      endDate
    );

    this.timeDenomination = incoming.timeDenomination;
    this.timeSelector = incoming.timeSelector;

    if (dateField === "start") {
      let yesterday = moment(event.actualDateFormatted).subtract(1, "days").format("M-D-YYYY");
      let parseYesterday = yesterday.split("-");
      this.endDateoickerOptions = {
        ...this.endDateoickerOptions,
        disableUntil: {
          year: parseInt(parseYesterday[2]),
          month: parseInt(parseYesterday[0]),
          day: parseInt(parseYesterday[1]),
        },
      };
    }
  }

  /**
   * Custom table sorting based on current head key
   * @param head
   * @param direction
   */
  public sortTable(head, direction) {
    this.sortBy = head;
    this.sortOrder = direction;

    switch (head) {
      case "session_name":
        if (direction === "desc") {
          this.sessions = this.sessions.sort((a, b) => {
            return a[head].localeCompare(b[head]);
          });
        } else {
          this.sessions = this.sessions.sort((a, b) => {
            return b[head].localeCompare(a[head]);
          });
        }

        break;
      case "start_time":
      case "end_time":
        let tmz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let endParsed =
          moment(this.model.end).format("MMMM DD YYYY") + " 11:59 PM " + tmz;
        let endDateTime = DateTime.fromFormat(endParsed, 'LLLL dd yyyy h:mm a z', { setZone: true });
        let end = endDateTime.toISO();
        if (direction === "desc") {
          this.sessions = this.sessions.sort((a, b) => {
            if (a[head] === null || a[head].toLowerCase().indexOf("in progress") !== -1)
              a[head] = end;
            if (b[head] === null || b[head].toLowerCase().indexOf("in progress") !== -1)
              b[head] = end;

            let dateA = moment(a[head]).format("X");
            let dateB = moment(b[head]).format("X");

            return parseInt(dateB) - parseInt(dateA);
          });
        } else {
          this.sessions = this.sessions.sort((a, b) => {
            if (a[head] === null || a[head].toLowerCase().indexOf("in progress") !== -1)
              a[head] = end;
            if (b[head] === null || b[head].toLowerCase().indexOf("in progress") !== -1)
              b[head] = end;

            let dateA = moment(a[head]).format("X");
            let dateB = moment(b[head]).format("X");

            return parseInt(dateA) - parseInt(dateB);
          });
        }

        break;
      case "duration":
        if (direction === "desc") {
          this.sessions = this.sessions.sort((a, b) => {
            return (
              this.convertDuration(b[head]) - this.convertDuration(a[head])
            );
          });
        } else {
          this.sessions = this.sessions.sort((a, b) => {
            return (
              this.convertDuration(a[head]) - this.convertDuration(b[head])
            );
          });
        }

        break;
      default:
        if (direction === "desc") {
          this.sessions = this.sessions.sort((a, b) => {
            return b[head] - a[head];
          });
        } else {
          this.sessions = this.sessions.sort((a, b) => {
            return a[head] - b[head];
          });
        }
    }
  }

  private convertDuration(incoming: string) {
    let hoursCalc = 0;
    let minutesCalc = 0;

    if (incoming.toLowerCase() === "in progress") return -1;

    if (incoming.indexOf("h") !== -1) {
      let getHours = incoming.split("h");
      hoursCalc = parseInt(getHours[0]) * 60;
      incoming = getHours[1];
    }

    if (incoming.indexOf("m") !== -1) {
      let getMinutes = incoming.split("m");
      minutesCalc = parseInt(getMinutes[0]);
    }

    return hoursCalc + minutesCalc;
  }

  public chartClicked(e: any): void {
    if (e.active[0].element.parsed.x) {
    }
  }
  public chartHovered(e: any): void { }

  public disabledDates(currentDate: any) {
    let today = currentDate;
    let endDays = [28, 29, 30, 31];
    // handle year
    if (today.month() === 11 && today.date() === 31) {
      this.disableDatesObj.year = today.year() + 1;
    } else {
      this.disableDatesObj.year = today.year();
    }
    // handle month
    // handle day
    if (endDays.every((day) => day === today.date())) {
      // I need to find a way to test this
      this.disableDatesObj.day = 1;
      this.disableDatesObj.month = today.month() + 2;
    } else {
      this.disableDatesObj.day = today.date() + 1;
      this.disableDatesObj.month = today.month() + 1;
    }
  }

  // todo: edgecases for first dat of the month, and or last day of the month.
  public generateUntilDate() {
    // let today = currentDate;
    let untilDate = this.model.start;
    this.disabledUntil.year = parseInt(moment(untilDate).format("YYYY"));
    this.disabledUntil.day = parseInt(moment(untilDate).format("DD")) - 1;
    this.disabledUntil.month = parseInt(moment(untilDate).format("MM"));
  }

  public onSearchTextChange(newValue: string) {
    console.log("newValue", newValue);

    //if the search text is empty, reset the sessions to the original sessions
    if (newValue === "") {
      this.sessions = [...this.originalSessions];
      this.allSessions = [...this.sessions];
      this.onPageChange({ page: 0 });
    } else {
      this.sessions = this.allSessions.filter((session) => {
        return session.session_name.toLowerCase().includes(newValue.toLowerCase());
      });
      this.allSessions = [...this.sessions];
      //paginate sessions
      this.onPageChange({ page: 0 });
    }
  }

  onPageChange(event) {
    this.page = event.page + 1;
    this.sessions = this.allSessions.slice((this.page - 1) * this.rows, (this.page - 1) * this.rows + this.rows);
  }

  public onCellClicked(event) {

    this.router.navigate(['client', this.clientCode, 'analytics', event.data['session_id']], {
      queryParams: {
        breakdownType: this.breakdownType,
        start_date: this.start,
        end_date: this.end
      }
    });

  }

  public onDownloadCSV() {
    this.gridApi.exportDataAsCsv(this.getParams());
  }

  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  private getParams() {

    let start_date = moment(this.queryParams.start).format("YYYY-MM-DD");
    let end_date = moment(this.queryParams.end).format("YYYY-MM-DD");

    return {
      fileName: `Meeting_Sessions_${start_date}_to_${end_date}_pulled_${moment().format("YYYY-MM-DD")}.csv`
    };
  }
}
