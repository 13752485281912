<div class="modal-content-wrapper">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">Sharing {{ media_item.name | titlecase }}</h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="shareModal.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="add-user-management-card content-card modal-fluid-content">
    <div class="row d-flex align-items-center">
      <div class="col-4 available-users">
        <div class="md-form">
          <input
            type="search"
            [(ngModel)]="SearchText"
            (ngModelChange)="search()"
            class="form-control"
            id="Search"
            [disabled]="usersLoading"
            mdbInput
          />
          <label class="active-label active" for="Search"
            >Search Available {{ labels.user.plural | titlecase }}</label
          >
        </div>
      </div>
    </div>
    <div class="row fluid-content">
      <div class="col-4 h-100 tabs-table available">
        <mdb-card class="user-card h-100">
          <mdb-tabset
            #availableTabs
            id="availableTabs"
            [buttonClass]="'classic-tabs'"
            class="admin-tab-styles"
            contentClass="remove-card available-tabs h-100"
            tabsButtonsClass="tab-nav-fluid"
            tabsContentClass="tab-content-fluid"
          >
            <mdb-tab heading="Share With"
              ><div
                *ngIf="usersLoading"
                class="loading-screen d-flex align-items-center h-100 justify-content-center"
              >
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <table
                *ngIf="!usersLoading"
                class="table table-responsive-md btn-table available-users-table"
                mdbTable
                mdbTableScroll
                small="true"
                scrollY="true"
              >
                <colgroup>
                  <col span="1" style="width: 20px" />
                  <col span="1" style="width: 100%" />
                </colgroup>
                <tbody>
                  <tr
                    mdbTableCol
                    *ngFor="let user of userList; index as user_index"
                  >
                    <td class="pr-0">
                      <button
                        mdbBtn
                        mdbWavesEffect
                        class="btn-flat btn-icon"
                        (click)="
                          addUser(
                            user.first_name,
                            user.last_name,
                            user.id,
                            user.username
                          )
                        "
                      >
                        <fa-icon [icon]="faSquarePlus"></fa-icon>
                      </button>
                    </td>
                    <td>
                      {{ user.first_name }} {{ user.last_name }} ({{
                        user.username
                      }})
                    </td>
                  </tr>
                </tbody>
              </table></mdb-tab
            >
          </mdb-tabset>
        </mdb-card>
      </div>
      <div class="col-8 h-100 tabs-table roster">
        <mdb-tabset
          #addedTabs
          id="addedTabs"
          [buttonClass]="'classic-tabs'"
          class="admin-tab-styles"
          contentClass="remove-card added-tabs h-100"
          tabsButtonsClass="tab-nav-fluid"
          tabsContentClass="tab-content-fluid"
        >
          <mdb-tab heading="Shared With"
            ><div
              *ngIf="rosterLoading"
              class="loading-screen d-flex align-items-center h-100 justify-content-center"
            >
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div
              class="responsive"
              *ngIf="addedUsers.length > 0 && !rosterLoading"
            >
              <table
                class="table table-striped table-responsive-md btn-table user-table"
                mdbTable
                mdbTableScroll
                #tableEl="mdbTable"
                small="true"
                scrollY="true"
                maxHeight="450"
              >
                <colgroup>
                  <col span="1" style="width: 22%" />
                  <col span="1" style="width: 22%" />
                  <col span="1" style="width: 22%" />
                  <col span="1" style="width: 34%" />
                </colgroup>
                <thead class="theme-base-medium-dark white-text sticky-top">
                  <tr>
                    <th>Remove From Sharing</th>
                    <th>First</th>
                    <th>Last</th>
                    <th>Username</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    mdbTableCol
                    *ngFor="let user of addedUsers; index as user_index"
                  >
                    <td>
                      <button
                        [disabled]="user.disabled"
                        mdbBtn
                        mdbWavesEffect
                        class="btn-flat btn-icon"
                        (click)="removeAddedUser(user.id)"
                      >
                        <fa-icon [icon]="faSquareMinus"></fa-icon>
                      </button>
                    </td>
                    <td>{{ user.first_name }}</td>
                    <td>
                      {{ user.last_name }}
                    </td>
                    <td>
                      {{ user.username }}
                    </td>
                    <td>
                      {{ user.role }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="empty-message" *ngIf="addedUsers.length === 0">
              <p class="m-0">
                User roster is currently empty. Add Users on the left, or switch
                to Available Groups and add Users via User Groups
              </p>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      type="button"
      mdbWavesEffect
      class="theme-modal-button btn"
      [disabled]="isClean || processing"
      (click)="updateSharing()"
    >
      Update Sharing
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      (click)="shareModal.hide()"
    >
      Close
    </button>
  </div>
</div>
