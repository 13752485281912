import { CoolLocalStorage } from "@angular-cool/storage";
import { DOCUMENT, TitleCasePipe } from "@angular/common";
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";
import { MDBModalRef, PopoverDirective } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { EventServicesService } from "src/app/modules/event-management/services/event-services.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";

import {
  faCirclePlus,
  faSquareMinus,
  faUsers,
  faSquarePlus,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NotificationsService } from "src/app/services/utilities/notifications.service";

@Component({
  selector: "app-manage-user-group-roster",
  templateUrl: "./manage-user-group-roster.component.html",
  styleUrls: ["./manage-user-group-roster.component.scss"],
  providers: [TitleCasePipe],
})
export class ManageUserGroupRosterComponent implements OnInit {
  @ViewChild("popOverTrigger") popOverTrigger: PopoverDirective;
  @ViewChild("popOverTriggerTop") popOverTriggerTop: PopoverDirective;


  //fonts
  faCirclePlus = faCirclePlus;
  faSquareMinus = faSquareMinus;
  faUsers = faUsers;
  faSquarePlus = faSquarePlus;
  faTimes = faTimes;

  //incoming
  public action: string;
  public targetGroup: any;
  public from: string = "user-groups";
  public teamID: number;
  public labels: any;
  public parentModalClass: string = "";

  //persistent
  private token: string = null;
  public SearchText: string;
  public isClean: boolean = true;
  public usersLoading: boolean = true;
  public rosterLoading: boolean = true;
  public number_of_events: number = 0;

  //outbound
  private outgoing: Subject<any> = new Subject();

  //copy
  public title: string;

  //userdata
  public userList: any;
  public origUserList: any;
  public unchanagedUserList: any;
  public inviteeUsers: any = [];
  public origInviteeUsers: any = [];
  public addedUsers: any = [];
  public removedUsers: any = [];

  //other data
  public personas: any;

  //form related
  public formLoading: boolean = true;
  public formState: string = "active";
  public groupingForm: UntypedFormGroup;
  public showMain: boolean = false;
  public triggerUserWarning: boolean = false;
  public preSelected: {
    group_name: string;
    group_description: string;
  } = {
      group_name: "",
      group_description: "",
    };

  constructor(
    public manageGroupRosterFrame: MDBModalRef,
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private eventService: EventServicesService,
    private elementRef: ElementRef,
    private cdref: ChangeDetectorRef,
    private _notificationService: NotificationsService,
    private TitleCase: TitleCasePipe,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    console.log(
      "this.targetGroup in AddUserComponent::init()",
      this.targetGroup
    );

    if (this.action === undefined) this.action = "add";

    if (this.action === "update") this.number_of_events = this.targetGroup.number_of_events;

    this.title = `${this.action === "add" ? "Add" : "Update"} ${this.labels.userGroup.singular
      }`;

    this.retrieveToken();
    this.retrieveData();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private async retrieveData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    //retrieve users
    let users = await this.retreiveUsers(getOptions);

    //retrieve personas
    let personas = await this.retrievePersonas(getOptions);

    //if editing, retrieve Group
    if (this.action === "update") {
      let groupData = await this.retrieveGroup(getOptions);
      this.inviteeUsers = groupData.members;
      this.origInviteeUsers = groupData.members;
      //just to make sure we have the latest group data
      this.targetGroup = groupData.group;
    }

    this.processUsers(users, personas.personas);

    this.usersLoading = false;
    this.rosterLoading = false;

    this.makeFormValidatingGroup();
  }

  /**
   * Retrieve Team Users
   * @param options
   * @returns
   */
  private retreiveUsers(options) {
    let retrieverUsers = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/users",
      "get",
      {},
      options
    );

    return retrieverUsers.toPromise();
  }

  private retrievePersonas(options) {
    let retrievePersonas = this._xrPlatformRestService.restfulAPIQuery(
      "/personas/findpersonasbyclient/" + this.teamID,
      "get",
      {},
      options
    );

    return retrievePersonas.toPromise();
  }

  private retrieveGroup(options) {
    let retrieveGroup = this._xrPlatformRestService.restfulAPIQuery(
      "/group/" + this.targetGroup.id + "/members",
      "get",
      {},
      options
    );

    return retrieveGroup.toPromise();
  }

  private processUsers(users, personas) {
    //process users
    users.forEach((element, index) => {
      if (element.meta && element.meta.persona_id) {
        const personaDetails = personas.filter(
          (item) => item.id === element.meta.persona_id
        );
        if (personaDetails.length > 0) {
          users[index].personaName = personaDetails[0].persona_name;
        } else {
          users[index].personaName = "Not Set";
        }
      } else {
        users[index].personaName = "Not Set";
      }

      let role = "Not Set";

      if (element.meta !== null && element.meta.role !== undefined) {
        switch (element.meta.role) {
          case 1:
            role = "Observer";
            break;
          case 2:
            role = "Leader";
            break;
          case 3:
            role = "Participant";
            break;
        }
      }

      users[index].role = role;
    });

    users = users.sort((a, b) =>
      a.first_name > b.first_name ? 1 : b.first_name > a.first_name ? -1 : 0
    );

    this.origUserList = JSON.parse(JSON.stringify(users));
    this.unchanagedUserList = JSON.parse(JSON.stringify(users));
    this.userList = users;

    console.log(
      "inviteeUsers in AddUserComponent::processUsers()",
      this.inviteeUsers
    );
  }

  buildPreselected() {
    if (this.action === "update" && this.targetGroup) {
      this.preSelected.group_name =
        this.targetGroup.name === undefined ? null : this.targetGroup.name;
      this.preSelected.group_description =
        this.targetGroup.description === undefined ||
          this.targetGroup.description === null
          ? "No description"
          : this.targetGroup.description;

      if (this.inviteeUsers.length > 0) {
        this.inviteeUsers.forEach((element) => {
          let user = this.origUserList.filter(
            (item) => item.id === element.other_id
          );

          if (user.length > 0) {
            this.addUser(
              user[0].first_name,
              user[0].last_name,
              user[0].personaName,
              user[0].role,
              user[0].id,
              user[0].username
            );
          }
        });

        this.isClean = true;
      }
    }
  }

  private makeFormValidatingGroup() {
    this.buildPreselected();

    this.groupingForm = new UntypedFormGroup({
      group_name: new UntypedFormControl(
        this.preSelected.group_name,
        Validators.required
      ),
      group_description: new UntypedFormControl(
        this.preSelected.group_description
      ),
    });

    //detect changes to group_name and group_description in order to flip isClean
    this.groupingForm.controls["group_name"].valueChanges.subscribe((val) => {
      this.isClean = false;
    });

    this.groupingForm.controls["group_description"].valueChanges.subscribe(
      (val) => {
        console.log(
          "group_description val in AddUser::makeFormValidatingGroup()",
          val
        );
        this.isClean = false;
      }
    );

    console.log(
      "this.groupingForm in AddUser::makeFormValidatingGroup()",
      this.groupingForm
    );

    this.formLoading = false;
  }

  get group_name() {
    return this.groupingForm.get("group_name");
  }

  get group_description() {
    return this.groupingForm.get("group_description");
  }

  public showingWarning() {
    this.groupingForm.markAllAsTouched();
    this.triggerUserWarning = true;
  }

  addUser(firstName, lastName, personaName, role, id, username) {
    const isUserAlreadyAdded =
      this.addedUsers.filter((item) => item.id === id).length > 0;
    if (!isUserAlreadyAdded) {
      this.userList = this.userList.filter((item) => item.id !== id);
      this.origUserList = this.origUserList.filter((item) => item.id !== id);
      this.addedUsers.push({
        first_name: firstName,
        last_name: lastName,
        username: username,
        expPersona: personaName ? personaName : "Not Set",
        role: role,
        id: id,
      });

      //when in update mode, make sure this added user is not on the remove list
      //i.e. if a user removes a user, then decides to add them back in again
      if (this.action === "update") {
        this.removedUsers = this.removedUsers.filter((removed_id) => {
          return removed_id !== id;
        });

        this.isClean = this.checkForRosterChanges(
          this.origInviteeUsers,
          this.addedUsers
        );
      } else if (this.action === "add") {
        this.isClean = false;
      }

      if (this.addedUsers.length >= 100) {
        this._notificationService.errorNotification("Maximum limit reached for user group");
      }
    }
  }

  removeAddedUser(id) {
    this.addedUsers.forEach((element, index) => {
      if (element.id == id) {
        this.addedUsers.splice(index, 1);
      }
    });
    const removedItem = this.unchanagedUserList.filter(
      (item) => item.id && item.id == id
    );

    //if this is an update action, add removed user to the removedUsers array
    if (this.action === "update") {
      this.removedUsers.push(removedItem[0]);

      this.isClean = this.checkForRosterChanges(
        this.origInviteeUsers,
        this.addedUsers
      );
    }

    if (this.action === "add" && this.addedUsers.length === 0)
      this.isClean = true;

    this.userList.push(removedItem[0]);
    this.userList.sort((a, b) =>
      a.first_name > b.first_name ? 1 : b.first_name > a.first_name ? -1 : 0
    );
    this.origUserList.push(removedItem[0]);
    this.origUserList.sort((a, b) =>
      a.first_name > b.first_name ? 1 : b.first_name > a.first_name ? -1 : 0
    );
  }

  public closeModal() {
    let mainEventDisplay = this.document.getElementsByClassName(
      this.parentModalClass
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("secondary");
      mainEventDisplay.item(0).classList.add("restore-primary");
    }

    this.manageGroupRosterFrame.hide();
  }

  search(): void {
    const searchKey = this.SearchText.toLowerCase();
    this.userList = this.origUserList.filter(function (tag) {
      return (
        tag.first_name.toLowerCase().indexOf(searchKey) >= 0 ||
        tag.last_name.toLowerCase().indexOf(searchKey) >= 0 ||
        tag.username.toLowerCase().indexOf(searchKey) >= 0 ||
        (
          tag.first_name.toLowerCase() +
          " " +
          tag.last_name.toLowerCase()
        ).indexOf(searchKey) >= 0
      );
    });
  }

  public async rosterActions() {
    this.formState = "processing";
    let incomingValues = this.groupingForm.value;
    let members = this.addedUsers;
    let newMembers = [];
    let number_of_members = members.length;
    let removedMembers = this.removedUsers;

    //add toast notifications
    let actionAdj = this.action.replace(/e$/, "");
    let notification = this._notificationService.savingNotification(
      `${this.TitleCase.transform(actionAdj)}ing ${this.labels.userGroup.singular
      }`
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let groupID = null;
    let groupOut = {
      name: incomingValues.group_name,
      description: incomingValues.group_description,
      number_of_members: number_of_members,
      number_of_events: this.number_of_events,
    };

    if (this.action === "add") {
      let addGroup = await this.addGroup(options, incomingValues);
      groupID = addGroup.group.id;
      groupOut = addGroup.group;
    } else {
      groupID = this.targetGroup.id;

      if (this.action === "update") {
        let updateGroup = await this.updateGroup(
          headers,
          incomingValues,
          groupID
        );

        groupOut = updateGroup.group;
      }
    }

    if (members.length > 0) {
      let addGroupMembers = await this.addGroupMembers(
        options,
        members,
        groupID
      );
      number_of_members = addGroupMembers.number_of_members;
      newMembers = addGroupMembers.members;
      groupOut = addGroupMembers.group;
    }

    if (removedMembers.length > 0) {
      let removeGroupMembers = await this.removeGroupMembers(
        options,
        removedMembers,
        groupID
      );

      number_of_members = removeGroupMembers.number_of_members;
      newMembers = removeGroupMembers.members;
      groupOut = removeGroupMembers.group;
    }

    console.log(
      "this.targetGroup in AddUser::rosterActions()",
      this.targetGroup
    );
    console.log("this.action in AddUser::rosterActions()", this.action);

    console.log("groupOut in AddUser::rosterActions()", groupOut);

    let outgoing = {
      action: this.action,
      group: {
        id: groupID,
        name: groupOut.name,
        description: groupOut.description,
        number_of_members: groupOut.number_of_members,
        number_of_events:
          this.action === "add" ? 0 : groupOut.number_of_events,
        members: newMembers,
      },
    };

    this.outgoing.next(outgoing);
    this._notificationService.clearNotification(notification);
    this.closeModal();
  }

  private addGroup(options, incoming) {
    let addGroup = this._xrPlatformRestService.restfulAPIQuery(
      "/group",
      "post",
      {
        name: incoming.group_name,
        description: incoming.group_description,
        team_id: this.teamID,
        group_type: "user",
      },
      options
    );

    return addGroup.toPromise();
  }

  /**
   * Updating group
   * @param options
   * @param members
   * @param groupID
   * @returns
   */
  private updateGroup(headers, incomingValues, groupID) {
    let thisGroup = {
      name: incomingValues.group_name,
      description: incomingValues.group_description,
    };

    let options = {
      body: JSON.stringify(thisGroup),
    };

    let manageGroup = this._xrPlatformRestService.manageEntity(
      "group",
      groupID,
      "update",
      options,
      headers
    );
    return manageGroup.toPromise();
  }

  private addGroupMembers(options, members, groupID) {
    let membersOutbound = this.buildMembers(members);

    console.log(
      "membersOutbound in AddUser::addGroupMembers()",
      membersOutbound
    );

    let addGroupMembers = this._xrPlatformRestService.restfulAPIQuery(
      "/group/" + groupID + "/members",
      "post",
      {
        members: membersOutbound,
      },
      options
    );

    return addGroupMembers.toPromise();
  }

  private removeGroupMembers(options, members, groupID) {
    let membersOutbound = this.buildMembers(members);

    let removeGroupMembers = this._xrPlatformRestService.restfulAPIQuery(
      "/group/" + groupID + "/members",
      "put",
      {
        members: membersOutbound,
      },
      options
    );

    return removeGroupMembers.toPromise();
  }

  private buildMembers(incomingMembers) {
    let outgoingMembers = [];

    incomingMembers.forEach((element) => {
      outgoingMembers.push({
        name: element.username,
        reference_id: element.id,
        type: "user",
      });
    });

    return outgoingMembers;
  }

  /**
   * For each item in origList, see if the property other_id matches the id property in any item in currentList
   * Also do the vice versa to check for new adds
   * If it does not, then there are changes
   * @param origList
   * @param currentList
   */
  private checkForRosterChanges(origList, currentList) {
    let isClean = true;

    origList.forEach((element) => {
      let isFound = currentList.filter((item) => item.id === element.other_id);
      if (isFound.length === 0) isClean = false;
    });

    currentList.forEach((element) => {
      let isFound = origList.filter((item) => item.other_id === element.id);
      if (isFound.length === 0) isClean = false;
    });

    return isClean;
  }

  public closePopOvers() {
    if (this.popOverTrigger !== undefined) this.popOverTrigger.hide();
    if (this.popOverTriggerTop !== undefined) this.popOverTriggerTop.hide();
  }

  public modalClick(event) {


    let isPopover = false;

    //check to see if this click is coming from the pop up triggers
    let target = event.target;
    let parent = target.parentElement;

    //see if fa-xmark is in the parents class list (indicates fa-icon xmark)
    if (parent !== null) {
      if (parent.classList.contains("fa-xmark")) isPopover = true;
      if (parent.classList.contains("cancel-alert")) isPopover = true;
    }

    //see if target class list has the cancel-alert class
    if (target.classList.contains("cancel-alert")) isPopover = true;

    //see if target class list has the fa-xmark class
    if (target.classList.contains("fa-xmark")) isPopover = true;

    if (!isPopover) this.closePopOvers();

  }
}
