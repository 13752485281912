import { debounceTime, filter } from "rxjs/operators";
import {
  Component,
  OnInit,
  OnChanges,
  HostListener,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import {
  MDBModalRef,
  MDBModalService,
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";
import { CollapseComponent } from "ng-uikit-pro-standard";

import {
  faFilter,
  faFilterCircleXmark,
  faSquareMinus,
  faUsersCog,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { ManageUserGroupComponent } from "./modals/manage-user-group/manage-user-group.component";
import { UserToGroupComponent } from "./modals/user-to-group/user-to-group.component";
import { UserServicesService } from "../services/user-services.service";
import { UsersByGroupComponent } from "./modals/users-by-group/users-by-group.component";
import { ManageUserGroupRosterComponent } from "./modals/manage-user-group-roster/manage-user-group-roster.component";
import { TitleCasePipe } from "@angular/common";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";
import { Subject } from "rxjs";
import { CompoundFilterPipe } from "../../shared-modules/pipes/compound-filter.pipe";
import { SingleSortPipe } from "../../shared-modules/pipes/single-sort.pipe";

@Component({
  selector: "app-user-groups",
  templateUrl: "./user-groups.component.html",
  styleUrls: ["./user-groups.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [CompoundFilterPipe, SingleSortPipe],
})
export class UserGroupsComponent implements OnInit, OnChanges {
  //inbound
  @Input("users") users: any;
  @Input("loading") loading: any;
  @Input("loadingError") loadingError: any;
  @Input("groups") groups: any;
  @Input("loadingGroups") loadingGroups: any;
  @Input("labels") labels: any;
  @Input("errorRetrievingMsg") errorRetrievingMsg: string;
  @Input("allUserGroups") allUserGroups: any;
  @Input("userGroupsLength") userGroupsLength: number;
  @Input("rows") rows: number;

  //outbound
  @Output("sendGrouplength") sendGrouplength = new EventEmitter();

  @ViewChild("pagination") pagination: any;

  //fa icons
  public faFilter = faFilter;
  public faFilterCircleXmark = faFilterCircleXmark;
  public faSquareMinus = faSquareMinus;
  public faUsersCog = faUsersCog;
  public faTrashAlt = faTrashAlt;

  public copy: string = "Group users to streamline experience management.";
  public token: string;
  public user: any;
  public teamID: number;

  //loading
  private collapsedLoaded: boolean = false;

  //table paginiation
  public first: number = 0;
  public page: number = 1;

  //filter and sort detection
  public triggerFilterUpdate: Subject<any> = new Subject();

  public groupFrame: MDBModalRef;
  public userFrame: MDBModalRef;
  public usersByGroupFrame: MDBModalRef;
  private modalOptions = {
    backdrop: "static",
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "",
    containerClass: "",
    animated: true,
    data: {},
  };

  //search handling
  public groupSearchText: string = "";
  public previous: any[];
  private mdbTableData: MdbTableDirective;
  public tableGroups: [];

  //table sorting
  headElements = [];
  sort_elements = [
    {
      type: "none",
      head: "actions",
    },
    {
      type: "string",
      head: "name",
    },
    {
      type: "number",
      head: "number_of_members",
    },
    {
      type: "number",
      head: "number_of_events",
    },
  ];
  sort = {
    direction: "asc",
    head: "name",
    type: "string",
  };
  public sortBy = "Name";
  public sortOrder = "asc";
  public sortingActive: boolean = false;

  public groupFiltersClass = "collapse show";

  //filtering
  filter_elements = [];
  //table filtering
  public filterText: {
    name: string;
    number_of_members: string;
    number_of_events: string;
  } = {
      name: "",
      number_of_members: "",
      number_of_events: "",
    };

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private coolLocalStorage: CoolLocalStorage,
    private modalService: MDBModalService,
    private route: ActivatedRoute,
    private TitleCase: TitleCasePipe,
    private _notificationService: NotificationsService,
    private _sharedDataService: SharedDataService,
    private cd: ChangeDetectorRef,
    private compoundFilterPipe: CompoundFilterPipe,
    private singleSortPipe: SingleSortPipe
  ) { }

  @HostListener("input", ["$event.target"]) oninput(target) {
    if (target.id === "groupSearch") this.searchItems();
  }

  /**
   * Our viewChild uses a setter that detects when the child content is available
   */
  @ViewChild(MdbTableDirective, { static: false }) set Content(
    content: MdbTableDirective
  ) {
    if (content) {
      this.mdbTableData = content;
      this.mdbTableData.setDataSource(this.groups);
    }
  }

  @ViewChild("groupTablePagination")
  mdbTablePagination: MdbTablePaginationComponent;

  ngOnInit() {

    this.triggerFilterUpdate
      .pipe(debounceTime(350))
      .subscribe(() => this.updateFilteredUserGroups(true));

    this.retrieveTeamID();
    this.retrieveUser();
    this.retrieveToken();
    this.configureFilterElements();
    this.configureHeadElements();
    this.updateFilteredUserGroups();
  }

  ngOnChanges() {
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private configureHeadElements() {
    this.headElements = [
      "action",
      `${this.TitleCase.transform(this.labels.userGroup.singular)} Name`,
      `# of ${this.TitleCase.transform(this.labels.userGroupMember.plural)}`,
      `# of ${this.TitleCase.transform(
        this.labels.event.singular
      )}s Attached To`,
    ];
  }

  private configureFilterElements() {
    this.filter_elements = [
      {
        filter: false,
        type: "close_filters",
        target: "actions",
      },
      {
        filter: true,
        type: "text",
        target: "name",
      },
      {
        filter: true,
        type: "number",
        target: "number_of_members",
      },
      {
        filter: true,
        type: "number",
        target: "number_of_events",
      },
    ];

    this.filterText = {
      name: "",
      number_of_members: "",
      number_of_events: "",
    };
  }

  updateFilteredUserGroups(resetPage?: boolean) {

    if (resetPage === undefined) resetPage = false;

    this.groups = this.compoundFilterPipe.transform(this.allUserGroups, {
      name: {
        text: this.filterText.name,
        type: "text",
      },
      number_of_members: {
        text: this.filterText.number_of_members,
        type: "number",
      },
      number_of_events: {
        text: this.filterText.number_of_events,
        type: "number",
      },
    });

    console.log("groups after filtering", this.groups);

    this.groups = this.singleSortPipe.transform(this.groups, this.sort.direction, this.sort.head, this.sort.type);

    this.userGroupsLength = this.groups.length;

    if (resetPage) {
      this.page = 1;
      this.pagination.changePage(0);
    }

    this.groups = this.groups.slice((this.page - 1) * this.rows, (this.page - 1) * this.rows + this.rows);

    console.log("groups after filtering and sorting", this.groups);
  }

  onPageChange(event) {
    this.page = event.page + 1;
    this.updateFilteredUserGroups();

  }

  private nullStringCheck(incoming) {
    if (incoming === null) incoming = "";

    return incoming;
  }

  /**
   * Reset specific filter ngModel filterText[filter_elements[i].target]
   * @param index
   */
  public resetFilter(index, value?) {
    if (value === undefined) value = "";
    this.filterText[this.filter_elements[index].target] = value;
    this.updateFilteredUserGroups();
  }

  /**
   * Collapse API
   */
  public collapsing() {
    if (this.collapsedLoaded) this.groupFiltersClass = "collapsing";
    this.collapsedLoaded = true;
  }

  public collapsed(showing) {
    if (showing) {
      this.groupFiltersClass = "collapse show";
    } else {
      this.groupFiltersClass = "collapse";
    }
  }

  /**
   * Custom table sorting based on current head key
   * @param head
   * @param direction
   */
  public sortTable(head, direction, type) {
    this.sortBy = head;
    this.sortOrder = direction;

    this.sort.head = head;
    this.sort.direction = direction;
    this.sort.type = type;

    this.updateFilteredUserGroups();
  }

  private searchItems() {
    const prev = this.mdbTableData.getDataSource();

    if (!this.groupSearchText) {
      this.mdbTableData.setDataSource(this.previous);
      this.tableGroups = this.mdbTableData.getDataSource();
      this.groups = this.tableGroups;
    }
    if (this.groupSearchText) {
      this.tableGroups = this.mdbTableData.searchLocalDataByMultipleFields(
        this.groupSearchText,
        ["name"]
      );
      this.mdbTableData.setDataSource(prev);
      this.groups = this.tableGroups;
    }
  }

  public showUsersTable(groupUsersTable, index) {
    this.groups[index].membersLoaded = true;
    groupUsersTable.toggle();
  }

  public openManageGroupModal(targetGroup?, action?) {
    this.modalOptions.data = {
      teamID: this.teamID,
      targetGroup: targetGroup === undefined ? null : targetGroup,
      action: action === undefined ? "add" : action,
      labels: this.labels,
      from: "user-groups"
    };

    this.modalOptions.containerClass = "";
    this.modalOptions.class =
      this.modalOptions.class + " modal-full-height modal-right";
    this.modalOptions.containerClass = "group-roster-container";

    //for right now we're using the legacy modal to handle deletion
    //@todo: refactor to use the new modal
    if (action === "delete") {
      this.modalOptions.class = "";
      this.modalOptions.containerClass = "delete-group-container";

      this.groupFrame = this.modalService.show(
        ManageUserGroupComponent,
        this.modalOptions
      );
    } else {

      this.groupFrame = this.modalService.show(
        ManageUserGroupRosterComponent,
        this.modalOptions
      );
    }

    this.groupFrame.content.outgoing.subscribe((result: any) => {

      let actionAdj = result.action.replace(/e$/, "");
      let toastMsg = `${targetGroup.name} has been ${actionAdj}ed`;

      this._notificationService.successNotification(toastMsg);

      if (result.action === "add") {
        this.groups.push(result.group);
        this.groups = this.groups.filter((group) => {
          return group.id !== null;
        });
      } else if (result.action === "update") {
        this.groups.forEach((group, index) => {
          if (group.id === result.group.id) {
            this.groups[index].name = result.group.name;
            this.groups[index].description = result.group.description;
            this.groups[index].members = result.group.members;
            this.groups[index].number_of_members =
              result.group.number_of_members;
            this.groups[index].number_of_events =
              result.group.number_of_events;
          }
        });
      } else if (result.action === "delete") {
        //remove group from groups via changedData.group.id
        this.groups = this.groups.filter((group) => {
          return group.id !== result.group.id;
        });
      }
    });
  }

  public getUserData(users, user_id) {
    return users.filter((user) => {
      return user.id === user_id;
    });
  }
}
