import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { CoolLocalStorage } from "@angular-cool/storage";
import { XrPlatformRestService } from "./../../../services/rest/xr-platform/xr-platform-rest.service";
import {
  MDBModalRef,
  MDBModalService,
} from "ng-uikit-pro-standard";
import { EditTeamLogoComponent } from "./modals/edit-team-logo/edit-team-logo.component";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { AddSettingModalComponent } from "./modals/add-setting-modal/add-setting-modal.component";
import { ViewPropModalComponent } from "../../universal/universal-settings/modals/view-prop-modal/view-prop-modal.component";
import { ArchivePropModalComponent } from "../../universal/universal-settings/modals/archive-prop-modal/archive-prop-modal.component";
import { AddTeamExperienceModalComponent } from "./modals/add-team-experience-modal/add-team-experience-modal.component";

@Component({
  selector: "app-site-settings",
  templateUrl: "./site-settings.component.html",
  styleUrls: ["./site-settings.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SiteSettingsComponent implements OnInit {
  public token: string;
  public user: any;
  public loading: boolean = true;
  public labelsLoading: boolean = true;
  public teamExperiencesLoading: boolean = true;
  public eventPropsLoading: boolean = true;
  public eventPropOptionsLoading: boolean = true;
  public clientMetaLoading: boolean = true;
  public teamID: number;
  public assetPrefix: string = "/assets/json";
  public client: any;
  public copy =
    "Manage the general settings for this client instance. Super admin only.";

  // search
  public searchText: string = "";
  public labelSearchText: string = "";
  public experiencesSearchText: string = "";
  public eventPropsSearchText: string = "";
  public contentSearchText: string = "";

  //status messages
  public status: string = "";
  public processingType: string = "";

  public clientSettings: {
    clientName: string;
  } = {
      clientName: "",
    };

  public errorMsg: string = "";
  public statusMsg: string = "";

  public teamSettings: any = [];
  public publicEvents: any = [];
  public teamCategories: any = [];
  public teamExperiences: any = [];
  public teamLabels: any = [];
  public eventProps: any = [];
  public eventPropOptions: any = [];
  public avatarOptions: { value: number; label: string }[] = [
    {
      value: 1,
      label: "Ready Player Me Avatars",
    },
    {
      value: 2,
      label: "Meta Avatars",
    },
  ];
  public avatarTypeDefault: number;

  public settingsVersionSelect: any = [];

  public modalFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private route: ActivatedRoute,
    private modalService: MDBModalService,
    private _settingsService: SettingsService,
  ) { }

  ngOnInit() {
    this.retrieveTeamID();
    this.retrieveUser();
    this.retrieveToken();

    this.settingsVersionSelect =
      this._settingsService.getSettingsVersionsSelect();

    this.retrieveData();
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private async retrieveData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let clientData = await this.retrieveClient(options).catch((error) => {
      //do error stuff
    });

    this.client = clientData;
    this.processClientSettings();

    let settingsByCategory = await this.retrieveClientSettingsByCategory(
      options
    ).catch((error) => {
      //do error stuff
    });
    this.teamCategories = this.formatSettingsByCategory(
      settingsByCategory.categories
    );

    //settings are now loaded
    this.loading = false;

    //retrieve team experiences
    let teamExperiences = await this.retrieveTeamExperiences(options).catch(
      (error) => {
        ;
      }
    );

    this.teamExperiences = teamExperiences.experiences;

    this.teamExperiencesLoading = false;

    //@todo: data retrieval should happen only on active tab
    let eventProps = await this.retrieveEventProps(options).catch((error) => {
      //do error stuff
    });
    this.eventProps = eventProps.props;
    //event properties are now loaded
    this.eventPropsLoading = false;

    let eventPropOptions = await this.retrieveEventPropOptions(options).catch(
      (error) => {
        //do error stuff
      }
    );
    this.eventPropOptions = eventPropOptions.options;
    //event property options are now loaded
    this.eventPropOptionsLoading = false;

    let clientLabels = await this.retrieveClientLabels(options).catch(
      (error) => {
        //do error stuff
      }
    );
    this.teamLabels = clientLabels.labels;
    //labels are now loaded
    this.labelsLoading = false;

  }

  private retrieveClient(options) {
    let retrieveClient = this._xrPlatformRestService.retrieveEntityData(
      "team",
      this.teamID,
      options
    );

    return retrieveClient.toPromise();
  }

  private processClientSettings() {
    this.clientSettings.clientName =
      this.client.name === null ? "Not Set" : this.client.name;

    this.avatarTypeDefault =
      this.client.meta !== null && this.client.meta.avatar_type !== undefined
        ? ++this.client.meta.avatar_type
        : null;

    this.clientMetaLoading = false;
  }

  private retrieveClientSettingsByCategory(options) {
    let teamSettings = this._xrPlatformRestService.restfulAPIQuery(
      "/super-admin/team/" + this.teamID + "/settings/categories",
      "get",
      {},
      options
    );

    return teamSettings.toPromise();
  }

  private retrieveTeamExperiences(options) {
    let getTeamExperiences = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/experiences/frontend_only",
      "get",
      {},
      options
    );

    return getTeamExperiences.toPromise();
  }

  private formatSettingsByCategory(categories) {
    let temp_categories = [];
    categories.forEach((category) => {
      let temp_settings = [];
      let settings = category.settings;
      settings.forEach((element) => {
        if (element.constrained == false) {
          if (
            element.type == "boolean" &&
            element.unconstrained_default == "false"
          ) {
            element.unconstrained_default = false;
          } else if (
            element.type == "boolean" &&
            element.unconstrained_default == "true"
          ) {
            element.unconstrained_default = true;
          }
          temp_settings.push(element);
        } else {
          let temp_setting = element;
          if (element.type == "select_list") {
            element.options.forEach((option) => {
              if (option.is_default == true) {
                temp_setting.default_value = option.id;
                temp_settings.push(temp_setting);
              }
            });
          } else if (element.type == "multi_select_list") {
            let defaults = [];
            element.options.forEach((option) => {
              if (option.is_default == true) {
                defaults.push(option.id);
              }
            });

            element.default_value = defaults;
            temp_settings.push(element);
          } else {
            temp_setting.push(element);
          }
        }
      });
      category.settings = temp_settings;
      temp_categories.push(category);
    });

    return temp_categories;
  }

  private retrieveClientLabels(options) {
    let teamLabels = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/labels",
      "get",
      {},
      options
    );

    return teamLabels.toPromise();
  }

  private retrieveEventProps(options) {
    let eventProps = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/schedule/props",
      "get",
      {},
      options
    );

    return eventProps.toPromise();
  }

  private retrieveEventPropOptions(options) {
    let propOptions = this._xrPlatformRestService.restfulAPIQuery(
      "/schedule/props/options",
      "get",
      {},
      options
    );

    return propOptions.toPromise();
  }

  public updateTeamSettings(setting, value) {
    ;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let data = {
      setting_id: setting.id,
      team_id: this.teamID,
      option_id: null,
      value: null,
    };

    if (setting.type == "boolean") {
      data.value = value.checked.toString();
    } else if (setting.type == "string" || setting.type == "email") {
      data.value = value;
    } else if (setting.type == "number") {
      ;
      data.value = value;
      data.setting_id = setting.setting_id;
    } else if (setting.type == "select_list") {
      data.option_id = value;
      data.value = "True";
    } else if (setting.type == "multi_select_list") {
      data.option_id = value;
      setting.options.forEach((element) => {
        if (element.id == value) {
          ;
          if (element.is_default == true) {
            data.value = "False";
          } else {
            data.value = "True";
          }
        }
      });
    } else if (setting.type == "asset_id") {
      data.value = value;
    } else if (setting.type == "event_id") {
      data.value = value;
    }

    let addTeamSetting = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/setting/" + setting.id,
      "post",
      data,
      options
    );

    ;

    addTeamSetting.subscribe(
      (response) => {
        ;
        this._settingsService.updateSetting(
          response.team_settings,
          this.teamID
        );
        if (
          setting.name == "clientLogo" ||
          setting.type === "event_id"
        ) {
          ;
          let catIndex = 0;
          this.teamCategories[catIndex];
          this.teamCategories.forEach((category) => {
            let settingIndex = 0;
            this.teamCategories[catIndex][settingIndex];
            category.settings.forEach((element) => {
              if (element.name == setting.name) {
                this.teamCategories[catIndex].settings[settingIndex] =
                  response.team_settings;
              }
              settingIndex += 1;
            });
            catIndex += 1;
          });
        }
      },
      (error) => {
        ;
      }
    );
  }

  public updateTeamLabel(label, value, type) {
    ;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let data = {
      singular: null,
      plural: null,
    };

    if (type == "singular") {
      data.singular = value;
      data.plural = label.plural;
    } else {
      data.singular = label.singular;
      data.plural = value;
    }

    let addTeamLabel = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/label/" + label.id + "/add",
      "post",
      data,
      options
    );

    addTeamLabel.subscribe(
      (response) => {
        this._settingsService.updateLabel(response.team_label);
        this.teamLabels = this.teamLabels.map((label) => {
          if (label.id === response.team_label.id) {
            return response.team_label;
          } else {
            return label;
          }
        });
      },
      (error) => {
        console.log("error retrieveing labels", error);
      }
    );
  }

  public openChangeLogoModal(setting) {
    this.modalOptions.data = {
      team_id: this.teamID,
      type: "image",
      targetAsset: setting.asset !== undefined && setting.asset !== null ? setting.asset : null,
      fromLocation: "teamSettings",
    };

    this.modalOptions.class =
      this.modalOptions.class + " modal-full-height modal-right";
    this.modalOptions.containerClass = "event-media-container";
    this.modalFrame = this.modalService.show(
      EditTeamLogoComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {

      console.log("changedData from EditTeamLogoComponent", changedData);

      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            this.updateTeamSettings(setting, changedData.media.id);
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openAddTeamExperienceModal() {
    this.modalOptions.data = {
      teamID: this.teamID,
      teamExperiences: this.teamExperiences,
    };

    this.modalOptions.class = "modal-dialog";
    this.modalFrame = this.modalService.show(
      AddTeamExperienceModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.teamExperiences.push(changedData.experience);
            break;
          case "update":
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openAddEventPropsModal() {
    this.modalOptions.data = {
      teamID: this.teamID,
      teamProps: this.eventProps,
    };

    this.modalOptions.class = "modal-dialog";
    this.modalFrame = this.modalService.show(
      AddSettingModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.eventProps.push(changedData.prop);
            break;
          case "update":
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openViewEventPropsModal(prop) {
    this.modalOptions.data = {
      team_id: this.teamID,
      prop: prop,
    };

    ;

    this.modalOptions.class = "modal-dialog";
    this.modalFrame = this.modalService.show(
      ViewPropModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            this.eventProps.push(changedData.prop);
            break;
          case "update":
            break;
          case "delete":
            break;
        }
      } else {
      }
    });
  }

  public openArchivePropsModal(prop, index, type) {
    this.modalOptions.data = {
      teamID: this.teamID,
      prop: prop,
      type: type,
    };

    this.modalOptions.class = "modal-dialog";
    this.modalFrame = this.modalService.show(
      ArchivePropModalComponent,
      this.modalOptions
    );

    this.modalFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.action !== undefined) {
        switch (changedData.action) {
          case "add":
            break;
          case "update":
            break;
          case "delete":
            if (type === "Team Event Property") {
              this.eventProps.splice(index, 1);
            } else if (type === "Team Experience") {
              this.teamExperiences.splice(index, 1);
            }
            break;
        }
      } else {
      }
    });
  }

  /**
   * Temporary workaround for custom lables
   * Right now just focuses on the admin caps until we have an internal system for adjusting admin cap labelling
   * @param labelIn
   * @param setting
   * @param option
   * @returns
   */
  public customMultiSelectLabels(labelIn, setting, option) {
    let labelOut = labelIn;

    if (setting.name === "adminCaps") {
      switch (parseInt(option.value)) {
        case 3:
          labelOut = "Moderator";
          break;
      }
    }

    return labelOut;
  }

  public updateAvatarDefault(incoming) {
    let avatarType = parseInt(incoming);
    this.status = "processing";
    this.processingType = "avatarType";

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let data = {
      avatar_type: --avatarType,
    };

    let updateAvatarType = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/update-meta",
      "put",
      data,
      options
    );

    updateAvatarType.subscribe(
      (response) => {
        this.status = "saved";
        setTimeout(() => {
          this.status = "";
          this.processingType = "";
        }, 1500);
      },
      (error) => {
        ;
      }
    );
  }
}
