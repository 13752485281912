import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, fromEvent, merge, timer } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivityTrackingService {
  private readonly ACTIVITY_EVENTS = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart', 'click'];
  private readonly INACTIVITY_THRESHOLD = 3600 * 1000; // 1 hour in milliseconds

  private lastActivityTimestamp = Date.now();
  private tabFocused = true;
  private inactivitySubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.initActivityTracking();
    this.initTabVisibilityTracking();
  }

  private initActivityTracking(): void {
    // Combine all activity events into a single stream
    const activityEvents$ = merge(
      ...this.ACTIVITY_EVENTS.map(event => fromEvent(document, event))
    ).pipe(
      debounceTime(300) // Prevent excessive updates
    );

    // Update last activity timestamp on any user activity
    activityEvents$.subscribe(() => {
      if (this.tabFocused) {
        this.lastActivityTimestamp = Date.now();
        this.inactivitySubject.next(false);
      }
    });

    // Check for inactivity every minute
    timer(60000, 60000).subscribe(() => {
      const inactiveTime = Date.now() - this.lastActivityTimestamp;
      if (inactiveTime >= this.INACTIVITY_THRESHOLD) {
        this.inactivitySubject.next(true);
      }
    });
  }

  private initTabVisibilityTracking(): void {
    // Track when the tab becomes visible/hidden
    fromEvent(document, 'visibilitychange').subscribe(() => {
      this.tabFocused = document.visibilityState === 'visible';

      // When tab becomes visible again, check how long user was inactive
      if (this.tabFocused) {
        const inactiveTime = Date.now() - this.lastActivityTimestamp;
        if (inactiveTime < this.INACTIVITY_THRESHOLD) {
          // User wasn't inactive for too long, update timestamp
          this.lastActivityTimestamp = Date.now();
        }
        // Otherwise leave the old timestamp to trigger inactivity detection
      }
    });
  }

  /**
   * Returns whether the user has been inactive beyond the threshold
   */
  public isUserInactive(): boolean {
    const inactiveTime = Date.now() - this.lastActivityTimestamp;
    return inactiveTime >= this.INACTIVITY_THRESHOLD;
  }

  /**
   * Returns how long the user has been inactive in milliseconds
   */
  public getInactiveTime(): number {
    return Date.now() - this.lastActivityTimestamp;
  }

  /**
   * Observable that fires when user inactivity status changes
   */
  public onInactivityChange(): Observable<boolean> {
    return this.inactivitySubject.asObservable();
  }

  /**
   * Reset the inactivity timer
   */
  public resetInactivityTimer(): void {
    this.lastActivityTimestamp = Date.now();
    this.inactivitySubject.next(false);
  }
}
