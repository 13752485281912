<div class="welcome-page page">
  <div class="account-summary-container">
    <div class="row mb-4 d-flex">
      <div
        [ngClass]="{
          'col-8': user.role_type_id === 1 || user.role_type_id === 2,
          'col-12': user.role_type_id !== 1 && user.role_type_id !== 2
        }"
      >
        <mdb-card class="content-card engagement-card p-0 m-0">
          <div class="col-12 mb-1 mt-3">
            <p>Client Details</p>
            <hr />
          </div>
          <div class="d-flex justify-content-center" *ngIf="teamLoading">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="d-flex justify-content-left" *ngIf="!teamLoading">
            <div class="col-6">
              <p class="bold mb-1">Client Name</p>
              <div class="d-flex">
                <div>
                  <p>
                    {{ team.name }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6">
              <p class="bold mb-1">Client Code</p>
              <div class="d-flex">
                <div>
                  <p>
                    {{ team.code }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-around" *ngIf="!teamLoading">
            <div class="col-6 mb-2">
              <p class="bold mb-1">Engagement Window</p>
              <div class="d-flex">
                <div class="mr-3">
                  <p *ngIf="team.engagements[0]">
                    {{
                      team.engagements[0].start_date
                        | amDateFormat : "ddd, MMM D YYYY h:mm a"
                    }}
                    -
                    {{
                      team.engagements[0].end_date
                        | amDateFormat : "ddd, MMM D YYYY h:mm a"
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 mb-2">
              <p class="bold mb-1">Engagement Description</p>
              <p *ngIf="team.engagements[0]">
                {{ team.engagements[0].description }}
              </p>
            </div>
          </div>
        </mdb-card>
      </div>
      <div
        *ngIf="user.role_type_id === 1 || user.role_type_id === 2"
        class="col-4"
      >
        <mdb-card class="h-100">
          <div class="row">
            <div class="col-12 mt-3 at-a-glance-container">
              <p>At a Glance</p>
              <hr />
              <div class="d-flex justify-content-center" *ngIf="metricsLoading">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div
                class="metric-item d-flex align-items-baseline"
                *ngIf="
                  user.role_type_id === 1 ||
                  (user.role_type_id === 2 && !metricsLoading)
                "
              >
                <mdb-badge class="theme-base-dark" pill="true">
                  {{ metrics.events }}</mdb-badge
                >
                <p class="ml-2">
                  {{ this.labels.event.plural | titlecase }} Scheduled
                </p>
              </div>
              <div
                class="metric-item d-flex align-items-baseline"
                *ngIf="
                  user.role_type_id === 1 ||
                  (user.role_type_id === 2 && !metricsLoading)
                "
              >
                <mdb-badge class="theme-base-dark" pill="true">
                  {{ metrics.users }}</mdb-badge
                >
                <p class="ml-2">
                  {{ this.labels.user.singular | titlecase }} Accounts
                </p>
              </div>
              <div
                class="metric-item d-flex align-items-baseline"
                *ngIf="
                  user.role_type_id === 1 ||
                  (user.role_type_id === 2 && !metricsLoading)
                "
              >
                <mdb-badge class="theme-base-dark" pill="true">
                  {{ metrics.assets }}</mdb-badge
                >
                <p class="ml-2">
                  {{ this.labels.media.plural | titlecase }} Assets
                </p>
              </div>
            </div>
          </div>
        </mdb-card>
      </div>
    </div>
  </div>
  <!-- information modal -->
  <div
    mdbModal
    #infoModal="mdbModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myBasicModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100">About Unified Admin Panel</h4>
          <button type="button" class="close" (click)="infoModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p [innerHTML]="copy"></p>
        </div>
        <div class="modal-footer">
          <button
            mdbBtn
            mdbWavesEffect
            type="button"
            size="md"
            color="link"
            (click)="infoModal.hide()"
            id="close-modal-btn"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="interactive-analytics-card"
  *ngIf="user.role_type_id === 1 || user.role_type_id === 2"
>
  <div class="row justify-content-start flex-row metrics-controls">
    <div
      class="col-9 offset-3 d-flex flex-nowrap justify-content-end align-items-center"
    >
      <div class="md-form start-picker">
        <mdb-date-picker
          [inline]="true"
          name="start"
          [options]="myDatePickerOptions"
          [placeholder]="'Selected ' + labels.startTime.singular"
          [(ngModel)]="model.start"
          id="start-date"
          onkeydown="return false"
          [label]="startDateLabel"
          (dateChanged)="checkDateRange($event, 'start')"
        ></mdb-date-picker>
        <!-- <label [class.active]="start" for="start">Start Date</label> -->
      </div>
      <div class="md-form end-picker">
        <mdb-date-picker
          [inline]="true"
          name="end"
          [options]="endDateoickerOptions"
          [placeholder]="'Selected ' + labels.endTime.singular"
          [(ngModel)]="model.end"
          id="end-date"
          onkeydown="return false"
          [label]="endDateLable"
          (dateChanged)="checkDateRange($event, 'end')"
        ></mdb-date-picker>
      </div>
      <div class="md-form denomination">
        <mdb-select
          name="time-denomination"
          [options]="timeDenomination"
          [formControl]="timeSelector"
        >
        </mdb-select>
        <label for="time-denomination">Time Interval</label>
      </div>
      <button
        class="theme-modal-button btn update-btn"
        data-dismiss="modal"
        type="button"
        mdbBtn
        mdbWavesEffect
        (click)="setupDateChart()"
      >
        Update Chart
      </button>
    </div>
  </div>
  <div class="row d-flex flex-nowrap">
    <div
      class="col-3 d-flex justify-content-center flex-row metrics-aggregates"
    >
      <div class="aggregate-container w-100">
        <h3 class="text-center">Aggregate Values</h3>
        <ng-container *ngIf="chartLoading">
          <div
            class="chart-container flex-wrap justify-content-center d-flex align-content-center"
          >
            <div
              class="chart-container flex-wrap justify-content-center d-flex align-content-center"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </ng-container>
        <table *ngIf="!chartLoading" mdbTable striped="true">
          <tbody>
            <tr *ngFor="let aggregate of dateAggregates">
              <td class="aggregate-value semi-bold">{{ aggregate.label }}</td>
              <td class="aggregate-label">{{ aggregate.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-9">
      <h3 class="text-center">Meeting Sessions Over Time</h3>
      <ng-container *ngIf="chartLoading">
        <div
          class="chart-container flex-wrap justify-content-center d-flex align-content-center"
        >
          <div
            class="flex-wrap justify-content-center d-flex align-content-center"
          >
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </ng-container>
      <div
        *ngIf="!chartLoading"
        class="chart-container"
        style="position: relative; height: 42vh; width: auto"
      >
        <canvas #sessionsChart> </canvas>
      </div>
    </div>
  </div>
</div>
