<div class="interactive-analytics-card aia-analytics">
  <div
    *ngIf="!gridLoading"
    class="d-flex justify-content-between align-items-center w-100 metrics-controls analytics-info"
  >
    <p class="mb-0">{{ eventInfo }} for {{ userInfo }}</p>
    <p class="mb-0">{{ startDate }} - {{ endDate }}</p>
  </div>
  <div
    *ngIf="!gridLoading"
    class="d-flex justify-content-between align-items-center w-100 metrics-controls"
  >
    <div class="back-button">
      <a
        *ngIf="!gridLoading"
        mdbBtn
        class="btn-flat p-0 m-0 back-button"
        mdbWavesEffect
        [routerLink]="backRoute"
        mdbTooltip="Back to AI Dashboard"
        placement="bottom"
        [queryParams]="queryParams"
      >
        <mdb-icon fas icon="chevron-left"></mdb-icon>
        Back
      </a>
    </div>
    <div
      class="right-side"
      style="display: flex; align-items: center; justify-content: center"
    >
      <div class="download-csv">
        <button
          style="width: 11rem; margin-right: 1rem; margin-top: 0.4rem"
          class="theme-modal-button btn update-btn"
          data-dismiss="modal"
          type="button"
          mdbBtn
          mdbWavesEffect
          (click)="onDownloadCSV()"
        >
          Download CSV
        </button>
      </div>
      <div class="aia-model-selection">
        <div class="md-form md-filter-input md-filter-select mb-0 mt-0">
          <mdb-select-2
            class="custom-arrow"
            id="aiaModelSelect"
            [(ngModel)]="aiModel"
          >
            <mdb-select-option
              *ngFor="let option of aiModelOptions"
              [value]="option.value"
              >{{ option.label }}</mdb-select-option
            >
          </mdb-select-2>
        </div>
      </div>
    </div>
  </div>
  <div class="readquest-analytics-highlevel analytics-card">
    <ng-container *ngIf="gridLoading">
      <div class="row">
        <div class="col-12">
          <!-- if content hasnt load -->
          <div
            class="loading-card d-flex align-content-center justify-content-center"
          >
            <div
              class="d-flex justify-content-center"
              *ngIf="errorRetrievingMsg === ''"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              class="d-flex justify-content-center"
              *ngIf="errorRetrievingMsg !== ''"
            >
              <div
                class="alert alert-danger"
                role="alert"
                [innerHTML]="errorRetrievingMsg"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!gridLoading">
      <ag-grid-angular
        style="width: 100%; height: 100%"
        [columnDefs]="columnDefs"
        [autoSizeStrategy]="autoSizeStrategy"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData"
        [class]="themeClass"
        (gridReady)="onGridReady($event)"
      ></ag-grid-angular>
    </ng-container>
  </div>
</div>
