import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import videojs from "video.js";
import Player from "video.js/dist/types/player";

import {
  generateVideoThumbnails,
} from "@rajesh896/video-thumbnails-generator";

@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild("target", { static: true }) target: ElementRef;
  @Output("videoLoaded") videoLoaded = new EventEmitter();
  @Output("thumbnailsGenerated") thumbnailsGenerated = new EventEmitter<string[]>();

  thumbnailsLoading = true;

  // See options: https://videojs.com/guides/options
  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    sources: {
      src: string;
      type: string;
    }[];
  };

  player: Player;

  constructor(private elementRef: ElementRef) { }

  // Instantiate a Video.js player OnInit
  ngOnInit() {

    let self = this;

    this.player = videojs(
      this.target.nativeElement,
      this.options,
      function onPlayerReady() {
        console.log("onPlayerReady", self.options);
        self.videoLoaded.emit(this);

        const player = this;
        const videoUrl = self.options.sources[0].src;

        // Show loading state while getting thumbnails
        self.thumbnailsLoading = true;

        // Option 1: Fetch the video file first
        fetch(videoUrl)
          .then(response => response.blob())
          .then(videoBlob => {
            // Create a File object from the blob
            const videoFile = new File(
              [videoBlob],
              'video.mp4',
              { type: 'video/mp4' }
            );

            // Generate thumbnails from the file object
            return generateVideoThumbnails(videoFile, 4, "file");
          })
          .then(thumbnailArray => {
            console.log("thumbnailArray", thumbnailArray);
            self.thumbnailsLoading = false;

            if (thumbnailArray && thumbnailArray.length > 1) {
              const thumbnailToUse = thumbnailArray[2];
              player.poster(thumbnailToUse);
              self.thumbnailsGenerated.emit(thumbnailArray);
            }
          })
          .catch(error => {
            self.thumbnailsLoading = false;
            console.error("Error generating thumbnails", error);
          });

      }
    );
  }

  // Dispose the player OnDestroy
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
