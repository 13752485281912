<mdb-side-nav #sidenav [fixed]="true" class="fixed theme-base-dark">
  <!-- logo -->
  <li class="logo-sn d-block">
    <div class="logo-wrapper">
      <a
        [routerLink]="['/client', clientCode]"
        mdbWavesEffect
        class="waves-light w-100"
      >
        <img [src]="logoSrc" class="img-fluid flex-center" />
      </a>
    </div>
    <p *ngIf="!superAdminLevel" class="client-code">
      Client Code: {{ clientCode
      }}<button color="link" class="btn btn-link" (click)="copyAccessCode()">
        <span class="menu-icon"><fa-icon [icon]="faCopy"></fa-icon></span>
      </button>
    </p>
  </li>
  <!-- /logo -->
  <!-- menu items -->
  <!--Logged Out-->
  <links *ngIf="!token">
    <li>
      <ul class="collapsible collapsible-accordion mt-0">
        <mdb-accordion [multiple]="false" aria-multiselectable="false">
          <div *ngIf="user_role_id === 1">
            <mdb-accordion-item>
              <mdb-accordion-item-head
                mdbWavesEffect
                id="super-admin-accordion"
              >
                <span class="icon-wrapper">
                  <span class="menu-icon"
                    ><fa-icon [icon]="faUserTie"></fa-icon></span
                  >Super Admin
                </span>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <ul>
                  <li routerLinkActive="active">
                    <a
                      [routerLink]="['/universal-settings']"
                      mdbWavesEffect
                      id="super-admin-universal-settings"
                      class="icon-wrapper"
                    >
                      <span class="menu-icon"
                        ><fa-icon [icon]="faScrewdriverWrench"></fa-icon></span
                      >Universal Settings
                    </a>
                  </li>
                  <li routerLinkActive="active">
                    <a
                      [routerLink]="['/version-support']"
                      mdbWavesEffect
                      id="super-admin-version-support"
                      class="icon-wrapper"
                    >
                      <span class="menu-icon"
                        ><fa-icon [icon]="faTableList"></fa-icon></span
                      >Version Support
                    </a>
                  </li>
                  <li routerLinkActive="active">
                    <a
                      [routerLink]="['/client-list']"
                      mdbWavesEffect
                      id="client-list"
                      class="icon-wrapper"
                    >
                      <span class="menu-icon"
                        ><fa-icon [icon]="faTableList"></fa-icon></span
                      >Client List
                    </a>
                  </li>
                </ul>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
          </div>
        </mdb-accordion>
      </ul>
    </li>
  </links>

  <!--Logged In-->
  <links *ngIf="token">
    <li>
      <ul class="collapsible collapsible-accordion mt-0">
        <mdb-accordion [multiple]="false" aria-multiselectable="false">
          <div *ngIf="user_role_id === 1">
            <mdb-accordion-item>
              <mdb-accordion-item-head mdbWavesEffect id="admin-accordion">
                <span class="icon-wrapper">
                  <span class="menu-icon"
                    ><fa-icon [icon]="faUserTie"></fa-icon></span
                  >Super Admin
                </span>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <ul>
                  <li>
                    <a
                      [routerLink]="['/client', clientCode, 'settings']"
                      [routerLinkActive]="['active']"
                      mdbWavesEffect
                      id="client-settings"
                      class="icon-wrapper"
                    >
                      <span class="menu-icon"
                        ><fa-icon [icon]="faScrewdriverWrench"></fa-icon></span
                      >Settings
                    </a>
                  </li>
                  <li
                    routerLinkActive="active"
                    *ngIf="menuOptions.metricsExtras.readquest"
                  >
                    <a
                      [routerLink]="['/client', clientCode, 'authoring']"
                      mdbWavesEffect
                      id="super-admin-version-support"
                      class="icon-wrapper"
                    >
                      <span class="menu-icon"
                        ><fa-icon [icon]="faTableList"></fa-icon></span
                      >Authoring Tools
                    </a>
                  </li>
                  <li
                    routerLinkActive="active"
                    *ngIf="
                      menuOptions.metricsExtras.readquest &&
                      includeSpecialComponent
                    "
                  >
                    <a
                      [routerLink]="[
                        '/client',
                        clientCode,
                        'readquest-analytics-highlevel'
                      ]"
                      mdbWavesEffect
                      [routerLinkActive]="['active']"
                      id="readquest-analytics-highlevel"
                      class="icon-wrapper"
                    >
                      <span class="menu-icon"
                        ><fa-icon [icon]="faChartColumn"></fa-icon></span
                      >ReadQuest Analytics HL
                    </a>
                  </li>
                  <li routerLinkActive="active">
                    <a
                      [routerLink]="['/client-list']"
                      mdbWavesEffect
                      id="client-list"
                      class="icon-wrapper"
                    >
                      <span class="menu-icon"
                        ><fa-icon [icon]="faTableList"></fa-icon></span
                      >Client List
                    </a>
                  </li>
                </ul>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
          </div>
          <li
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="nav-item"
          >
            <a
              [routerLink]="['/client', clientCode]"
              mdbWavesEffect
              class="waves-light w-100"
              id="dashboard"
              class="icon-wrapper"
            >
              <span class="menu-icon"
                ><fa-icon [icon]="faTableColumns"></fa-icon></span
              >Home
            </a>
          </li>
          <li
            *ngIf="isAdmin"
            class="nav-item"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              [routerLink]="['/client', clientCode, 'users']"
              mdbWavesEffect
              class="waves-light w-100 height-40p"
              id="menu-users"
              class="icon-wrapper"
            >
              <span class="menu-icon"><fa-icon [icon]="faUsers"></fa-icon></span
              >{{ labels.user.plural | titlecase }}
            </a>
          </li>
          <li
            *ngIf="menuOptions.experiences && isAdmin"
            class="nav-item"
            routerLinkActive="active"
          >
            <a
              [routerLink]="['/client', clientCode, 'experiences']"
              mdbWavesEffect
              id="admin-experiences"
              class="icon-wrapper"
            >
              <span class="menu-icon"
                ><fa-icon [icon]="faVrCardboard"></fa-icon></span
              >{{ labels.experience.plural | titlecase }}
            </a>
          </li>
          <li
            *ngIf="(isAdmin || isModerator) && menuOptions.myEvents"
            class="nav-item"
            routerLinkActive="active"
          >
            <a
              [routerLink]="['/client', clientCode, 'meetings']"
              class="waves-light w-100"
              id="admin-all-events"
              class="icon-wrapper"
            >
              <span class="menu-icon"
                ><fa-icon [icon]="faTableList"></fa-icon></span
              >{{ labels.event.plural | titlecase }}
            </a>
          </li>
          <li
            *ngIf="teamID !== 9"
            class="nav-item"
            [routerLinkActive]="['active']"
          >
            <a
              [routerLink]="[
                '/client',
                clientCode,
                'media-library',
                'all-media'
              ]"
              [routerLinkActive]="['active']"
              mdbWavesEffect
              id="client-media-library"
              class="icon-wrapper"
              ><span class="menu-icon"
                ><fa-icon [icon]="faPhotoFilm"></fa-icon></span
              >{{ labels.media.plural | titlecase }} Library
            </a>
          </li>
          <li
            *ngIf="
              isAdmin &&
              menuOptions.defaultMetrics &&
              !menuOptions.hasMetricsExtras
            "
            [routerLinkActive]="['active']"
          >
            <a
              [routerLink]="['/client', clientCode, 'analytics']"
              mdbWavesEffect
              class="waves-light w-100"
              id="metrics-option"
              class="icon-wrapper"
            >
              <span class="menu-icon"
                ><fa-icon [icon]="faChartColumn"></fa-icon></span
              >Analytics
            </a>
          </li>
          <div
            *ngIf="
              (isAdmin &&
                menuOptions.defaultMetrics &&
                menuOptions.hasMetricsExtras) ||
              menuOptions.metaDisplay.length ||
              menuOptions.metricsExtras.aia
            "
          >
            <mdb-accordion-item [collapsed]="!isAnalyticsActive">
              <mdb-accordion-item-head mdbWavesEffect id="menu-users-accordion">
                <span class="icon-wrapper">
                  <span class="menu-icon"
                    ><fa-icon [icon]="faChartColumn"></fa-icon></span
                  >Analytics
                </span>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <ul>
                  <li *ngIf="isAdmin" [class.active]="isSessionsDashboardActive">
                    <a
                      [routerLink]="['/client', clientCode, 'analytics']"
                      [class.active]="isSessionsDashboardActive"
                      mdbWavesEffect
                      id="menu-users-option"
                      class="icon-wrapper"
                    >
                      <span class="menu-icon"
                        ><fa-icon [icon]="faChartColumn"></fa-icon></span
                      >Meeting Sessions
                    </a>
                  </li>
                  <li
                    *ngIf="
                      clientSettings.showReadquestAnalytics
                        .unconstrained_default && isAdmin
                    "
                    [class.active]="isReadquestUsersDashboardActive"
                  >
                    <a
                      [routerLink]="[
                        '/client',
                        clientCode,
                        'analytics',
                        'readquest',
                        'users'
                      ]"
                      [class.active]="isReadquestUsersDashboardActive"
                      mdbWavesEffect
                      id="menu-users-option"
                      class="icon-wrapper"
                    >
                      <span class="menu-icon"
                        ><fa-icon [icon]="faChartColumn"></fa-icon></span
                      >RQ User Analytics
                    </a>
                  </li>
                  <li
                    *ngIf="
                      clientSettings.showReadquestAnalytics
                        .unconstrained_default
                    "
                    [class.active]="isReadquestSkillDashboardActive"
                  >
                    <a
                      [routerLink]="[
                        '/client',
                        clientCode,
                        'analytics',
                        'readquest',
                        'skills'
                      ]"
                      [class.active]="isReadquestSkillDashboardActive"
                      mdbWavesEffect
                      id="menu-users-option"
                      class="icon-wrapper"
                    >
                      <span class="menu-icon"
                        ><fa-icon [icon]="faChartColumn"></fa-icon></span
                      >RQ Skill Analytics
                    </a>
                  </li>
                  <li
                    *ngIf="
                      clientSettings.showAIAAnalytics.unconstrained_default
                    "
                    [class.active]="isAIDashboardActive"
                  >
                    <a
                      [routerLink]="['/client', clientCode, 'analytics', 'aia']"
                      [class.active]="isAIDashboardActive"
                      mdbWavesEffect
                      id="menu-users-option"
                      class="icon-wrapper"
                    >
                      <span class="menu-icon"
                        ><fa-icon [icon]="faChartColumn"></fa-icon></span
                      >AI Sessions
                    </a>
                  </li>
                  <li
                    *ngIf="menuOptions.metaDisplay.length"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <a
                      *ngFor="let meta of menuOptions.metaDisplay"
                      [routerLink]="[
                        '/client',
                        clientCode,
                        'users',
                        'meta',
                        meta.name
                      ]"
                      mdbWavesEffect
                      id="inventory-session-results"
                      class="icon-wrapper"
                    >
                      <span class="menu-icon"
                        ><fa-icon [icon]="faTableList"></fa-icon></span
                      >Inventory Sessions
                    </a>
                  </li>
                </ul>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
          </div>
        </mdb-accordion>
      </ul>
    </li>
  </links>
  <!-- /menu items -->
  <app-footer [clientCode]="clientCode"></app-footer>
</mdb-side-nav>
