<div class="modal-content-wrapper media-viewer" (click)="modalClick($event)">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <span *ngIf="!mediaNameOnly"
        >{{ labels.media.singular | titlecase }} Details:
        {{ media_item.name }}</span
      >
      <span *ngIf="mediaNameOnly">{{ media_item.name }}</span>
      <ng-container *ngIf="secondaryTitle !== ''">
        <br /><span class="secondary-title">{{ secondaryTitle }}</span>
      </ng-container>
    </h4>
    <button
      *ngIf="isClean"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <button
      *ngIf="!isClean"
      type="button"
      class="close cancel-alert"
      #popOverTriggerTop="bs-mdbPopover"
      id="cancelWarningCloseX"
      triggers="click"
      mdbPopoverHeader=""
      [mdbPopover]="cancelWarning"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <div class="modal-body modal-fluid-content p-0">
    <div class="container-overflow-auto no-padding">
      <div class="media-container">
        <div class="media-preview">
          <div
            *ngIf="mediaLoading"
            class="loading-screen d-flex align-items-center h-100 w-100 justify-content-center"
          >
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <ng-container *ngIf="media_item.type === 'image'">
            <img
              class="img-fluid"
              [src]="media_item.cdn_uri"
              alt=""
              (load)="onAssetLoad($event)"
            />
          </ng-container>
          <ng-container *ngIf="media_item.type === 'text'">
            <iframe
              [src]="safeUrl"
              frameborder="0"
              height="100%"
              width="100%"
              (load)="onIframeLoad($event)"
            ></iframe>
          </ng-container>
          <app-video-player
            *ngIf="media_item.type === 'video'"
            [options]="videoPlayerOptions"
          ></app-video-player>
          <fa-icon
            class="media-preview-icon audio-preview"
            [icon]="faFileAudio"
            *ngIf="media_item.type === 'audio'"
          ></fa-icon>
          <app-audio-media-player
            *ngIf="media_item.type === 'audio'"
            [tracks]="tracks"
            (audioLoaded)="onAssetLoad($event)"
          ></app-audio-media-player>
          <app-pdf-viewer
            [pdfSrc]="pdfSrc"
            *ngIf="media_item.type === 'pdf'"
            (pdfLoaded)="onAssetLoad($event)"
          ></app-pdf-viewer>
          <div class="model-preview" *ngIf="media_item.type === '3d-object'">
            <app-wc-model-viewer
              *ngIf="modelLoaded"
              [modelSrc]="modelSrc"
              (generatedThumbnail)="onGeneratedThumbnail($event)"
              (modelLoadedEvent)="onAssetLoad($event)"
              [action]="'update'"
            ></app-wc-model-viewer>
          </div>
          <ng-container *ngIf="isGeneric">
            <img
              class="img-fluid"
              [src]="placeHolderImg"
              alt=""
              (load)="onAssetLoad($event)"
            />
          </ng-container>
        </div>
        <div *ngIf="!previewOnly" class="media-details">
          <p class="semi-bold">Details</p>
          <table mdbTable striped="true">
            <tbody>
              <tr mdbTableCol>
                <td>
                  <div class="content-plus">
                    <span class="semi-bold">Name:</span
                    ><app-text-inline-edit
                      [data]="media_item.name"
                      [name]="'name'"
                      [disabled]="
                        (isModerator || isParticipant) &&
                        media_item.current_owner !== user_id
                      "
                      (saveData)="
                        updateMediaDetails(
                          $event,
                          'name',
                          '.' + media_item.file_ext
                        )
                      "
                      [exclude]="'.' + media_item.file_ext"
                    ></app-text-inline-edit>
                  </div>
                </td>
                <td>
                  <span class="semi-bold">Owner:</span
                  >{{ media_item.owner_username }}
                </td>
              </tr>
              <tr mdbTableCol>
                <td>
                  <div class="content-plus">
                    <span class="semi-bold">Description:</span>
                    <app-text-inline-edit
                      [data]="media_item.description"
                      [name]="'description'"
                      [disabled]="
                        (isModerator || isParticipant) &&
                        media_item.current_owner !== user_id
                      "
                      (saveData)="updateMediaDetails($event, 'description', '')"
                    ></app-text-inline-edit>
                  </div>
                </td>
                <td>
                  <span class="semi-bold">Uploaded:</span
                  >{{ media_item.created | amDateFormat : "MMM D YYYY h:mm a" }}
                </td>
              </tr>
              <tr mdbTableCol>
                <td>
                  <span class="semi-bold">File Type:</span>{{ media_item.type }}
                </td>
                <td>
                  <span class="semi-bold">Size:</span
                  >{{ media_item.size | mediaSize : "mb" }}
                </td>
              </tr>
              <tr mdbTableCol>
                <td>
                  <span class="semi-bold">File Extension:</span
                  >{{ media_item.file_ext }}
                </td>
                <td>
                  <span class="semi-bold">Shares:</span
                  >{{ media_item.shared_with.length - 1 }}
                </td>
              </tr>
              <tr mdbTableCol>
                <td>
                  <div class="content-plus">
                    <span class="semi-bold">Is 360?:</span>
                    <app-text-inline-edit
                      [data]="media_item.is_360 ? 'true' : 'false'"
                      [name]="'is_360'"
                      [type]="'select'"
                      [disabled]="
                        (isModerator || isParticipant) &&
                        media_item.current_owner !== user_id
                      "
                      [selectOptions]="is360Options"
                      (saveData)="updateMediaDetails($event, 'is_360', '')"
                    ></app-text-inline-edit>
                  </div>
                </td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="isClean"
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-cancel-button btn"
      (click)="closeModal()"
    >
      Close
    </button>
    <button
      *ngIf="!isClean"
      mdbBtn
      mdbWavesEffect
      #popOverTrigger="bs-mdbPopover"
      id="cancelWarning"
      type="button"
      color="link"
      class="theme-modal-cancel-button btn cancel-alert"
      mdbPopoverHeader=""
      triggers="click"
      [mdbPopover]="cancelWarning"
    >
      Cancel
    </button>
  </div>
  <ng-template #cancelWarning
    ><div class="custom-pop error-pop wider near-right">
      <div class="left-arrow"></div>
      <p class="semi-bold">
        Warning! All updates on this
        {{ labels.media.singular }} item will be lost.
      </p>
      <div class="button-wrapper">
        <button
          mdbBtn
          mdbWavesEffect
          type="button"
          color="link"
          class="theme-modal-button btn warning-stay"
          (click)="closePopOvers()"
        >
          Back to Edit {{ labels.media.singular }}
        </button>
        <button
          mdbBtn
          mdbWavesEffect
          type="button"
          color="link"
          class="theme-modal-cancel-button btn warning-leave"
          (click)="closeModal(true)"
        >
          Continue Cancel
        </button>
      </div>
    </div></ng-template
  >
</div>
