<div class="heading-content">
  <h4>Inventory Session Analytics: Historic Data</h4>
  <div class="callout-text-wrapper">
    <p [innerHTML]="copy"></p>
  </div>
</div>
<mdb-card class="inventory-session-result-card content-card">
  <div class="row justify-content-start flex-row" *ngIf="loading">
    <div class="col-12">
      <p class="loading-text">Loading...</p>
    </div>
  </div>
  <div class="row align-items-end inventory-controls" *ngIf="!loading">
    <div class="col-4">
      <div>
        <p class="mt-3">Select to begin.</p>
        <div class="md-form users-select">
          <mdb-select
            name="user-to-review"
            [options]="usersSelect"
            id="usersSelect"
            mdbInput
            mdbValidate
            placeholder="Select User"
            [(ngModel)]="selectedUser"
            (selected)="userSelected($event)"
          ></mdb-select>
          <label for="user-to-reivew">Users To Review</label>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="md-form select-date">
        <mdb-date-picker
          [inline]="true"
          name="start"
          [options]="myDatePickerOptions"
          [placeholder]="'Selected start date'"
          [(ngModel)]="model.start"
          id="start-date"
          onkeydown="return false"
          [label]="startDateLabel"
        ></mdb-date-picker>
        <!-- <label [class.active]="start" for="start">Start Date</label> -->
      </div>
    </div>
    <div class="col-3">
      <div class="md-form select-date">
        <mdb-date-picker
          [inline]="true"
          name="end"
          [options]="myDatePickerOptions"
          [placeholder]="'Selected end date'"
          [(ngModel)]="model.end"
          id="end-date"
          onkeydown="return false"
          [label]="endDateLable"
        ></mdb-date-picker>
      </div>
    </div>
    <!-- <div class="col-2">
        <div class="md-form users-select">
          <mdb-select
            [options]="sessionsSelect"
            id="sessionsSelect"
            mdbInput
            mdbValidate
            placeholder="Select Session"
            label="Session to Review"
            (selected)="sesssionSelected($event)"
          ></mdb-select>
        </div>
      </div> -->
    <div class="col-2">
      <div class="btn-spacing text-right inventory-btns">
        <span
          class="tooltip-cover"
          *ngIf="!isUserSelected"
          mdbTooltip="Please first select a user"
          placement="top"
        >
          <mdb-icon fas icon="sync-alt" class="pr-1"></mdb-icon>
          Update Table
        </span>
        <button
          class="theme-modal-button"
          type="button"
          mdbBtn
          mdbWavesEffect
          [disabled]="!isUserSelected"
          (click)="getInventory()"
        >
          <mdb-icon fas icon="sync-alt" class="pr-1"></mdb-icon>
          Update Table
        </button>
      </div>
    </div>
  </div>
  <hr *ngIf="adminDefault && tableStatus === 'dataLoaded' && filter" />
  <div
    class="row align-items-end inventory-controls"
    *ngIf="adminDefault && tableStatus === 'dataLoaded' && filter"
  >
    <!-- 
      - each filter is on "col-2"
      - add "select-filter" to select in order to get styles right next to the "md-form" class
      - add "select-date" to date-picker in order to get styles right next to the "md-form" class
      - use in-line datePicker
     -->
    <div class="col-3">
      <p>Filters</p>
      <div class="md-form select-filter" *ngIf="adminDefault && filter">
        <mdb-select
          name="filter-type"
          [options]="typeSelect"
          id="typeSelect"
          mdbInput
          mdbValidate
          placeholder="Type of room"
          (selected)="typeSelected($event)"
        ></mdb-select>
        <label for="filter-type">Type of Room</label>
      </div>
    </div>
  </div>
</mdb-card>
<mdb-card class="inventory-table-container content-card" *ngIf="!loading">
  <div
    class="row inventory-controls justify-content-between"
    *ngIf="adminDefault"
  >
    <div class="col-5">
      <div class="custom-tooltip" *ngIf="headingHit !== null">
        <p>{{ tooltips[headingHit] }}</p>
        <div class="tailShadow"></div>
        <div class="tail1"></div>
        <div class="tail2"></div>
      </div>
    </div>
    <div class="col-3">
      <div class="btn-spacing text-right inventory-btns">
        <button
          *ngIf="
            tableStatus === 'importMedia' || tableStatus === 'importEnabled'
          "
          class="theme-modal-button"
          type="button"
          mdbBtn
          mdbWavesEffect
          (click)="importMeta()"
        >
          <mdb-icon fas icon="upload" class="pr-1"></mdb-icon>
          Import to DB
        </button>
        <button
          *ngIf="tableStatus === 'dataLoaded'"
          class="theme-modal-button"
          type="button"
          mdbBtn
          mdbWavesEffect
          (click)="downloadCSV()"
        >
          <mdb-icon fas icon="download" class="pr-1"></mdb-icon>
          Download CSV
        </button>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!loading">
    <div class="col-12">
      <div *ngIf="adminDefault">
        <p class="loading-text mx-0" *ngIf="tableStatus === 'pending'">
          Select a user from the drop down to see inventory session results
          data.
        </p>
        <p class="loading-text mx-0" *ngIf="tableStatus === 'noData'">
          There are no results yet for this user.
        </p>
      </div>
      <div *ngIf="!adminDefault">
        <p class="loading-text mx-0" *ngIf="tableStatus === 'noData'">
          No Inventory Session Analytics yet.
        </p>
      </div>
      <!-- the table -->
      <div
        class="table-container"
        *ngIf="tableStatus === 'dataLoaded' && !useMeta"
      >
        <table
          class="table table-responsive-md btn-table meeting-nav-table inventory-table"
          mdbTable
          small="true"
          striped="true"
          mdbTableScroll
          scrollY="true"
          scrollX="true"
          maxHeight="600"
        >
          <colgroup>
            <col style="width: auto" />
            <col style="width: auto" />
            <col style="width: auto" />
            <col style="width: auto" />
            <col style="width: auto" />
            <col style="width: 120px" />
            <col style="width: 120px" />
            <col style="width: 120px" />
            <col style="width: auto" />
            <col style="width: 120px" />
            <col style="width: 120px" />
            <col style="width: 150px" />
          </colgroup>
          <thead class="sticky-top theme-base-medium-dark white-text">
            <th
              *ngFor="let label of inventory.labels; let i = index"
              scope="row"
            >
              <mdb-icon
                fas
                icon="question-circle"
                class="info-icon"
                (mouseenter)="mouseEnter(i)"
                (mouseleave)="mouseLeave()"
              ></mdb-icon>
              {{ label }}
              <!-- <mdb-icon fas icon="sort" class="sort-icon"></mdb-icon> -->
            </th>
          </thead>
          <tbody>
            <tr
              mdbTableCol
              *ngFor="let item of inventory.inventory; let i = index"
            >
              <td *ngFor="let column of item">
                {{ column }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- the other table -->
      <!-- this table does not contain ScrollX as I can't visualize it -->
      <div
        class="table-container"
        *ngIf="tableStatus === 'dataLoaded' && useMeta"
      >
        <table
          class="table table-responsive-md btn-table meeting-nav-table the-other-inventory-table"
          mdbTable
          small="true"
          striped="true"
          mdbTableScroll
          scrollY="true"
          scrollX="true"
          maxHeight="600"
          hover="true"
        >
          <thead class="theme-base-medium-dark white-text">
            <tr>
              <th>Date</th>
              <th>Item Name</th>
              <th *ngIf="adminDefault">Expected Item Condition</th>
              <th>Recorded Item Condition</th>
              <th *ngIf="adminDefault">Expected No. of Inventory</th>
              <th>Recorded No. of Inventory</th>
              <th>Location</th>
              <th>Custom Status Text</th>
              <th *ngIf="adminDefault">Expected Other or Custom Status</th>
            </tr>
          </thead>
          <tbody>
            <tr mdbTableCol *ngFor="let data of tableData">
              <td>{{ data.m_dataSaveTime }}</td>
              <td>{{ data.m_itemName }}</td>
              <td *ngIf="adminDefault">
                {{ data.m_expectedItemStatusList.m_statusNames.join("; ") }}
              </td>
              <td>
                {{ data.m_studentStatuses.m_statusNames.join("; ") }}
              </td>
              <td *ngIf="adminDefault">{{ data.m_expectedProductAmount }}</td>
              <td>{{ data.m_reportedProductAmount }}</td>
              <td>{{ data.m_Location }}</td>
              <td>{{ data.m_userInputOtherStatuses }}</td>
              <td *ngIf="adminDefault">
                {{ possibleData(data, "m_otherBoxLook") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</mdb-card>
