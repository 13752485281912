<!-- Modal Content -->
<div class="modal-content-wrapper" (click)="modalClick($event)">
  <div class="modal-header text-center">
    <h4
      class="modal-title w-100 font-weight-bold"
      [ngClass]="{ removeTitle: action === 'delete' }"
    >
      <span> {{ formTitle | titlecase }} </span>
    </h4>
    <button
      *ngIf="isClean || action === 'delete' || action === 'restore'"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <button
      *ngIf="!isClean && action !== 'delete' && action !== 'restore'"
      type="button"
      class="close cancel-alert"
      #popOverTriggerTop="bs-mdbPopover"
      id="cancelWarningCloseX"
      triggers="click"
      mdbPopoverHeader=""
      [mdbPopover]="cancelWarning"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>

  <div class="modal-body modal-fluid-content p-0">
    <div class="container-overflow-auto">
      <div class="row">
        <div *ngIf="action === 'delete' || action === 'restore'">
          <div class="col-12">
            <p class="font-16" *ngIf="action === 'delete'">
              Delete this {{ labels.user.singular }} from the system?
            </p>
            <p class="font-16" *ngIf="action === 'restore'">
              {{ msgs.actionMsg }}?
            </p>
            <ul class="list-unstyled">
              <li>
                <span class="bold">First Name:</span>
                {{ targetUser.first_name }}
              </li>
              <li>
                <span class="bold">Last Name:</span>
                {{ targetUser.last_name }}
              </li>
              <li>
                <span class="bold">Username:</span>
                {{ targetUser.username }}
              </li>
              <li>
                <span class="bold">Email:</span>
                {{ targetUser.email }}
              </li>
            </ul>
          </div>
        </div>
        <form
          [formGroup]="userForm"
          class="text-center edit-form striped-form"
          autocomplete="disabled"
          *ngIf="!formLoading && action !== 'delete' && action !== 'restore'"
        >
          <div class="row p-0">
            <div class="col-12">
              <p>
                <span class="action-msg-small">Required*</span>
              </p>
            </div>
          </div>
          <div class="row striped">
            <div class="grid">
              <div class="grid-item">
                <div class="md-form">
                  <!--username-->
                  <div *ngIf="!viewOnly.username">
                    <input
                      type="text"
                      id="username"
                      class="form-control"
                      formControlName="username"
                      mdbInput
                      mdbValidate
                      [ngClass]="{ disabled: userStatus === 'invited' }"
                      trimInput
                    />
                    <label for="username">* Username</label>
                    <mdb-error
                      *ngIf="
                        username.invalid && (username.dirty || username.touched)
                      "
                    >
                      <ng-container *ngIf="!username.errors.username_exists"
                        >Please add a username</ng-container
                      >
                      <ng-container *ngIf="username.errors.username_exists"
                        >Username already exists</ng-container
                      >
                    </mdb-error>
                  </div>
                  <div *ngIf="viewOnly.username">
                    <p>
                      <span class="bold">Username:</span>
                      {{ preSelected.username }}
                    </p>
                  </div>
                  <!--end username-->
                </div>
              </div>
              <div class="grid-item">
                <div class="md-form">
                  <!--email-->
                  <div *ngIf="!viewOnly.email">
                    <input
                      type="email"
                      id="email"
                      class="form-control"
                      formControlName="email"
                      mdbInput
                      mdbValidate
                      autocomplete="disabled"
                      [ngClass]="{
                        disabled:
                          userStatus === 'invited' || userStatus === 'accepted'
                      }"
                      trimInput
                    />
                    <label for="email">* Email</label>
                    <mdb-error
                      *ngIf="email.invalid && (email.dirty || email.touched)"
                    >
                      <ng-container *ngIf="!email.errors.email_exists"
                        >Please add a valid email</ng-container
                      >
                      <ng-container *ngIf="email.errors.email_exists"
                        >Email already exists</ng-container
                      >
                    </mdb-error>
                  </div>
                  <div *ngIf="viewOnly.last_name">
                    <p>
                      <span class="bold">Email:</span> {{ preSelected.email }}
                    </p>
                  </div>
                  <!--end email-->
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="grid">
              <div class="grid-item">
                <div class="md-form">
                  <!--first name-->
                  <div *ngIf="!viewOnly.first_name">
                    <input
                      type="text"
                      id="first_name"
                      class="form-control"
                      formControlName="first_name"
                      mdbInput
                      mdbValidate
                      autocomplete="disabled"
                      [ngClass]="{ disabled: userStatus === 'invited' }"
                      trimInput
                    />
                    <label for="first_name">* First name</label>
                    <mdb-error
                      *ngIf="
                        first_name.invalid &&
                        (first_name.dirty || first_name.touched)
                      "
                    >
                      Please add a valid first name
                    </mdb-error>
                  </div>
                  <div *ngIf="viewOnly.first_name">
                    <p>
                      <span class="bold">First Name:</span>
                      {{ preSelected.first_name }}
                    </p>
                  </div>
                  <!--end first name-->
                </div>
              </div>
              <div class="grid-item">
                <div class="md-form">
                  <!--last name-->
                  <div *ngIf="!viewOnly.last_name">
                    <input
                      type="text"
                      id="last_name"
                      class="form-control"
                      formControlName="last_name"
                      mdbInput
                      mdbValidate
                      autocomplete="disabled"
                      [ngClass]="{ disabled: userStatus === 'invited' }"
                      trimInput
                    />
                    <label for="last_name">* Last name</label>
                    <mdb-error
                      *ngIf="
                        last_name.invalid &&
                        (last_name.dirty || last_name.touched)
                      "
                    >
                      Input invalid
                    </mdb-error>
                    <mdb-success
                      *ngIf="
                        last_name.valid &&
                        (last_name.dirty || last_name.touched)
                      "
                    >
                      Input valid
                    </mdb-success>
                  </div>
                  <div *ngIf="viewOnly.last_name">
                    <p>
                      <span class="bold">* Last Name:</span>
                      {{ preSelected.last_name }}
                    </p>
                  </div>
                  <!--end last name-->
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!viewOnly.meta">
            <div class="row striped">
              <div class="grid">
                <div class="grid-item">
                  <div class="md-form">
                    <!--admin capability-->
                    <label class="active-label" for="role-type-id"
                      >* System Capability</label
                    >
                    <mdb-select
                      [ngClass]="{
                        disabled:
                          user.role_type_id > 2 && action === 'update-self'
                      }"
                      [options]="adminCaps"
                      id="role-type-id"
                      class="role-type"
                      formControlName="role_type_id"
                      mdbInput
                      mdbValidate
                      placeholder="Choose {{
                        this.labels.systemCapability.singular | titlecase
                      }}"
                    ></mdb-select>
                    <mdb-error
                      *ngIf="
                        last_name.invalid &&
                        (last_name.dirty || last_name.touched)
                      "
                    >
                      Input invalid
                    </mdb-error>
                    <!--admin capability-->
                  </div>
                </div>
                <div class="grid-item">
                  <div class="md-form">
                    <!--experience role-->
                    <mdb-select
                      [ngClass]="{
                        disabled:
                          user.role_type_id > 2 && action === 'update-self'
                      }"
                      [options]="expRoleSelect"
                      id="role"
                      formControlName="role"
                      mdbInput
                      mdbValidate
                      placeholder="Choose Experience Role"
                      label="{{ labels.eventRoles.singular | titlecase }}"
                    ></mdb-select>
                    <!--end experience role-->
                  </div>
                </div>
                <div class="grid-item" *ngIf="showPersona">
                  <div class="md-form">
                    <!--experience persona-->
                    <ng-template #personaWarning>
                      <p *ngIf="user.role_type_id === 1">
                        This client instance requires personas, please add
                        personas
                        <a
                          [routerLink]="[
                            '/client',
                            teamID,
                            'entity-management'
                          ]"
                          >under Entity Management</a
                        >.
                      </p>
                      <p *ngIf="user.role_type_id === 2">
                        Personas are required - please inquire with support
                        about adding personas.
                      </p>
                    </ng-template>
                    <button
                      *ngIf="!expPersonaSelect.length && user.role_type_id < 3"
                      mdbBtn
                      class="info-button color-theme-base-medium"
                      [mdbPopover]="personaWarning"
                      placement="right"
                      mdbPopoverHeader="Need Personas"
                      mdbWavesEffect
                    >
                      <mdb-icon
                        fas
                        icon="info-circle"
                        class="text-danger"
                      ></mdb-icon>
                    </button>
                    <mdb-select
                      [ngClass]="{
                        disabled:
                          user.role_type_id > 2 && action === 'update-self'
                      }"
                      *ngIf="expPersonaSelect.length"
                      [options]="expPersonaSelect"
                      id="personaID"
                      formControlName="persona_id"
                      mdbInput
                      mdbValidate
                      placeholder="Choose Experience Persona"
                      label="{{ labels.eventPersona.singular | titlecase }} *"
                    ></mdb-select>
                    <mdb-error
                      *ngIf="
                        username.invalid && (username.dirty || username.touched)
                      "
                    >
                      Please provide a persona
                    </mdb-error>
                    <!--end experience persona-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div
        class="row mt-3"
        *ngIf="sourceType === 'account' && user.status === 'generic'"
      >
        <button
          mdbBtn
          type="button"
          mdbWavesEffect
          class="theme-modal-button btn"
          mdbWavesEffect
          (click)="openPasswordModal()"
        >
          Edit Password
        </button>
      </div>
      <div
        class="row mt-3"
        *ngIf="sourceType === 'account' && user.status === 'accepted'"
      >
        <button
          mdbBtn
          type="button"
          mdbWavesEffect
          class="theme-modal-button btn"
          mdbWavesEffect
          (click)="sendUserReset(user)"
        >
          Send Password Reset
        </button>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <span
      *ngIf="!formLoading"
      id="mainEventAction"
      class="button-wrapper"
      triggers="focus"
      mdbPopoverHeader=""
      [mdbPopover]="
        !userForm.valid && !isClean && formState !== 'processing'
          ? mainEventAction
          : null
      "
    >
      <button
        *ngIf="formState !== 'success'"
        mdbBtn
        type="button"
        [ngClass]="{ 'btn-danger': action == 'delete' }"
        mdbWavesEffect
        class="theme-modal-button btn"
        mdbWavesEffect
        [disabled]="
          (!userForm.valid || isClean || formState === 'processing') &&
          action !== 'delete' &&
          action !== 'restore'
        "
        (click)="manageUser()"
      >
        {{ btnLabel.main }}
      </button>
    </span>
    <button
      *ngIf="isClean || action === 'delete' || action === 'restore'"
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-cancel-button btn"
      (click)="closeModal()"
    >
      {{ this.action === "restore" ? "Do Not Restore" : "Close" }}
    </button>
    <button
      *ngIf="!isClean && action !== 'delete' && action !== 'restore'"
      mdbBtn
      mdbWavesEffect
      #popOverTrigger="bs-mdbPopover"
      id="cancelWarning"
      type="button"
      color="link"
      class="theme-modal-cancel-button btn cancel-alert"
      mdbPopoverHeader=""
      triggers="click"
      [mdbPopover]="cancelWarning"
    >
      Cancel
    </button>
  </div>
</div>

<ng-template #mainEventAction
  ><div class="custom-pop error-pop">
    <p>Please review issues above.</p>
  </div></ng-template
>

<ng-template #cancelWarning
  ><div class="custom-pop error-pop wider near-right">
    <p class="semi-bold">
      Warning! All {{ action === "add" ? "work" : "updates" }} on this
      {{ labels.user.singular }} will be lost.
    </p>
    <div class="button-wrapper">
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-button btn warning-stay"
        (click)="closePopOvers()"
      >
        Back to {{ action }} {{ labels.user.singular }}
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn warning-leave"
        data-dismiss="modal"
        (click)="closeModal(true)"
      >
        Continue Cancel
      </button>
    </div>
  </div></ng-template
>
