<ng-container
  *ngIf="!is_hub && !is_aia && starting_experience_id.value !== null"
>
  <p class="experience-buttons">
    <button
      *ngIf="!experienceFieldsGroups.length"
      mdbBtn
      mdbWavesEffect
      class="theme-modal-button btn"
      (click)="addExperienceFields()"
      [disabled]="!starting_experience_id.value"
    >
      Add additional {{ labels.experience.plural }}
    </button>
    <button
      *ngIf="experienceFieldsGroups.length + 1 < experiencesSelectBase.length"
      mdbBtn
      mdbWavesEffect
      class="theme-modal-button btn"
      (click)="addAllExperienceFields()"
      [disabled]="!starting_experience_id.value"
    >
      Add all {{ labels.experience.plural }}
    </button>
  </p>
</ng-container>

<div
  *ngIf="
    !is_hub &&
    !is_aia &&
    starting_experience_id.value !== null &&
    experienceFieldsGroups.length
  "
  class="row experiences experiences-additional repeater"
>
  <p class="font-16 title">
    Additional {{ labels.experience.plural | titlecase }}
  </p>
  <div
    class="col-md-12 experiences-wrapper"
    *ngFor="let experienceUserFields of experienceFieldsGroups; let i = index"
  >
    <div class="grid">
      <div class="grid-item repeater-item">
        <div class="d-flex form-repeater" [formGroup]="experienceUserFields">
          <div class="btn-group list-btns-sm btn-icons">
            <button
              *ngIf="experiencesSelectCurrent.length > 0"
              mdbBtn
              mdbWavesEffect
              class="btn-flat btn-icon add-button"
              [ngClass]="{
                show: i === experienceFieldsGroups.length - 1
              }"
              [disabled]="
                (experienceUserFields.controls.experience_id.invalid &&
                  experienceUserFields.controls.experience_id !== null &&
                  experienceUserFields.controls.experience_id.errors !== null &&
                  !experienceUserFields.controls.experience_id.errors
                    .eventCapacity) ||
                (experienceUserFields.controls.experience_id.valid &&
                  i < experienceFieldsGroups.length - 1) ||
                (experienceUserFields.controls.experience_id.errors !== null &&
                  experienceUserFields.controls.experience_id.errors
                    .eventCapacity &&
                  i < experienceFieldsGroups.length - 1)
              "
              (click)="addExperienceFields(i + 1)"
            >
              <fa-icon [icon]="faPlusSquare"></fa-icon>
            </button>
            <button
              mdbBtn
              mdbWavesEffect
              class="btn-flat btn-icon minus"
              (click)="removeExperienceField(i)"
            >
              <fa-icon
                class="color-theme-base-medium"
                [icon]="faMinusSquare"
              ></fa-icon>
            </button>
          </div>
          <div class="md-form static-error experience flex-grow-1">
            <!--experience-->
            <mdb-select-2
              placeholder="Choose an {{
                labels.experience.singular | titlecase
              }}"
              label="{{ labels.experience.singular | titlecase }} Name"
              formControlName="experience_id"
            >
              <mdb-select-option
                *ngFor="let option of experiencesSelect[i]"
                [value]="option.value"
                >{{ option.label }}</mdb-select-option
              >
            </mdb-select-2>
            <mdb-error
              *ngIf="
                experienceUserFields.controls.experience_id.invalid &&
                (experienceUserFields.controls.experience_id.dirty ||
                  experienceUserFields.controls.experience_id.touched) &&
                experienceUserFields.controls.experience_id.errors !== null &&
                experienceUserFields.controls.experience_id.errors.required
              "
            >
              Please select an
              {{ labels.experience.singular }}
            </mdb-error>
            <mdb-error
              *ngIf="
                experienceUserFields.controls.experience_id.errors !== null &&
                experienceUserFields.controls.experience_id.errors.eventCapacity
              "
            >
              <span class="font-12 warning"
                >Note that the attendee capacity of this Experience is under the
                attendee capacity set for this
                {{ labels.event.singular }}.</span
              >
            </mdb-error>
            <!--end persona-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
