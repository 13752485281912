import {
  Component,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Location, TitleCasePipe } from '@angular/common';
import { CoolLocalStorage } from '@angular-cool/storage';
import { UntypedFormControl } from "@angular/forms";
import Chart from "chart.js/auto";

import * as moment from "moment";
import { DateTime, IANAZone } from "luxon";
import { IMyOptions } from "ng-uikit-pro-standard";
import { ActivatedRoute, Router } from '@angular/router';

import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
  INumberFilterParams,
  ITextFilterParams,
  SizeColumnsToFitGridStrategy,
} from "ag-grid-community";

import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { MetricsServicesService } from 'src/app/modules/metrics/services/metrics-services.service';
import { SizeColumnsToFitProvidedWidthStrategy } from 'ag-grid-community';
import { SizeColumnsToContentStrategy } from 'ag-grid-community';
import { combineLatest } from 'rxjs';
import { SettingsService } from "src/app/services/utilities/settings.service";

export class chartDates {
  constructor(public start: string, public end: string) { }
}

@Component({
  selector: 'app-metrics-aia',
  templateUrl: './metrics-aia.component.html',
  styleUrls: ['./metrics-aia.component.scss'],
  providers: [TitleCasePipe]
})
export class MetricsAiaComponent {
  @ViewChild("sessionsChart") private chartRef: ElementRef;

  //persistent
  private token: string;
  public teamID: number;
  public clientCode: string;
  public title: string = "AI Dashboard";
  public users: any;
  private targetURL: string;
  private gridApi!: GridApi;
  private clientSettings: any;
  public user: any;

  //icons
  public faTimes = faTimes;

  //visibility
  public showDropdown: boolean = true;
  public gridLoading: boolean = true;
  public errorRetrievingMsg: string = "";
  private chartCreated = false;
  public showUserInformation: boolean = false;

  //grid config
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
      type: "fitGridWidth",
    };
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 220,
    suppressHeaderMenuButton: false,
  };
  public rowData!: any[];
  public skillRowData!: any[];
  public durtionChrtData!: any[];
  public themeClass: string =
    "ag-theme-quartz-dark";

  //date/time
  public tz: any;
  public tz_iana: any;

  //chart handling
  private chart: Chart;
  private chartData: any;
  public labels;
  public chartLabelDC = "";
  public chartLabelsDC: Array<any> = [];
  public date = new Date();
  public disableDatesObj = { year: 0, month: 0, day: 0 };
  public disabledUntil = { year: 0, month: 0, day: 0 };
  public myDatePickerOptions: IMyOptions = {
    alignSelectorRight: true,
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableSince: this.disableDatesObj,
  };
  public endDateoickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableUntil: this.disabledUntil,
    disableSince: this.disableDatesObj,
  };
  public model = new chartDates("", "");
  public startDateLabel: string = "Start Date";
  public endDateLable: string = "End Date";
  public timeDenomination = [
    { value: "hour", label: "Hourly" },
    { value: "day", label: "Daily" },
    { value: "month", label: "Monthly" },
    { value: "year", label: "Yearly" },
  ];
  public timeSelector: UntypedFormControl = new UntypedFormControl("day");
  public dateAggregates: {
    value: number;
    label: string;
    unit: string;
  }[];
  public chartOptionsDC: any = {
    indexAxis: "x",
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Total Connections",
        },
      },
      x: {
        title: {
          display: true,
          text: "Time Frame",
        },
      },
    },
    interaction: {
      mode: "point",
    },
    //onClick: function adjustLineEndPoints(context) {  }
  };

  //query params
  public queryParams = {
    interval: "",
    start: "",
    end: "",
  };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private _notificationService: NotificationsService,
    private _metricsServicesService: MetricsServicesService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private TitleCase: TitleCasePipe,
    private _settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.retrieveToken();
    this.retrieveTeamID();
    this.retrieveUser();
    this.retrieveClientCode();
    this.retrieveLabels();

    let getTimezones = this._metricsServicesService.resolveTimeZone();
    this.tz_iana = getTimezones.tz_iana
    this.tz = getTimezones.tz;

    console.log("this.tz in metrics aia", this.tz);

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    this.showUserInformation = this.clientSettings.metricsUsername.unconstrained_default;

    this.buildColumnDefs();

    // Listen for route changes
    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([params, queryParams]) => {

      const retrievedParams = this._metricsServicesService.retrieveQueryParams(queryParams);

      this.queryParams.start = retrievedParams.start;
      this.queryParams.end = retrievedParams.end;
      this.queryParams.interval = retrievedParams.interval;
      if (this.queryParams.interval != undefined) {
        this.timeSelector = new UntypedFormControl(this.queryParams.interval);
      }

      this.targetURL = `foretell/display/aia/sessions/${this.teamID}`;

      if (this.queryParams.start != undefined && this.queryParams.end != undefined) {

        this.model = new chartDates(
          moment(this.queryParams.start).format("MMM D, YYYY"),
          moment(this.queryParams.end).format("MMM D, YYYY")
        );

      } else {

        // const date = new Date()
        const today = moment();
        // called moment funct because the local var gets reasigned
        this.model = new chartDates(
          today.subtract(7, "d").format("MMM D, YYYY"),
          moment().format("MMM D, YYYY")
        );
      }

      this.disabledDates(moment());
      this.generateUntilDate();

      this.retrieveData();
    });
  }

  ngAfterViewChecked() {
    if (!this.chartCreated && this.chartRef && this.chartRef.nativeElement && !this.gridLoading) {
      this.chartCreated = true;

      console.log("creating chart");

      this.chart = new Chart(this.chartRef.nativeElement, {
        type: "bar",
        data: this.chartData,
        options: this.chartOptionsDC,
      });
    }
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private buildColumnDefs() {

    let columns = [];

    columns.push({
      headerName: 'Session Scenario', field: 'event_name', type: 'text', filter: "agTextColumnFilter", cellStyle: { 'cursor': 'pointer' }, filterParams: {
        buttons: ["clear"],
      } as ITextFilterParams
    })

    if (this.showUserInformation) {
      columns.push({
        headerName: 'Username', field: 'username', type: 'text', filter: "agTextColumnFilter", cellStyle: { 'cursor': 'pointer' }, filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      });
    } else {
      columns.push({
        headerName: 'User ID', field: 'user_id', type: 'number', filter: 'agNumberColumnFilter', cellStyle: { 'cursor': 'pointer' }, filterParams: {
          buttons: ["clear"],
        } as INumberFilterParams
      });
    }

    columns.push({
      headerName: 'Start Time', field: 'start_time', type: 'date', cellStyle: { 'cursor': 'pointer' }, filter: 'agDateColumnFilter',
    });
    columns.push({
      headerName: 'End Time', field: 'end_time', type: 'date', cellStyle: { 'cursor': 'pointer' }, filter: 'agDateColumnFilter',
    });
    columns.push({
      headerName: 'Practice Sessions', field: 'practice_session_count', type: 'number', filter: 'agNumberColumnFilter', cellStyle: { 'cursor': 'pointer' }, filterParams: {
        buttons: ["clear"],
      } as INumberFilterParams
    });
    columns.push({
      headerName: 'Submitted Files?', field: 'submitted_files', type: "boolean", cellStyle: { 'cursor': 'pointer' }
    });

    this.columnDefs = this._metricsServicesService.createColumnDefinitions(columns);
  }

  public async retrieveData() {
    let self = this;

    if (this.chart) {
      this.chart.destroy();
    }

    let start = this.model.start;
    let end = this.model.end;

    if (start === "" || end === "") return false;

    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end).format("YYYY-MM-DD");
    let tmz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.checkDateRange({}, "initiated", moment(start), moment(end));

    let postData = {
      "start": startDate,
      "end": endDate,
      "tmz": tmz,
      "page_number": 1,
      "page_size": 2000,
      "last_request_id": null,
      "interval": this.timeSelector.value
    }

    //setup query params
    this.queryParams = {
      interval: this.timeSelector.value,
      start: startDate,
      end: endDate
    };

    let gridData = await this.retrieveGridData(postData).catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    let graph = {
      "Data": gridData.asset_sessions.pagination.total_records.legacy_counts,
      "graphTitle": "Sessions over Time",
      "labelData": gridData.asset_sessions.pagination.total_records.legacy_labels,
      "xLabel": "Days",
      "yLabel": "Number of Sessions"
    }

    this.rowData = gridData.asset_sessions.sessions;

    this.dateAggregates = gridData.asset_sessions.pagination.total_records.aggregates;

    let timezone_offset = gridData.asset_sessions.timezone_offset;

    this.chartLabelsDC = gridData.asset_sessions.pagination.total_records.legacy_labels;

    let xmin = moment(`${startDate} 00:00 AM`).format("X");
    let xmax = moment(`${endDate} 11:59 PM`).format("X");

    //generate labels from start to end date
    let currentDate = moment(startDate);

    let endDateMoment = moment(endDate);

    while (currentDate.isSameOrBefore(endDateMoment)) {

      //chart labels are in this format: 2024-12-02T00:00:00-05:00
      //if there are any chart labels missing between the start and end date, add them
      let label = currentDate.format("YYYY-MM-DDT00:00:00" + timezone_offset);

      if (!this.chartLabelsDC.includes(label)) {
        this.chartLabelsDC.push(label);
      }

      //increment the date
      currentDate = currentDate.add(1, this.timeSelector.value);

    }

    if (this.timeSelector.value === "month") {
      xmin = moment(startDate).startOf('month').format("YYYY-MM-DD");
      xmax = moment(endDate).endOf('month').format("YYYY-MM-DD");
    } else if (this.timeSelector.value === "year") {
      xmin = moment(startDate).format("YYYY-01-01");
      xmax = moment(endDate).format("YYYY-12-31");
    }

    let unit = this.timeSelector.value === "hour" ? "minute" : this.timeSelector.value;

    let defaultYLabels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    let ymax = graph.Data.length ? Math.max(...graph.Data) : Math.max(...defaultYLabels);
    let yLabels = [...Array(ymax).keys()].push(ymax);

    this.chartOptionsDC = {
      indexAxis: "x",
      responsive: true,
      maintainAspectRatio: false,
      backgroundColor: "rgba(29; 61; 124; 0.65)",
      plugins: {
        // zoom: zoomOptions,
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: function (context) {
              let format = "LLL d, yyyy";

              if (self.timeSelector.value === "hour") {
                format = "LLL d, yyyy h:mm a";
              } else if (self.timeSelector.value === "month") {
                format = "LLL yyyy";
              } else if (self.timeSelector.value === "year") {
                format = "yyyy";
              }

              let date = DateTime.fromMillis(context[0].parsed.x, { zone: IANAZone.create("GMT") });

              if (self.timeSelector.value === "hour") {
                date = DateTime.fromMillis(context[0].parsed.x, { zone: IANAZone.create(tmz) });
              }

              console.log("date in tooltip", date);

              let label = date.toFormat(format);
              return label;
            },
            label: function (context) {
              return `${context.formattedValue} sessions`;
            }
          }
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Number of Sessions",
          },
          min: 0,
          max: ymax + 1,
          ticks: {
            precision: 0,
            stepSize: 1,
            callback: function (value) {
              if (Math.floor(value) === value) {
                return value;
              }
            }
          }
        },
        x: {
          title: {
            display: true,
            text: "Date",
          },
          type: 'time',
          bounds: 'ticks',
          min: xmin,
          max: xmax,
          time: {
            parser: moment.parseZone,
            tooltipFormat: 'll',
            unit: unit
          },
          ticks: {
            callback: function (value, index, values) {
              let date = DateTime.fromMillis(values[index].value, { zone: IANAZone.create(tmz) });

              if (date.isValid) {

                let format = "LLL d, yyyy";

                if (self.timeSelector.value === "hour") {
                  format = "LLL d, yyyy h:mm a";
                } else if (self.timeSelector.value === "month") {
                  format = "LLL yyyy";
                } else if (self.timeSelector.value === "year") {
                  format = "yyyy";
                }

                return date.toFormat(format);
              } else {

                return value;
              }
            }
          }
        },
      },
      interaction: {
        mode: "point",
      },
      //onClick: function adjustLineEndPoints(context) {  }
    };

    let data = {
      labels: this.chartLabelsDC,
      datasets: [
        {
          data: graph.Data,
          borderColor: "#483c46",
          maxBarThickness: 50,
          minBarLength: 10,
          backgroundColor: [
            'rgba(0, 35, 56, 0.42)',
            'rgba(72, 60, 70, 0.42)',
            'rgba(75, 45, 27, 0.42)',
            'rgba(4, 201, 209, 0.42)',
            'rgba(38, 131, 140, 0.42)',
            'rgba(190, 124, 77, 0.42)',
            'rgba(160, 142, 106, 0.42)'
          ],
          label: "",
        },
      ],
      yLabels: yLabels,
    };

    if (this.timeSelector.value === "hour") {
      data.datasets[0]["barThickness"] = 3;
    }

    console.log("data for chart", data);

    this.chartData = data;
    this.gridLoading = false;
    //first chart initialization is in ngAfterViewChecked

    if (this.chartCreated) {
      this.chart = new Chart(this.chartRef.nativeElement, {
        type: "bar",
        data: this.chartData,
        options: this.chartOptionsDC,
      });
    }
  }

  private retrieveGridData(data) {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    if (this.user.role_type_id !== 1 && this.user.role_type_id !== 2) {
      this.targetURL = `foretell/display/aia/sessions/${this.teamID}/user/${this.user.id}`;
    }

    return this._metricsServicesService.restfulForetellAPIRequest(this.targetURL, "post", data, getOptions, true).toPromise();

  }

  public onCellClicked(event) {

    this.router.navigate(['client', this.clientCode, 'analytics', 'aia', 'session', event.data['session_id']], { queryParams: { ...this.queryParams, origin: "aia_event_analytics" } });

  }

  public checkDateRange(event, dateField, startDate?, endDate?) {

    console.log("event", event);

    if (dateField === 'start') {
      startDate = moment(event.actualDateFormatted);
    } else {
      endDate = moment(event.actualDateFormatted);
    }

    console.log("startDate", startDate);
    console.log("typeof startDate", typeof startDate);
    console.log("endDate", endDate);
    console.log("typeof endDate", typeof endDate);

    //see if diff between dates is greater than 7 days
    let diff = endDate.diff(startDate, "days");

    console.log("diff", diff);

    //if greater than 7 days, set "hour" option in timeSelector to disabled
    this.timeDenomination = this.timeDenomination.map((item) => {
      if (diff > 7 && item.value === "hour") {
        item["disabled"] = true;
      } else {
        item["disabled"] = false;
      }

      return item;
    });

    //if the timeSelector is set to "hour" and the diff is greater than 7 days, set the timeSelector to "day"
    if (this.timeSelector.value === "hour" && diff > 7) {
      this.timeSelector = new UntypedFormControl("day");
    }
  }

  public disabledDates(currentDate: any) {
    let today = currentDate;
    let endDays = [28, 29, 30, 31];
    // handle year
    if (today.month() === 11 && today.date() === 31) {
      this.disableDatesObj.year = today.year() + 1;
    } else {
      this.disableDatesObj.year = today.year();
    }
    // handle month
    // handle day
    if (endDays.every((day) => day === today.date())) {
      // I need to find a way to test this
      this.disableDatesObj.day = 1;
      this.disableDatesObj.month = today.month() + 2;
    } else {
      this.disableDatesObj.day = today.date() + 1;
      this.disableDatesObj.month = today.month() + 1;
    }
  }

  // todo: edgecases for first dat of the month, and or last day of the month.
  public generateUntilDate() {
    // let today = currentDate;
    let untilDate = this.model.start;
    this.disabledUntil.year = parseInt(moment(untilDate).format("YYYY"));
    this.disabledUntil.day = parseInt(moment(untilDate).format("DD")) - 1;
    this.disabledUntil.month = parseInt(moment(untilDate).format("MM"));
  }

  public onDownloadCSV() {
    this.gridApi.exportDataAsCsv(this.getParams());
  }

  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  private getParams() {

    let start_date = moment(this.queryParams.start).format("YYYY-MM-DD");
    let end_date = moment(this.queryParams.end).format("YYYY-MM-DD");

    return {
      fileName: `AI_Sessions_${start_date}_to_${end_date}_pulled_${moment().format("YYYY-MM-DD")}.csv`
    };
  }

}
