import { AgRendererClickableIconComponent } from './../metrics-aia/renderers/ag-renderer-clickable-icon/ag-renderer-clickable-icon.component';
import { CoolLocalStorage } from '@angular-cool/storage';
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SettingsService } from "src/app/services/utilities/settings.service";

import * as moment from "moment";
import { DateTime, IANAZone } from "luxon";
import * as timezoneFixes from "src/assets/timezones/ianaToAbbreviation.json";
import Chart from "chart.js/auto";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TitleCasePipe } from '@angular/common';

@Injectable({
  providedIn: "root",
})
export class MetricsServicesService {
  private restURL: string = "";
  private apbRestURL: string = "";
  private teamID: number;
  private token: string;
  private labels: any;

  private usernameFlag = "";

  //custom cell renderers
  public frameworkComponents: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private _clientManagementService: ClientManagementService,
    private _settingsService: SettingsService,
    private coolLocalStorage: CoolLocalStorage,
    private titleCasePipe: TitleCasePipe
  ) {
    this.frameworkComponents = {
      iconRenderer: AgRendererClickableIconComponent,
    }
  }

  public setTeamID(teamID) {
    this.teamID = teamID;

    let storedSettings = this._settingsService.getSettingsFromStorage(teamID);
    this.restURL = storedSettings.metricsURI.unconstrained_default;
    this.apbRestURL = this._clientManagementService.getRESTurl();

    //add trailing slash to restURL if needed
    if (this.restURL[this.restURL.length - 1] !== "/") {
      this.restURL += "/";
    }

    if (
      storedSettings.showMetricsUsername !== undefined &&
      storedSettings.showMetricsUsername.unconstrained_default == true
    ) {
      this.usernameFlag += "/flag";
    }
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public getMetricsColors() {
    let chartColors = [
      "#002738",
      "#04c9d1",
      "#483c46",
      "#be7c4d",
    ];

    return chartColors;
  }

  public getMetricsOverTimeFrame(
    teamID: number,
    timeDenomination,
    startDate,
    endDate,
    tmz,
    apBackend?: boolean
  ): Observable<any> {

    if (apBackend === undefined) apBackend = false;

    let restURL = this.restURL +
      `sessions/sessionsovertime/${teamID}/${timeDenomination}/${startDate}/${endDate}/${tmz}`;

    let httpOptions = {};

    if (apBackend) {
      this.retrieveToken();
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

      httpOptions = {
        headers: headers,
      };

      restURL = this.apbRestURL + `/foretell/display/standard/sessions/overtime/${teamID}/${timeDenomination}/${startDate}/${endDate}/${tmz}`;
    }

    return this.http
      .get(restURL, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public getSessions(teamID: number, timePeriod, startDate, endDate, tmz, apBackend?: boolean): Observable<any> {

    if (apBackend === undefined) apBackend = false;

    let restURL = this.restURL +
      `sessions/client/${teamID}/${startDate}/${endDate}/${tmz}`

    let httpOptions = {};

    if (apBackend) {
      this.retrieveToken();
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

      httpOptions = {
        headers: headers,
      };

      restURL = this.apbRestURL + `/foretell/display/standard/sessions/client/${teamID}/${timePeriod}/${startDate}/${endDate}/${tmz}`
    }

    return this.http
      .get(
        restURL,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  public getUserSpeakingBreakdown(session_id, tmz): Observable<any> {
    //parse tmz by /
    let tmzParts = tmz.split("/");

    return this.http
      .get(
        this.restURL +
        `session/${session_id}/users/breakdown/speaking?tmz_general=${tmzParts[0]}&tmz_specific=${tmzParts[1]}&username_flag=${this.usernameFlag}`
      )
      .pipe(catchError(this.handleError));
  }

  public getUserGazingBreakdown(teamID, session_id, tmz): Observable<any> {
    //parse tmz by /
    let tmzParts = tmz.split("/");

    return this.http
      .get(
        this.restURL +
        `session/${session_id}/users/breakdown/gazing?tmz_general=${tmzParts[0]}&tmz_specific=${tmzParts[1]}&username_flag=${this.usernameFlag}`
      )
      .pipe(catchError(this.handleError));
  }

  public getUserBreakdown(teamID, session_id, tmz, apBackend): Observable<any> {

    //parse tmz by /
    let tmzParts = tmz.split("/");
    let httpOptions = {};

    let restURL = this.restURL +
      `session/${session_id}/users/breakdown?tmz_general=${tmzParts[0]}&tmz_specific=${tmzParts[1]}&username_flag=${this.usernameFlag}`;

    if (apBackend) {
      this.retrieveToken();
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

      httpOptions = {
        headers: headers,
      };

      restURL = this.apbRestURL + `/foretell/display/standard/session/${session_id}/users/breakdown?tmz_general=${tmzParts[0]}&tmz_specific=${tmzParts[1]}&username_flag=${this.usernameFlag}`;
    }

    return this.http
      .get(
        restURL,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  public restfulForetellAPIRequest(
    url: string,
    action: string,
    data,
    options?: any,
    useAPB?: boolean
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    if (useAPB === undefined) useAPB = false;
    let restURL = this.restURL + url;
    if (useAPB) restURL = this.apbRestURL + "/" + url;

    if (action == "get") {
      if (data.queryParams !== undefined) {

        let queryParams = data.queryParams;

        console.log("this.apbRestURL", this.apbRestURL);
        console.log("this.restURL", this.restURL);

        // Create a URL object
        const queryURL = new URL(url, useAPB ? this.apbRestURL : this.restURL);

        // Append query parameters to the URL
        Object.keys(queryParams).forEach(key => queryURL.searchParams.append(encodeURIComponent(key), encodeURIComponent(queryParams[key])));

        restURL = queryURL.href;

      }

      return this.http
        .get(restURL, options)
        .pipe(catchError(this.handleError));
    } else if (action == "post") {
      return this.http
        .post(restURL, data, options)
        .pipe(catchError(this.handleError));
    } else if (action == "put") {
      return this.http
        .put(restURL, data, options)
        .pipe(catchError(this.handleError));
    } else if (action == "delete") {
      return this.http
        .delete(restURL, options)
        .pipe(catchError(this.handleError));
    } else {
      return null;
    }
  }

  public createColumnDefinitions(columns) {
    let getTimezones = this.resolveTimeZone();

    let columnsOut = columns.map(column => ({
      headerName: column.headerName,
      field: column.field !== undefined ? column.field : null,
      filter: column.filter !== undefined ? column.filter : false,
      floatingFilter: false,
      filterParams: column.filterParams !== undefined ? column.filterParams : {
        comparator: this.dateFilterComparator,
      },
      cellStyle: column.cellStyle !== undefined ? column.cellStyle : {},
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (column.type === "number" || column.type === "percentage" || column.type === "time") {
          return valueA - valueB;
        } else if (column.type === "text") {
          if (valueA.toLowerCase() == valueB.toLowerCase()) return 0;
          return (valueA.toLowerCase() > valueB.toLowerCase()) ? 1 : -1;
        } else if (column.type === "date") {
          if (valueA === null || valueB === null) return 0;

          let unixA = DateTime.fromFormat(valueA.replace(getTimezones.tz, getTimezones.tz_iana), 'EEE, dd LLL yyyy HH:mm:ss z', { setZone: true }).toMillis();
          let unixB = DateTime.fromFormat(valueB.replace(getTimezones.tz, getTimezones.tz_iana), 'EEE, dd LLL yyyy HH:mm:ss z', { setZone: true }).toMillis();

          //compare numerical values for sorting
          return unixA - unixB;
        } else if (column.type === "boolean") {
          return valueA - valueB;
        } else {
          return valueA.localeCompare(valueB);
        }
      },
      valueFormatter: (params) => {
        if (column.type === "time") {
          //convert seconds to a string in the form hh hours mm minutes, rounded to the closest minute
          let durationFormatted = this.convertDurationToTime(params.value);

          return `${durationFormatted}`;
        } else if (column.type === "date") {
          if (params.value.toLowerCase().includes("progress")) return params.value;
          if (params.value === null) return "N/A";
          return this.timeFormatter(params.value, "LLL d yyyy h:mm a");
        } else if (column.type === "percentage") {
          return params.value === null ? "0%" : `${Math.round(params.value * 100)}%`;
        } else if (column.type === "boolean") {
          return params.value === true ? "Yes" : "No";
        } else {
          return params.value;
        }
      },
      cellRenderer: column.cellRenderer !== undefined ? column.cellRenderer : null,
      cellRendererParams: this.processCellRendererParams(column),
      wrapText: column.wrapText !== undefined ? column.wrapText : false,
      autoHeight: column.wrapText !== undefined ? column.wrapText : false,
    }));

    console.log("columnsOut", columnsOut);

    return columnsOut;
  }

  /**
 * Compares two dates for filtering, normalizing to GMT and comparing only the date part
 * @param filterValue The filter value from the filter component
 * @param cellValue The value in the cell being compared
 * @returns true if the dates match (ignoring time), false otherwise
 */
  dateFilterComparator(filterValue: any, cellValue: any): number {

    console.log("filterValue", filterValue);
    console.log("cellValue", cellValue);

    // Handle null/undefined values
    if (!filterValue || !cellValue) {
      return 0;
    }

    try {
      // Convert both values to moment objects if they aren't already
      const momentFilter = moment.isMoment(filterValue) ? filterValue : moment(filterValue);
      const momentCell = moment.isMoment(cellValue) ? cellValue : moment(cellValue);

      // Ensure both are valid dates
      if (!momentFilter.isValid() || !momentCell.isValid()) {
        return 0;
      }

      // Normalize to UTC and strip time components
      const filterDateOnly = momentFilter.clone().utc().startOf('day');
      const cellDateOnly = momentCell.clone().utc().startOf('day');

      // Compare only the date parts
      if (filterDateOnly.isSame(cellDateOnly)) {
        return 0;
      } if (filterDateOnly.isBefore(cellDateOnly)) {
        return 1;
      } else {
        return -1;
      }
    } catch (error) {
      console.error('Error comparing dates:', error);
      return 0;
    }
  }

  private processCellRendererParams(column) {
    console.log("column in processCellRendererParams", column);
    let cellRendererParams = {};

    switch (column.type) {
      case "icons":
        cellRendererParams = {
          icons: column.icons,
          onClick: (event) => this.iconClicked(event, column)
        }
        break;
    }

    return cellRendererParams;
  }

  private iconClicked(e, column) {
    console.log("icon clicked", e);
    console.log("column in iconClicked", column);
    column.callback(e, column);
  }

  public timeFormatter(time, format = "EEE, dd LLL yyyy h:mm a z") {
    let getTimezones = this.resolveTimeZone();

    // Convert the formatted time to a DateTime object using luxon
    let dt = DateTime.fromFormat(time.replace(getTimezones.tz, getTimezones.tz_iana), 'EEE, dd LLL yyyy HH:mm:ss z', { setZone: true }).setZone(getTimezones.tz_iana).toFormat(format);

    // Replace the IANA timezone with the original timezone
    dt = dt.replace(getTimezones.tz_iana, getTimezones.tz);

    return dt;
  }

  public convertDurationToTime(duration) {
    //convert seconds to a string in the form hh hours mm minutes, rounded to the closest minute
    let hours = Math.floor(duration / 3600).toString().padStart(2, '0');
    let minutes = Math.round((duration % 3600) / 60).toString().padStart(2, '0');
    let seconds = Math.round(duration % 60).toString().padStart(2, '0');

    let durationFormatted = `${hours}:${minutes}:${seconds}`;

    return durationFormatted;
  }

  private handleError(error: Response | any) {
    console.error("ApiService::handleError", error);
    return throwError(error);
  }

  public resolveTimeZone() {
    let tz_iana = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let tz = moment.tz(tz_iana).format("z");

    //if the string includes a negative number in the form -03 or -02, check for a timezone fix
    if (tz.includes("-")) {
      let fix = timezoneFixes[tz_iana];

      if (fix !== undefined) {
        tz = fix;
      } else {
        //fall back to UTC from Luxon
        tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
      }
    } else if (tz.includes("+")) {
      //fall back to UTC from Luxon
      //@todo: update timezone fixes to include plus returns
      tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
    }

    return {
      tz_iana: tz_iana,
      tz: tz,
    }
  }

  public retrieveQueryParams(params) {
    const legacyState = params.get('legacy_state') !== undefined ? params.get('legacy_state') : "false";

    const is_teacher = params.get('is_teacher') === null ? true : params.get('is_teacher') === "true" ? true : false;
    const userType = is_teacher === true ? "teacher" : "student";
    const start = params.get('start') !== null ? moment(params.get('start')).format("YYYY-MM-DD") : moment().subtract(7, 'days').format("YYYY-MM-DD");
    const end = params.get('end') !== null ? moment(params.get('end')).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
    const table_type = params.get('table_type') !== null ? params.get('table_type') : "skills";
    const interval = params.get('interval') !== null ? params.get('interval') : "day";
    const ai_model = params.get('ai_model') !== null ? params.get('ai_model') : "gpt-4o";

    return {
      legacyState: legacyState,
      userType: userType,
      is_teacher: is_teacher,
      start: start,
      end: end,
      table_type: table_type,
      interval: interval,
      ai_model: ai_model
    }
  }

  public async setupDateChart(chart, model, timeSelector, timeDenomination, chartRef, chartData, labels): Promise<any> {
    if (chart) {
      chart.destroy();
    }

    let dataOut = {
      chart: chart,
      start: model.start,
      end: model.end,
      breakdownType: timeSelector.value,
      chartLabelDC: "",
      chartLabelsDC: [],
      dateAggregateTitle: "",
      dateAggregates: [],
      chartData: chartData,
      chartOptionsDC: {},
      chartCreated: false,
      timeDenomination: timeDenomination,
      timeSelector: timeSelector,
    }

    let start = model.start;
    let end = model.end;

    if (start === "" || end === "") return false;

    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end).format("YYYY-MM-DD");
    let tmz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let checkDateRange = this.checkDateRange({}, "initiated", timeDenomination, timeSelector, moment(start), moment(end));

    dataOut.timeDenomination = checkDateRange.timeDenomination;
    dataOut.timeSelector = checkDateRange.timeSelector;

    dataOut.start = startDate;
    dataOut.end = endDate;

    console.log("dataOut.start", dataOut.start);
    console.log("dataOut.end", dataOut.end);

    try {

      const retrieveMetricsData =
        await this.getMetricsOverTimeFrame(
          this.teamID,
          timeSelector.value,
          startDate,
          endDate,
          tmz,
          true
        ).toPromise();

      let graph = retrieveMetricsData.Graph;
      let timezone_offset = retrieveMetricsData.timezone_offset;
      let self = this;

      dataOut.chartLabelDC = this.processChartLabel(graph.graphTitle, labels);
      dataOut.chartLabelsDC = graph.labelData;

      console.log("graph.Data", graph.Data);
      console.log("chartLabelsDC", dataOut.chartLabelsDC);

      dataOut.dateAggregateTitle = retrieveMetricsData.Title;

      let aggregates = [];
      Object.keys(retrieveMetricsData.Aggregates[0]).forEach(function (key) {
        let thisAggregate = {
          label: key,
          value: retrieveMetricsData.Aggregates[0][key],
        };

        aggregates.push(thisAggregate);
      });

      dataOut.dateAggregates = aggregates;

      let xmin = moment(`${startDate} 00:00 AM`).format("X");
      let xmax = moment(`${endDate} 11:59 PM`).format("X");

      //generate labels from start to end date
      let currentDate = moment(startDate);

      // if (this.timeSelector.value === "year") {
      //   currentDate = moment(startDate).add(7, "months").add(14, "days");
      // }

      let endDateMoment = moment(endDate);

      while (currentDate.isSameOrBefore(endDateMoment)) {

        //chart labels are in this format: 2024-12-02T00:00:00-05:00
        //if there are any chart labels missing between the start and end date, add them
        let label = currentDate.format("YYYY-MM-DDT00:00:00" + timezone_offset);

        console.log("label", label);

        if (!dataOut.chartLabelsDC.includes(label)) {
          dataOut.chartLabelsDC.push(label);
        }

        //increment the date
        currentDate = currentDate.add(1, timeSelector.value);

      }

      console.log("xmin pre selected", xmin);
      console.log("xmax pre selected", xmax);

      if (timeSelector.value === "month") {
        xmin = moment(startDate).startOf('month').format("YYYY-MM-DD");
        xmax = moment(endDate).endOf('month').format("YYYY-MM-DD");
      } else if (timeSelector.value === "year") {
        xmin = moment(startDate).format("YYYY-01-01");
        xmax = moment(endDate).format("YYYY-12-31");
      }

      console.log("xmin", xmin);
      console.log("xmax", xmax);

      let unit = timeSelector.value === "hour" ? "minute" : timeSelector.value;

      console.log("unit", unit);

      let defaultYLabels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      let ymax = graph.Data.length ? Math.max(...graph.Data) : Math.max(...defaultYLabels);
      let yLabels = [...Array(ymax).keys()].push(ymax);

      dataOut.chartOptionsDC = {
        indexAxis: "x",
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: "rgba(29; 61; 124; 0.65)",
        plugins: {
          // zoom: zoomOptions,
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: function (context) {
                let format = "LLL d, yyyy";

                if (timeSelector.value === "hour") {
                  format = "LLL d, yyyy h:mm a";
                } else if (timeSelector.value === "month") {
                  format = "LLL yyyy";
                } else if (timeSelector.value === "year") {
                  format = "yyyy";
                }

                let date = DateTime.fromMillis(context[0].parsed.x, { zone: IANAZone.create(tmz) });

                let label = date.toFormat(format);
                return label;
              },
              label: function (context) {
                return `${context.formattedValue} sessions`;
              }
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Number of Sessions",
            },
            min: 0,
            max: ymax + 1,
            ticks: {
              precision: 0,
              stepSize: 1,
              callback: function (value) {
                if (Math.floor(value) === value) {
                  return value;
                }
              }
            }
          },
          x: {
            title: {
              display: true,
              text: "Date",
            },
            type: 'time',
            bounds: 'ticks',
            min: xmin,
            max: xmax,
            time: {
              parser: moment.parseZone,
              tooltipFormat: 'll',
              unit: unit
            },
            ticks: {
              callback: function (value, index, values) {
                let date = DateTime.fromMillis(values[index].value, { zone: IANAZone.create(tmz) });

                if (date.isValid) {

                  let format = "LLL d, yyyy";

                  if (timeSelector.value === "hour") {
                    format = "LLL d, yyyy h:mm a";
                  } else if (timeSelector.value === "month") {
                    format = "LLL yyyy";
                  } else if (timeSelector.value === "year") {
                    format = "yyyy";
                  }

                  return date.toFormat(format);
                } else {

                  return value;
                }
              }
            }
          },
        },
        interaction: {
          mode: "point",
        },
        //onClick: function adjustLineEndPoints(context) {  }
      };

      let data = {
        labels: dataOut.chartLabelsDC,
        datasets: [
          {
            data: graph.Data,
            borderColor: "#483c46",
            maxBarThickness: 50,
            minBarLength: 10,
            backgroundColor: [
              'rgba(0, 35, 56, 0.42)',
              'rgba(72, 60, 70, 0.42)',
              'rgba(75, 45, 27, 0.42)',
              'rgba(4, 201, 209, 0.42)',
              'rgba(38, 131, 140, 0.42)',
              'rgba(190, 124, 77, 0.42)',
              'rgba(160, 142, 106, 0.42)'
            ],
            label: "",
          },
        ],
        yLabels: yLabels,
      };

      if (timeSelector.value === "hour") {
        data.datasets[0]["barThickness"] = 3;
      }

      console.log("data for chart", data);

      dataOut.chartData = data;
      //first chart initialization is in ngAfterViewChecked

      if (dataOut.chartCreated) {
        dataOut.chart = new Chart(chartRef.nativeElement, {
          type: "bar",
          data: dataOut.chartData,
          options: dataOut.chartOptionsDC,
        });
      }

      return dataOut;

    } catch (error) {

      //return error
      return error;

    }
  }

  public async retrieveAnalticsSessions(model, timeSelector): Promise<any> {

    let dataOut = {
      sessions: [],
      allSessions: [],
      originalSessions: [],
    }

    try {
      const organizeSessions = await this.organizeSessions(model, timeSelector);

      if (organizeSessions) {
        dataOut.sessions = organizeSessions.sessions;
        dataOut.allSessions = organizeSessions.allSessions;
        dataOut.originalSessions = organizeSessions.originalSessions;
      }

      return dataOut;
    } catch (error) {
      return error;
    }
  }

  private processChartLabel(labelIn, labels) {
    labelIn = labelIn.replace(
      "Meeting",
      this.titleCasePipe.transform(labels.event.singular)
    );

    labelIn = labelIn.replace("meeting", labels.event.singular);

    return labelIn;
  }

  public checkDateRange(event, dateField, timeDenomination, timeSelector, model, startDate?, endDate?) {

    console.log("event in checkDateRange", event);

    if (dateField === 'start') {
      startDate = moment(event.actualDateFormatted);
      if (endDate === undefined) endDate = moment(event.end);
    } else {
      endDate = moment(event.actualDateFormatted);
      if (startDate === undefined) startDate = moment(event.start);
    }

    //see if diff between dates is greater than 7 days
    let diff = endDate.diff(startDate, "days");

    console.log("timeDenomination in checkDateRange", timeDenomination);

    //if greater than 7 days, set "hour" option in timeSelector to disabled
    timeDenomination = timeDenomination.map((item) => {
      if (diff > 7 && item.value === "hour") {
        item["disabled"] = true;
      } else {
        item["disabled"] = false;
      }

      return item;
    });

    //if the timeSelector is set to "hour" and the diff is greater than 7 days, set the timeSelector to "day"
    if (timeSelector.value === "hour" && diff > 7) {
      timeSelector = new UntypedFormControl("day");
    }

    return {
      timeDenomination: timeDenomination,
      timeSelector: timeSelector,
    }
  }

  public async organizeSessions(model, timeSelector) {

    let dataOut = {
      sessions: [],
      allSessions: [],
      originalSessions: [],
    }

    let tmz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let start = model.start;
    let end = model.end;

    if (start === "" || end === "") return false;

    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end).format("YYYY-MM-DD");

    try {
      const retrieveMetricsData = await this.getSessions(
        this.teamID,
        timeSelector.value,
        startDate,
        endDate,
        tmz,
        true
      ).toPromise();

      if (retrieveMetricsData?.sessions) {

        dataOut.sessions = retrieveMetricsData.sessions;
        dataOut.sessions = dataOut.sessions.sort((a, b) => {
          return b.session_id - a.session_id;
        });
        console.log("this.sessions in getSessions", dataOut.sessions);
        //deep copy of all sessions to allSessions
        dataOut.allSessions = [...dataOut.sessions];
        dataOut.originalSessions = [...dataOut.sessions];
      }
    } catch (error) {
      console.error("Error in getSessions:", error);
    }

    return dataOut
  }

}
