import { MetricsServicesService } from 'src/app/modules/metrics/services/metrics-services.service';
import { XrPlatformRestService } from '../../../../../services/rest/xr-platform/xr-platform-rest.service';
import { Component } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
  INumberFilterParams,
  ITextFilterParams,
  SizeColumnsToFitGridStrategy,
} from "ag-grid-community";

import { SizeColumnsToFitProvidedWidthStrategy } from 'ag-grid-community';
import { SizeColumnsToContentStrategy } from 'ag-grid-community';

import { combineLatest } from 'rxjs';

import { environment } from "src/environments/environment";

import * as moment from "moment";

import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { CoolLocalStorage } from '@angular-cool/storage';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/services/utilities/settings.service';

@Component({
  selector: 'app-metrics-aia-assessment',
  templateUrl: './metrics-aia-assessment.component.html',
  styleUrls: ['./metrics-aia-assessment.component.scss']
})
export class MetricsAiaAssessmentComponent {
  //incoming via param
  public recordingID = 0;
  public aiExperienceID: number = 0;
  public sessionID: number = 0;
  public targetType: string = "trainee";
  public aiModel: string = "gpt-4o";

  //query params
  public queryParams = {
    ai_model: "gpt-4o",
    interval: "",
    start: "",
    end: "",
  };

  //nav related
  public backRoute: string[];
  public clientCode: string = "";

  //API related
  public targetSessionURL: string = "";
  public token: string = "";

  //transcript file storage
  public transcriptFile: string = "";

  //icons
  public faTimes = faTimes;

  //display
  public eventID: number;
  public event: any;
  public eventInfo: string = "";
  public userID: number;
  public user: any;
  public userInfo: string = "";
  public showUserInformation: boolean = false;
  private clientSettings: any;
  public teamID: number = 0;

  //visibility
  public showDropdown: boolean = true;
  public gridLoading: boolean = true;
  public errorRetrievingMsg: string = "";
  public noMenus: boolean = false; //used to hide menus if token is present in query param

  //proxying - temporary until we can resolve CORS on azure server
  private gridApis: { [key: string]: GridApi } = {};
  private gridNames = {
    'assessment': 'Using Assessment Criteria',
    'rubric': 'Using Rubric Criteria'
  }
  private targetGrid: string = 'assessment';

  //grid config
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public rubricColumnDefs: (ColDef | ColGroupDef)[] = [];
  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
      type: "fitGridWidth",
    };
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 220,
    cellStyle: { 'display': 'flex', 'align-items': 'center', 'padding': '12px' }
  };
  public rowData!: any[];
  public rubricRowData!: any[];
  public themeClass: string =
    "ag-theme-quartz-dark";

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _metricsServicesService: MetricsServicesService,
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute,
    private _settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.buildColumnDefs();
    this.retrieveToken();
    this.retrieveTeamID();
    this.retrieveClientCode();

    // Listen for route changes
    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([params, queryParams]) => {
      this.sessionID = +params.get('session_id');
      this.targetType = params.get('assessment_target');

      if (queryParams.get("token")) {
        this.noMenus = true; //used to hide menus if token is present in query param
      }

      this.clientSettings = this._settingsService.getSettingsFromStorage(
        this.teamID
      );

      this.showUserInformation = this.clientSettings.metricsUsername.unconstrained_default;

      this.targetSessionURL = `foretell/display/aia/session/${this.sessionID}`;

      this.aiExperienceID = +queryParams.get('ai_exp_id');
      this.recordingID = +queryParams.get('recording_id');
      this.aiModel = queryParams.get('ai_model');

      const retrievedParams = this._metricsServicesService.retrieveQueryParams(queryParams);

      this.queryParams.start = retrievedParams.start;
      this.queryParams.end = retrievedParams.end;
      this.queryParams.interval = retrievedParams.interval;
      this.queryParams.ai_model = retrievedParams.ai_model;

      this.backRoute = [
        '/client',
        this.clientCode,
        'analytics',
        'aia',
        'session',
        this.sessionID.toString(),
      ];


      console.log("this.aiExperienceID", this.aiExperienceID);

      this.retrieveData();
    });
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }


  private async retrieveData() {


    try {
      let sessionData = await this.retrieveSessionData();

      console.log("session data in assessment", sessionData);

      this.event = sessionData.aia_session_data.event;
      this.eventInfo = this.event.event_name;
      this.user = sessionData.aia_session_data.user;
      this.userInfo = this.showUserInformation ? `${this.user.username}` : `User ID: ${this.user.id}`;

      let thisRecording = sessionData.aia_session_data.aia_sessions.find((recording: any) => recording.id === this.recordingID);

      console.log("thisRecording data in assessment", thisRecording);

      this.transcriptFile = thisRecording.generated_media_types.transcript_json[0].transcript_json;

      console.log("this.transcriptFile in assessment", this.transcriptFile);

      let assessmentData = await this.retrieveAssessmentData();

      // let assessmentData = this.coolLocalStorage.getObject(`assessmentData${this.targetType}`);

      // if (!assessmentData) 

      // this.coolLocalStorage.setObject(`assessmentData${this.targetType}`, assessmentData);

      // console.log("assessment data", assessmentData);

      let results = assessmentData["assessmentCriteriaAnalysis"].results;
      let rubricResults = assessmentData["rubricAnalysis"];

      //results is an object; iterate over the keys to get the data
      let data = [];

      for (let key in results) {
        let item = results[key];

        let thisItem = {
          criteria: key,
          description: item.description,
          rationale: item.rationale,
          assessment: item.assessment,
          score: item.score
        }

        data.push(thisItem);
      }
      this.rowData = data;

      data = []

      for (let key in rubricResults) {

        let item = rubricResults[key];

        let assessmentBlock = item.assessment;

        //need to split content by \n\n
        let content = assessmentBlock.split("\n\n");

        let thisItem = {
          criteria: item.title,
          description: content[0],
          assessment: content[1],
          rationale: content[2],
        }

        data.push(thisItem);

      }

      console.log("this.rubricRowData", data);

      this.rubricRowData = data;
      this.gridLoading = false;
    } catch (error) {
      console.error("Error retrieving data", error);
      this.errorRetrievingMsg = "Error retrieving data. Please try a refresh - if that does not work, please reach out to support.";
    }

  }

  private retrieveSessionData() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let data = {}

    return this._metricsServicesService.restfulForetellAPIRequest(this.targetSessionURL, "get", data, getOptions, true).toPromise();

  }

  private retrieveAssessmentData() {
    const headers = {
      "Content-Type": "application/json",
    };

    const getOptions = {
      headers: headers,
    };

    let data = {
      "aiModel": this.aiModel,
      "analysisTarget": this.targetType,
      "aiExperienceId": this.aiExperienceID,
      "transcriptJson": this.transcriptFile
    }

    let getAssessment = this._xrPlatformRestService.restfulAPIQueryThirdParty(
      `https://ai-api.foretellreality.com/api/ai/analytics/compound_analysis_for_experience`,
      "post",
      data,
      getOptions
    );

    return getAssessment.toPromise();
  }

  private buildColumnDefs() {
    let columns = [
      {
        headerName: 'Criteria', field: 'criteria', type: 'text', wrapText: true, cellStyle: { 'line-height': '24px' }, filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams, floatingFilter: false
      },
      {
        headerName: 'Description', field: 'description', type: 'text', cellStyle: { 'line-height': '24px' }, wrapText: true, floatingFilter: false
      },
      {
        headerName: 'Rationale', field: 'rationale', type: 'text', wrapText: true, cellStyle: { 'line-height': '24px' }, floatingFilter: false
      },
      {
        headerName: 'Assessment', field: 'assessment', type: 'text', filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams, floatingFilter: false
      }
    ]

    this.columnDefs = this._metricsServicesService.createColumnDefinitions(columns);

    let rubricColumns = [
      {
        headerName: 'Criteria', field: 'criteria', type: 'text', wrapText: true, cellStyle: { 'line-height': '24px' }, filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams, floatingFilter: false
      },
      {
        headerName: 'Description', field: 'description', type: 'text', wrapText: true, cellStyle: { 'line-height': '24px' }, floatingFilter: false
      },
      {
        headerName: 'Assessment', field: 'assessment', type: 'text', filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams, floatingFilter: false
      },
      {
        headerName: 'Rationale', field: 'rationale', type: 'text', wrapText: true, cellStyle: { 'line-height': '24px' }, floatingFilter: false
      }
    ]

    this.rubricColumnDefs = this._metricsServicesService.createColumnDefinitions(rubricColumns);
  }

  public tabSelected(event, targetGrid) {
    this.targetGrid = targetGrid;
  }

  public onDownloadCSV() {
    this.gridApis[this.targetGrid].exportDataAsCsv(this.getParams());
  }

  public onGridReady(params: GridReadyEvent, targetGrid) {
    this.gridApis[targetGrid] = params.api;
  }

  private getParams() {

    let assessmentTitle = this.gridNames[this.targetGrid];

    return {
      fileName: `AI_Assessment_${assessmentTitle}_${this.event.event_name}_for_${this.userInfo}_using_model_${this.aiModel}_pulled_${moment().format("YYYY-MM-DD")}.csv`
    };
  }

}
