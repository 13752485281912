<div class="interactive-analytics-card">
  <div class="row justify-content-start flex-row metrics-controls">
    <div
      class="col-9 offset-3 d-flex flex-nowrap justify-content-end align-items-center"
    >
      <div class="md-form start-picker">
        <mdb-date-picker
          [inline]="true"
          name="start"
          [options]="myDatePickerOptions"
          [placeholder]="'Selected ' + labels.startTime.singular"
          [(ngModel)]="model.start"
          id="start-date"
          onkeydown="return false"
          [label]="startDateLabel"
          (dateChanged)="checkDateRange($event, 'start')"
        ></mdb-date-picker>
        <!-- <label [class.active]="start" for="start">Start Date</label> -->
      </div>
      <div class="md-form end-picker">
        <mdb-date-picker
          [inline]="true"
          name="end"
          [options]="endDateoickerOptions"
          [placeholder]="'Selected ' + labels.endTime.singular"
          [(ngModel)]="model.end"
          id="end-date"
          onkeydown="return false"
          [label]="endDateLable"
          (dateChanged)="checkDateRange($event, 'end')"
        ></mdb-date-picker>
      </div>
      <div class="md-form denomination">
        <mdb-select
          name="time-denomination"
          [options]="timeDenomination"
          [formControl]="timeSelector"
        >
        </mdb-select>
        <label for="time-denomination">Time Interval</label>
      </div>
      <button
        class="theme-modal-button btn update-btn"
        data-dismiss="modal"
        type="button"
        mdbBtn
        mdbWavesEffect
        (click)="setupDateChart()"
      >
        Update Chart
      </button>
    </div>
  </div>
  <div class="row d-flex flex-nowrap">
    <div
      class="col-3 d-flex justify-content-center flex-row metrics-aggregates"
    >
      <div class="aggregate-container w-100">
        <h3 class="text-center">Aggregate Values</h3>
        <ng-container *ngIf="metricsLoadingDC">
          <div
            class="chart-container flex-wrap justify-content-center d-flex align-content-center"
          >
            <div
              class="chart-container flex-wrap justify-content-center d-flex align-content-center"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </ng-container>
        <table *ngIf="!metricsLoadingDC" mdbTable striped="true">
          <tbody>
            <tr *ngFor="let aggregate of dateAggregates">
              <td class="aggregate-value semi-bold">{{ aggregate.label }}</td>
              <td class="aggregate-label">{{ aggregate.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-9">
      <h3 class="text-center">Meeting Sessions Over Time</h3>
      <ng-container *ngIf="metricsLoadingDC">
        <div
          class="chart-container flex-wrap justify-content-center d-flex align-content-center"
        >
          <div
            class="flex-wrap justify-content-center d-flex align-content-center"
          >
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </ng-container>
      <div
        *ngIf="!metricsLoadingDC"
        class="chart-container"
        style="position: relative; height: 42vh; width: auto"
      >
        <canvas #sessionsChart> </canvas>
      </div>
    </div>
  </div>
  <div class="interactive-meetings-card content-card">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <h4 class="text-center meeting-header">Meeting Sessions</h4>
        <button
          class="theme-modal-button btn update-btn download-csv-btn"
          data-dismiss="modal"
          type="button"
          mdbBtn
          mdbWavesEffect
          (click)="onDownloadCSV()"
        >
          Download CSV
        </button>
      </div>
    </div>
    <ng-container *ngIf="!sessionsLoaded">
      <div class="loading-screen d-flex align-content-center">
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="sessionsLoaded">
      <div class="readquest-analytics-highlevel analytics-card">
        <ag-grid-angular
          style="width: 100%; height: 100%"
          [columnDefs]="columnDefs"
          [autoSizeStrategy]="autoSizeStrategy"
          [defaultColDef]="defaultColDef"
          [rowData]="rowData"
          [class]="themeClass"
          (cellClicked)="onCellClicked($event)"
          (gridReady)="onGridReady($event)"
        ></ag-grid-angular>
      </div>
    </div>
  </div>
</div>
