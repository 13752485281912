import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  ElementRef,
  HostListener,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";

import {
  faCirclePlus,
  faSquareMinus,
  faUsers,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { TitleCasePipe } from "@angular/common";

@Component({
  selector: "app-manage-media-sharing",
  templateUrl: "./manage-media-sharing.component.html",
  styleUrls: ["./manage-media-sharing.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TitleCasePipe],
})
export class ManageMediaSharingComponent implements OnInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild("row", { static: true }) row: ElementRef;

  //fonts
  faCirclePlus = faCirclePlus;
  faSquareMinus = faSquareMinus;
  faUsers = faUsers;
  faSquarePlus = faSquarePlus;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    public shareModal: MDBModalRef,
    private route: ActivatedRoute,
    private _xrPlatformRestService: XrPlatformRestService,
    private _MediaManagementServicesService: MediaManagementServicesService,
    private _notificationService: NotificationsService,
    private TitleCase: TitleCasePipe
  ) { }

  //incoming
  public action: string = "update";
  public teamID: number;
  public labels: any;

  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";
  public placeHolderWebp: string = "assets/img/webp-thumbnail.jpeg";
  public placeHolderPdf: string = "assets/img/pdf-thumbnail.jpg";
  public placeHolderMp4: string = "assets/img/mp4-thumbnail.jpg";
  public user: any;
  public media_item: any;
  searchText = new Subject();
  results: Observable<string[]>;

  //tracking
  public isClean: boolean = true;
  public processing: boolean = false;

  //persistent
  public SearchText: string;
  public usersLoading: boolean = true;
  public rosterLoading: boolean = true;

  //userdata
  public userList: any;
  public origUserList: any;
  public unchanagedUserList: any;
  public inviteeUsers: any = [];
  public origInviteeUsers: any = [];
  public addedUsers: any = [];
  public removedUsers: any = [];

  public shared_usernames: string[] = [];
  public usernames_autocomplete: string[] = [];
  public users_autocompete: any;
  public sharelevel: string = "";
  public filteredShares: any;

  public sharelevelCreator: UntypedFormGroup;

  public usernameselector: string = "";
  //public sharelevelselector: string = 'Private';

  sharelevelselector = new UntypedFormControl("private");
  private share_level = "public";

  public token: string;

  public sharelevels = [
    { value: "private", label: "Private" },
    { value: "public", label: "Public" },
  ];

  public headElements = ["User id", "Share Level", "Shared On", "Unshare"];
  public sort_Elements = ["user_id", "share_level", "effective_start_date", ""];

  private outgoing: Subject<any> = new Subject();

  ngOnInit(): void {
    console.log("this.media_item", this.media_item);

    this.token = this._MediaManagementServicesService.retrieveToken();
    this.sharelevelCreator = new UntypedFormGroup({
      usernameselector: new UntypedFormControl(),
    });

    this.retrieveData();
  }

  private async retrieveData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    //retrieve users
    let users = await this.retreiveUsers(getOptions);

    this.processUsers(users);

    this.usersLoading = false;
    this.rosterLoading = false;

    this.buildPreselected();
  }

  /**
   * Retrieve Team Users
   * @param options
   * @returns
   */
  private retreiveUsers(options) {
    let retrieverUsers = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + this.teamID + "/users",
      "get",
      {},
      options
    );

    return retrieverUsers.toPromise();
  }

  private processUsers(users) {

    console.log("this.media_item", this.media_item);

    //get current shares from media item
    if (this.media_item.shared_with.length) {

      this.media_item.shared_with.forEach(share => {

        if (share.user_id === this.media_item.current_owner) return;

        let thisUser = users.filter(user => user.id === share.user_id);

        if (!thisUser.length) return;

        thisUser = thisUser[0];

        this.origInviteeUsers.push(thisUser);
        this.inviteeUsers.push(thisUser);

      });

    }

    users = users.filter((user) => user.id !== this.media_item.current_owner);

    users = users.sort((a, b) =>
      a.first_name > b.first_name ? 1 : b.first_name > a.first_name ? -1 : 0
    );

    this.origUserList = JSON.parse(JSON.stringify(users));
    this.unchanagedUserList = JSON.parse(JSON.stringify(users));
    this.userList = users;
  }

  buildPreselected() {
    this.inviteeUsers.forEach((user) => {

      console.log("user.id", user.id);
      console.log("this.media_item.current_owner", this.media_item.current_owner);
      console.log("this.user.id", this.user.id);

      user.disabled = user.id !== this.media_item.current_owner && this.media_item.current_owner !== this.user.id;
      this.addedUsers.push(user);
    });
  }

  search(): void {
    const searchKey = this.SearchText.toLowerCase();
    this.userList = this.origUserList.filter(function (tag) {
      return (
        tag.first_name.toLowerCase().indexOf(searchKey) >= 0 ||
        tag.last_name.toLowerCase().indexOf(searchKey) >= 0 ||
        tag.username.toLowerCase().indexOf(searchKey) >= 0 ||
        (
          tag.first_name.toLowerCase() +
          " " +
          tag.last_name.toLowerCase()
        ).indexOf(searchKey) >= 0
      );
    });
  }

  public addUser(firstName, lastName, id, username) {
    const isUserAlreadyAdded =
      this.addedUsers.filter((item) => item.id === id).length > 0;
    if (!isUserAlreadyAdded) {
      this.userList = this.userList.filter((item) => item.id !== id);
      this.origUserList = this.origUserList.filter((item) => item.id !== id);
      this.addedUsers.push({
        first_name: firstName,
        last_name: lastName,
        username: username,
        id: id,
      });

      //when in update mode, make sure this added user is not on the remove list
      //i.e. if a user removes a user, then decides to add them back in again
      this.removedUsers = this.removedUsers.filter((removed_id) => {
        return removed_id !== id;
      });

      this.isClean = this.checkForRosterChanges(
        this.origInviteeUsers,
        this.addedUsers
      );
    }
  }

  public removeAddedUser(id) {
    this.addedUsers.forEach((element, index) => {
      if (element.id == id) {
        this.addedUsers.splice(index, 1);
      }
    });
    const removedItem = this.unchanagedUserList.filter(
      (item) => item.id && item.id == id
    );

    //if this is an update action, add removed user to the removedUsers array
    this.removedUsers.push(removedItem[0]);

    this.isClean = this.checkForRosterChanges(
      this.origInviteeUsers,
      this.addedUsers
    );

    this.userList.push(removedItem[0]);
    this.userList.sort((a, b) =>
      a.first_name > b.first_name ? 1 : b.first_name > a.first_name ? -1 : 0
    );
    this.origUserList.push(removedItem[0]);
    this.origUserList.sort((a, b) =>
      a.first_name > b.first_name ? 1 : b.first_name > a.first_name ? -1 : 0
    );
  }

  /**
   * For each item in origList, see if the property other_id matches the id property in any item in currentList
   * Also do the vice versa to check for new adds
   * If it does not, then there are changes
   * @param origList
   * @param currentList
   */
  private checkForRosterChanges(origList, currentList) {
    let isClean = true;

    origList.forEach((element) => {
      let isFound = currentList.filter((item) => item.id === element.other_id);
      if (isFound.length === 0) isClean = false;
    });

    currentList.forEach((element) => {
      let isFound = origList.filter((item) => item.other_id === element.id);
      if (isFound.length === 0) isClean = false;
    });

    return isClean;
  }

  public async updateSharing() {
    let members = this.addedUsers;
    let newMembers = [];
    let removedMembers = this.removedUsers;

    //add toast notifications
    let actionAdj = this.action.replace(/e$/, "");
    let notification = this._notificationService.savingNotification(
      `${this.TitleCase.transform(actionAdj)}ing ${this.labels.userGroup.singular
      }`
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    //user original members to determine if a user is being added or removed
    members.forEach((member) => {
      let isFound = this.origInviteeUsers.filter(
        (origMember) => origMember.id === member.id
      );
      if (isFound.length === 0) {
        newMembers.push(member);
      }

      //remove member from removedMembers list
      removedMembers = removedMembers.filter(
        (removedMember) => removedMember.id !== member.id
      );
    });

    //add new members
    if (newMembers.length) {
      let newMemberPromises = newMembers.map((member) => {
        return this.addShare({
          user_id: member.id,
          access: this.share_level,
        }, options);
      });

      await Promise.all(newMemberPromises);
    }

    //remove removed members
    if (removedMembers.length) {
      let removedMemberPromises = removedMembers.map((member) => {
        return this.removeShare({
          user_id: member.id,
          access: this.share_level,
        }, options);
      });

      await Promise.all(removedMemberPromises);
    }

    let shared_width = members.map((member) => {
      return {
        user_id: member.id,
        username: member.username,
        share_level: this.share_level,
        effective_start_date: new Date(),
      }
    });

    let outgoing = {
      action: "updating_sharing",
      shared_with: shared_width,
    }

    this.outgoing.next(outgoing);

    this._notificationService.clearNotification(notification);
    this.closeModal();

  }

  private async addShare(data, options) {

    let body = {
      access: data.access,
    }

    return this._xrPlatformRestService.enableUserShare(body, data.user_id, this.media_item.id, options).toPromise();

  }

  private async removeShare(data, options) {

    let body = {
      access: data.access,
    }

    return this._xrPlatformRestService.disableUserShare(
      body,
      data.user_id,
      this.media_item.id,
      options
    ).toPromise();

  }

  public closeModal() {
    this.shareModal.hide();
  }


}
