<div id="stylingGuide"></div>
<div
  theme
  class="app-container"
  [ngClass]="{ 'no-menus': !loadMenus }"
>
  <app-side-menu
    *ngIf="loadMenus && token && contentLoaded && !loadingIssue"
    [token]="token"
  ></app-side-menu>
  <app-menu
    *ngIf="loadMenus && token && contentLoaded && !loadingIssue"
    [token]="token"
  ></app-menu>
  <!-- content for users / not super admins -->
  <p *ngIf="!contentLoaded && !loadingIssue">Loading content...</p>
  <mdb-card
    *ngIf="contentLoaded && loadingIssue"
    class="mx-auto my-3 w-50 loading-issue"
  >
    <mdb-card-img
      src="/assets/img/GlimpseGroupWhite.png"
      alt="Card image cap"
      class="py-2"
    ></mdb-card-img>
    <mdb-card-body>
      <p>There was an issue loading this page - please <a onclick="location.reload()">refresh the browser window</a>. If this issue persists, please reach
        out to support.</p>
    </mdb-card-body>
  </mdb-card>
  <router-outlet *ngIf="contentLoaded && !loadingIssue"></router-outlet>
</div>
