import { ArchiveVendorEntityComponent } from './vendor-management/modals/archive-vendor-entity-modal/archive-vendor-entity-modal.component';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SiteSettingsComponent } from "./site-settings/site-settings.component";

import { PersistentModule } from "./../persistent/persistent.module";
import { SharedModulesModule } from "./../shared-modules/shared-modules.module";
import { EditSiteSettingsComponent } from "./site-settings/modals/edit-site-settings/edit-site-settings.component";
import { UsersCsvUploadComponent } from "./users-csv-upload/users-csv-upload.component";
import { EntityManagementComponent } from "./entity-management/entity-management.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ViewPersonaPermissionModalComponent } from "./entity-management/modals/view-persona-permission-modal/view-persona-permission-modal.component";
import { AddPersonaPermissionModalComponent } from "./entity-management/modals/add-persona-permission-modal/add-persona-permission-modal.component";
import { ArchiveModalComponent } from "./entity-management/modals/archive-modal/archive-modal.component";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { EditTeamLogoComponent } from "./site-settings/modals/edit-team-logo/edit-team-logo.component";
import { ManageRoomModalComponent } from "./entity-management/modals/manage-room-modal/manage-room-modal.component";
import { ManageExperienceModalComponent } from "./entity-management/modals/manage-experience-modal/manage-experience-modal.component";
import { ManageRoleModalComponent } from "./entity-management/modals/manage-role-modal/manage-role-modal.component";
import { ManagePersonaModalComponent } from "./entity-management/modals/manage-persona-modal/manage-persona-modal.component";
import { ManagePermissionModalComponent } from "./entity-management/modals/manage-permission-modal/manage-permission-modal.component";
import { AddSettingModalComponent } from "./site-settings/modals/add-setting-modal/add-setting-modal.component";
import { ViewSettingModalComponent } from "./site-settings/modals/view-setting-modal/view-setting-modal.component";
import { AddPropOptionModalComponent } from "./site-settings/modals/add-prop-option-modal/add-prop-option-modal.component";
import { AddEventTypeOptionModalComponent } from "./site-settings/modals/add-event-type-option-modal/add-event-type-option-modal.component";
import { VendorManagementComponent } from "./vendor-management/vendor-management.component";
import { ManageSurveyComponent } from "./vendor-management/modals/manage-survey/manage-survey.component";
import { ViewSurveyComponent } from "./vendor-management/modals/view-survey/view-survey.component";
import { AddSurveyQuestionModalComponent } from "./vendor-management/modals/add-survey-question-modal/add-survey-question-modal.component";
import { ManageQuestionModalComponent } from "./vendor-management/modals/manage-question-modal/manage-question-modal.component";
import { ManageQuestionOptionModalComponent } from "./vendor-management/modals/manage-question-option-modal/manage-question-option-modal.component";
import { ViewQuestionModalComponent } from "./vendor-management/modals/view-question-modal/view-question-modal.component";
import { AddQuestionOptionModalComponent } from "./vendor-management/modals/add-question-option-modal/add-question-option-modal.component";
import { ClientListComponent } from './client-list/client-list.component';
import { ManageClientModalComponent } from './client-list/modals/manage-client-modal/manage-client-modal.component';
import { EditClientPasswordModalComponent } from './client-list/modals/edit-client-password-modal/edit-client-password-modal.component';
import { EditClientLogoModalComponent } from './client-list/modals/edit-client-logo-modal/edit-client-logo-modal.component';
import { MediaManagementModule } from '../media-management/media-management.module';
import { SelectPublicEventComponent } from './site-settings/modals/select-public-event/select-public-event.component';
import { AddTeamExperienceModalComponent } from './site-settings/modals/add-team-experience-modal/add-team-experience-modal.component';

@NgModule({
    declarations: [
        SiteSettingsComponent,
        EditSiteSettingsComponent,
        UsersCsvUploadComponent,
        EntityManagementComponent,
        ViewPersonaPermissionModalComponent,
        AddPersonaPermissionModalComponent,
        ArchiveModalComponent,
        ManageRoomModalComponent,
        ManageExperienceModalComponent,
        ManageRoleModalComponent,
        ManagePersonaModalComponent,
        ManagePermissionModalComponent,
        EditTeamLogoComponent,
        AddSettingModalComponent,
        ViewSettingModalComponent,
        AddPropOptionModalComponent,
        AddEventTypeOptionModalComponent,
        VendorManagementComponent,
        ManageSurveyComponent,
        ViewSurveyComponent,
        AddSurveyQuestionModalComponent,
        ManageQuestionModalComponent,
        ManageQuestionOptionModalComponent,
        ViewQuestionModalComponent,
        AddQuestionOptionModalComponent,
        ArchiveVendorEntityComponent,
        ClientListComponent,
        ManageClientModalComponent,
        EditClientPasswordModalComponent,
        EditClientLogoModalComponent,
        SelectPublicEventComponent,
        AddTeamExperienceModalComponent,
    ],
    imports: [
        CommonModule,
        PersistentModule,
        SharedModulesModule,
        ReactiveFormsModule,
        MediaManagementModule,
        MDBBootstrapModulesPro.forRoot(),
    ],
    exports: [SiteSettingsComponent]
})
export class SuperAdminModule { }
