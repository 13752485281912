import {
  Component,
  AfterViewInit,
  OnInit,
  ElementRef,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { TitleCasePipe } from "@angular/common";

import { MDBModalRef, MDBModalService, PopoverDirective } from "ng-uikit-pro-standard";
import { CoolLocalStorage } from "@angular-cool/storage";
import { Subject } from "rxjs";

import { XrPlatformRestService } from "./../../../../../services/rest/xr-platform/xr-platform-rest.service";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { MediaManagementServicesService } from "src/app/modules/media-management/services/media-management-services.service";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { UserServicesService } from "../../../services/user-services.service";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { EditPasswordComponent } from "../edit-password/edit-password.component";

import { faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"],
  providers: [TitleCasePipe],
  encapsulation: ViewEncapsulation.None,
})
export class EditUserComponent implements OnInit, AfterViewInit {
  @ViewChild("popOverTrigger") popOverTrigger: PopoverDirective;
  @ViewChild("popOverTriggerTop") popOverTriggerTop: PopoverDirective;

  //persistent
  public labels: any;
  public token: string;
  public mainModal: any;
  public adminLevel: boolean = false;
  public finalUser: any;
  public clientSettings: any;
  public legacySettings: any;
  public formLoading: boolean = true;

  //icons
  public faTimes = faTimes;

  //incoming
  public teamID: number;
  public targetUser: any;
  public userStatus: string = "generic";
  public action: string;
  public user: any;
  public users: any;
  private domain: string;
  public sourceType: string;
  public expPersonaSelect: { value: string; label: string }[] = [];
  public isClean: boolean = true;

  //feedback response
  public formTitle: string = "Add User";
  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
      errorMsg: "",
      statusMsg: "",
      processingMsg: "",
      actionMsg: "Add User",
    };
  public formState: string = "active";
  public notification: any;
  public showPersona: boolean;

  private modalOptions = {
    backdrop: "static",
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };
  public pwFrame: MDBModalRef;
  public restoreFrame: MDBModalRef;

  //form vars
  public formType: string = "add_user";
  public formIcon: string = "";
  public userForm: UntypedFormGroup;
  public viewOnly: {
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    meta: string;
  };
  public adminCaps: { value: string; label: string }[];
  public expRoleSelect: { value: string; label: string }[];
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
      close: "Close",
      main: "Add",
      reset: "Add Another User",
      retry: "Retry",
    };
  public continueType: string;
  public preSelected: {
    id: any;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    role_type_id: number;
    meta: {
      role: number;
      persona_id: number;
    };
  } = {
      id: null,
      username: null,
      first_name: null,
      last_name: null,
      email: null,
      role_type_id: 2,
      meta: {
        role: 3,
        persona_id: null,
      },
    };
  public confirmOn = false;
  public userRoleDefault: number = 6;

  private selectedUser: {
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    id: number;
    role_type_id: number;
    meta: string;
  } = {
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      id: null,
      role_type_id: 6,
      meta: null,
    };

  public thumbnails: {
    image: string;
    pdf: boolean;
    imageError: boolean;
    imageButton: string;
  } = {
      image: null,
      pdf: false,
      imageError: false,
      imageButton: "Select Image",
    };
  public existing: {
    image: any;
  } = {
      image: null,
    };

  //labels: any;
  selectedFileName: string;
  // placeholder
  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";

  //data back to parent
  private outgoing: Subject<any> = new Subject();

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    public userFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _clientManagementService: ClientManagementService,
    private _mediaManagementService: MediaManagementServicesService,
    private elementRef: ElementRef,
    private _settingsService: SettingsService,
    private _userService: UserServicesService,
    private TitleCase: TitleCasePipe,
    private _notificationService: NotificationsService,
    private modalService: MDBModalService
  ) { }

  ngOnInit() {

    console.log("action in edit user", this.action)
    console.log("targetUser in edit user", this.targetUser)

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );
    this.userRoleDefault = +this.clientSettings.defaultRole;
    this.showPersona = this.clientSettings.showPersona.unconstrained_default;
    console.log("this.showPersona", this.showPersona);
    this.adminCaps = this.formatAdminCaps(
      this.clientSettings.adminCaps.options
    );
    this.expRoleSelect = this.formatRoleSelect(
      this.clientSettings.expRoleSelect.options
    );

    this.legacySettings = this._clientManagementService.getClientSettings(
      this.teamID
    );

    //this.retrieveLabels()

    if (this.targetUser !== undefined) this.userStatus = this.targetUser.status;

    if (
      this.targetUser !== undefined &&
      (this.targetUser.meta === undefined || this.targetUser.meta === null)
    )
      this.targetUser.meta = {};

    if (this.user.role_type_id === 1 || this.user.role_type_id === 2)
      this.adminLevel = true;
    this.retrieveLabels();

    this.btnLabel.reset = `Add Another ${this.TitleCase.transform(
      this.labels.user.singular
    )}`;

    this.retrieveToken();
    this.dynamicCopy();
    this.retrieveUserData();

    this._mediaManagementService.updateMediaMetaDone$.subscribe((data: any) => {
      this.postRequestHandler(this.action, this.finalUser);
    });
  }

  private async retrieveUserData() {

    if (this.showPersona && (this.expPersonaSelect === undefined || this.expPersonaSelect.length === 0)) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

      const options = {
        headers: headers,
      };

      let retrievePersonas =
        await this._xrPlatformRestService.retrieveEntityCollection(
          "personas",
          this.teamID,
          "findpersonasbyclient",
          options,
          true
        ).toPromise();

      this.processPersonas(retrievePersonas);
    }

    this.buildPreselected();
    this.makeFormValidatingGroup();

  }

  private processPersonas(incomingPersonas) {
    let personasSelect = [];

    let personas =
      incomingPersonas !== null && incomingPersonas.personas !== undefined
        ? incomingPersonas.personas
        : [];

    personas.forEach((persona) => {
      let thisPersonaSelect = {
        value: persona.id,
        label: persona.persona_name,
      };

      personasSelect.push(thisPersonaSelect);
    });

    this.expPersonaSelect = personasSelect;
  }

  //restricts dropdown of system capabilities
  //super admins have access to all caps
  //admins have access to all caps *except* admin
  //other role types have no access
  private processAdminCaps() {
    if (this.user.role_type_id === 2) {
      this.adminCaps.forEach((cap, index) => {
        if (parseInt(cap.value) === 2 || parseInt(cap.value) === 1)
          this.adminCaps.splice(index, 1);
      });
    }
  }

  /*private retrieveLabels(): void {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }*/

  ngAfterViewInit() {
    this.mainModal =
      this.elementRef.nativeElement.parentElement.parentElement.parentElement;
  }

  private formatAdminCaps(caps) {
    let adminCaps = [];
    for (let key in caps) {
      if (caps[key].is_default) {
        adminCaps.push({
          label: this._userService.systemCapabilityLabelling(
            caps[key].label,
            +caps[key].value
          ),
          value: +caps[key].value.toString(),
        });
      }
    }

    return adminCaps;
  }

  private formatPersonaSelect(caps) {
    let personaCapps = [];
    for (let key in caps.options) {
      if (caps.options[key].is_default) {
        personaCapps.push({
          label: caps.options[key].label,
          value: +caps.options[key].value.toString(),
        });
      }
    }

    return personaCapps;
  }

  private formatRoleSelect(roles) {
    let roleSelect = [];
    for (let key in roles) {
      if (roles[key].is_default) {
        roleSelect.push({ label: roles[key].label, value: +roles[key].value.toString() });
      }
    }

    return roleSelect;
  }

  private formatAvatarsSelect(caps) {
    let avatarCapps = [];
    for (let key in caps.options) {
      if (caps.options[key].is_default) {
        avatarCapps.push({
          label: caps.options[key].label,
          value: +caps.options[key].value.toString(),
        });
      }
    }

    return avatarCapps;
  }

  public dynamicCopy() {
    this.continueType = "reset";

    if (this.action) {
      switch (this.action) {
        case "add":
          this.msgs.actionMsg =
            "Fill in the following fields to add a new " +
            this.labels.user.singular;
          this.btnLabel.main = "Add";
          this.formTitle = "Add " + this.labels.user.singular;
          this.formType = "add_user";
          this.formIcon = "Add";
          break;
        case "update":
          this.msgs.actionMsg =
            "Update the following " + this.labels.user.singular;
          this.btnLabel.main = "Update";
          this.formTitle =
            "Update " + this.TitleCase.transform(this.labels.user.singular);
          this.formType = "update_user";
          this.continueType = "continue";
          this.formIcon = "edit";
          break;
        case "update-self":
          this.msgs.actionMsg = "Update Your Account Details";
          this.btnLabel.main = "Update";
          this.formTitle = "Update Account";
          this.formType = "update_user";
          this.continueType = "continue";
          this.formIcon = "edit";
          break;
        case "delete":
          this.msgs.actionMsg =
            "Delete this " +
            this.labels.user.singular +
            " from the system?";
          this.btnLabel.main = "Delete";
          this.formTitle = "Delete " + this.labels.user.singular;
          this.formType = "delete_user";
          this.continueType = "none";
          this.formIcon = "trash";
          break;
        case "restore":
          this.msgs.actionMsg = this.targetUser.error === "username_exists_deleted" ? `A deleted user exists with the username ${this.targetUser.username}. Would you like to restore this user?` : `A deleted user exists with the email ${this.targetUser.email}. Would you like to restore this user?`;
          this.btnLabel.main = "Restore";
          this.formTitle = "Restore " + this.labels.user.singular;
          this.formType = "restore_user";
          this.continueType = "none";
          this.formIcon = "undo";
          break;
      }
    }
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private buildPreselected() {
    this.preSelected.username =
      this.targetUser !== undefined ? this.targetUser.username : null;
    this.preSelected.first_name =
      this.targetUser !== undefined ? this.targetUser.first_name : null;
    this.preSelected.last_name =
      this.targetUser !== undefined ? this.targetUser.last_name : null;
    this.preSelected.email =
      this.targetUser !== undefined ? this.targetUser.email : null;
    this.preSelected.id =
      this.targetUser !== undefined ? this.targetUser.id : null;
    this.preSelected.role_type_id =
      this.targetUser !== undefined
        ? this.targetUser.role_type_id
        : this.userRoleDefault;
    this.preSelected.meta.role =
      this.targetUser !== undefined && this.targetUser.meta.role !== undefined
        ? this.targetUser.meta.role
        : 3;
    this.preSelected.meta.persona_id =
      this.targetUser !== undefined &&
        this.targetUser.meta.persona_id !== undefined
        ? this.targetUser.meta.persona_id
        : null;

    console.log("this.action in buildPreselected", this.action)

    this.viewOnly = {
      username: this.action === "delete" || this.action === "restore" ? "disabled" : null,
      first_name: this.action === "delete" || this.action === "restore" ? "disabled" : null,
      last_name: this.action === "delete" || this.action === "restore" ? "disabled" : null,
      email: this.action === "delete" || this.action === "restore" ? "disabled" : null,
      meta: this.action === "delete" || this.action === "restore" ? "disabled" : null,
    };

    //to account for account management page limitations
    if (this.sourceType === "account" && !this.adminLevel) {
      this.viewOnly.username = "disabled";
      this.viewOnly.email = "disabled";
    }

    if (this.targetUser !== undefined) {
      this.selectedUser = this.targetUser;
    }

    console.log("this.viewOnly", this.viewOnly)
  }

  private makeFormValidatingGroup() {

    console.log("this.user.role_type_id", this.user.role_type_id);
    console.log("this.action", this.action);
    console.log("this.userStatus", this.userStatus);

    this.userForm = new UntypedFormGroup({
      username: new UntypedFormControl(
        { value: this.preSelected.username, disabled: this.userStatus === 'invited' },
        Validators.required
      ),
      first_name: new UntypedFormControl(
        { value: this.preSelected.first_name, disabled: this.userStatus === 'invited' },
        Validators.required
      ),
      last_name: new UntypedFormControl(
        { value: this.preSelected.last_name, disabled: this.userStatus === 'invited' },
        Validators.required
      ),
      email: new UntypedFormControl(
        { value: this.preSelected.email, disabled: this.userStatus === 'invited' || this.userStatus === 'accepted' },
        Validators.compose([Validators.required, Validators.email])
      ),
      role_type_id: new UntypedFormControl(
        { value: this.preSelected.role_type_id, disabled: this.user.role_type_id > 2 && this.action === 'update-self' },
        Validators.required
      ),
      role: new UntypedFormControl({ value: this.preSelected.meta.role, disabled: this.user.role_type_id > 2 && this.action === 'update-self' }),
    });

    if (this.showPersona && !this.viewOnly.meta) {
      this.userForm.addControl(
        "persona_id",
        new UntypedFormControl(
          { value: this.preSelected.meta.persona_id, disabled: this.user.role_type_id > 2 && this.action === 'update-self' },
          Validators.required
        )
      );
    }

    this.userForm.valueChanges.subscribe((data) => {
      this.isClean = false;
    });

    this.formLoading = false;
  }

  get username() {
    return this.userForm.get("username");
  }

  get first_name() {
    return this.userForm.get("first_name");
  }

  get last_name() {
    return this.userForm.get("last_name");
  }

  get email() {
    return this.userForm.get("email");
  }

  get role_type_id() {
    return this.userForm.get("role_type_id");
  }

  get role() {
    return this.userForm.get("role");
  }

  get persona_id() {
    return this.userForm.get("persona_id");
  }

  public openPasswordModal() {
    this.modalOptions.data = {
      user: this.user,
      targetUser: this.user,
      teamID: this.teamID,
    };

    this.pwFrame = this.modalService.show(
      EditPasswordComponent,
      this.modalOptions
    );
  }

  public async sendUserReset(thisUser) {

    let message = "Sending password reset";
    let targetURL = `/users/cognito/${this.teamID}/reset-password`;

    let notification = this._notificationService.savingNotification(message);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const httpOptions = {
      headers: headers,
    };

    let data = {
      email: thisUser.email,
    }

    let response = await this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "post",
      data,
      httpOptions
    ).toPromise().catch((error) => {
      this.formState = "pending";
      this._notificationService.clearNotification(notification);
      this._notificationService.errorNotification('Error sending password reset');
      return Promise.reject(error);
    });

    this._notificationService.clearNotification(notification);

    if (response !== undefined) {
      this._notificationService.successNotification('Password reset sent');
    } else {
      this._notificationService.errorNotification('Error sending password reset');
    }

  }


  public manageUser() {
    let actionAdj = this.action.replace(/e$/, "");
    this.notification = this._notificationService.savingNotification(
      `${this.TitleCase.transform(actionAdj)}ing ${this.labels.user.singular}`
    );

    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "";
    let formValues = this.userForm.value;
    this.formState = "processing";
    let action = this.action;

    if (this.action === "delete") {
      this.msgs.processingMsg = "";
      this.msgs.processingMsg = `<span class='loading-msg'>Removing ${this.TitleCase.transform(
        this.labels.user.singular
      )}</span>`;
      action = "delete";
    } else if (this.action === "restore") {
      this.msgs.processingMsg = "";
      this.msgs.processingMsg = `<span class='loading-msg'>Restoring ${this.TitleCase.transform(
        this.labels.user.singular
      )}</span>`;
      action = "restore";
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const httpOptions = {
      headers: headers,
    };

    let meta: any = {};

    if (action === "update") {
      meta = this.targetUser.meta;
    }

    meta.role = formValues.role;

    if (this.showPersona) {
      meta.persona_id = formValues.persona_id;
    }

    //meta cleanup on formValues
    delete formValues.role;
    delete formValues.persona_id;

    if (action === "add") {
      this.msgs.processingMsg = `<span class='loading-msg'>Adding ${this.TitleCase.transform(
        this.labels.user.singular
      )}</span>`;

      let userValues: any;

      userValues = {
        domain: this.domain,
        email: formValues.email,
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        role_type_id:
          formValues.role_type_id === null
            ? this.userRoleDefault
            : formValues.role_type_id,
        team_id: this.teamID,
        username: formValues.username.trim(),
      };

      if (Object.keys(meta).length) userValues.meta = meta;

      const body = JSON.stringify(userValues);

      let addUser = this._xrPlatformRestService.addUser(body, httpOptions);

      addUser.subscribe(
        (response) => {
          this.finalUser = response;

          if (this.finalUser.status === "error") {

            this.handleErrors(this.finalUser.error, response);

          } else {
            this.postRequestHandler(action, response);
          }
        },
        (err) => {
          this.btnLabel.retry = "Retry";

          let errorBody = err;

          if (err._body !== undefined) {
            errorBody = JSON.parse(err._body);
          } else if (err.error !== undefined) {
            errorBody = err.error;
          }

          let errorMsg = errorBody.error;

          this.handleErrors(errorMsg, userValues);
        }
      );
    } else if (action === "update" || action === "update-self") {
      this.msgs.processingMsg = "<span class='loading-msg'>Updating</span>";
      //preserving meta on account update
      formValues.meta = this.targetUser.meta;

      //only include email and username if they are new
      if (
        formValues.username !== undefined &&
        formValues.username.trim() === this.selectedUser.username.trim()
      ) {
        delete formValues.username;
      }

      if (
        formValues.email !== undefined &&
        formValues.email === this.selectedUser.email
      ) {
        delete formValues.email;
      }

      const options = JSON.stringify(formValues);

      let updateUser = this._xrPlatformRestService.manageUsers(
        this.targetUser.id,
        "update-by-admin",
        options,
        httpOptions
      );

      updateUser.subscribe(
        (response) => {
          this.finalUser = response;
          if (this.finalUser.status === "error") {
            this.handleErrors(this.finalUser.error, response);
          } else {
            this.postRequestHandler(action, response);
          }
        },
        (err) => {
          this.btnLabel.retry = "Retry";

          let errorBody = err;

          if (err._body !== undefined) {
            errorBody = JSON.parse(err._body);
          } else if (err.error !== undefined) {
            errorBody = err.error;
          }

          let errorMsg = errorBody.error;

          this.handleErrors(errorMsg, formValues);
        }
      );
    } else if (action === "delete") {
      this.msgs.processingMsg = `<span class='loading-msg'>Deleting ${this.TitleCase.transform(
        this.labels.user.singular
      )}</span>`;
      let handleUser = this._xrPlatformRestService.manageUsers(
        this.targetUser.id,
        action,
        {},
        httpOptions
      );

      handleUser.subscribe(
        (response) => {
          this.finalUser = response;

          //####CLEANUP####
          this.removeUserFromGroupsInit(
            this.targetUser.id,
            httpOptions,
            response,
            action
          );
        },
        (err) => {
          this.msgs.processingMsg = "";
          this.btnLabel.retry = "Retry";
          let errorMsg = JSON.parse(err._body);
          this.msgs.errorMsg = errorMsg.error;
        }
      );
    } else if (action === "restore") {
      let restoreUser = this._xrPlatformRestService.restfulAPIQuery(
        `/user/${this.targetUser.id}/restore`,
        "put",
        {},
        httpOptions
      );

      restoreUser.subscribe(
        (response) => {
          this.finalUser = response;
          this.postRequestHandler(action, response);
        },
        (err) => {
          this.msgs.processingMsg = "";
          this.btnLabel.retry = "Retry";
          let errorMsg = JSON.parse(err._body);
          this.msgs.errorMsg = errorMsg.error;
        }
      );
    }
  }

  private async handleErrors(error, user) {

    this._notificationService.errorNotification(
      `Issue adding ${this.labels.user.singular}, please see errors or actions below.`
    );

    if (error === "username_exists_deleted" || error === "email_exists_deleted") {
      let handleRestore = this.openUserModal("restore", user);
      this.formState = "success";
    } else {

      if (error === "username_exists") {

        this.userForm.controls.username.setErrors({ 'username_exists': true });

      } else if (error === "email_exists") {

        this.userForm.controls.email.setErrors({ 'email_exists': true });

      }

    }

  }

  public openUserModal(action, thisUser?): Promise<{ status: string; user: any; }> {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "delete-users-container"
    };

    this.modalOptions.data = {
      user: thisUser,
      users: [],
      targetUser: thisUser,
      teamID: this.teamID,
      action: action,
      domain: "",
      sourceType: "user",
    };

    this.restoreFrame = this.modalService.show(
      EditUserComponent,
      this.modalOptions
    );

    return new Promise((resolve, reject) => {
      this.restoreFrame.content.outgoing.subscribe((changedData) => {
        if (changedData.action !== undefined) {
          let actionAdj = action.replace(/e$/, "");
          this._notificationService.successNotification(
            `${this.TitleCase.transform(
              this.labels.user.singular
            )} successfully ${actionAdj}ed`
          );

          switch (changedData.action) {
            case "restore":
              if (changedData.user !== null) {
                let outgoingData = {
                  action: changedData.action,
                  user: changedData.user
                };

                this.outgoing.next(outgoingData);

                resolve({ status: "restored", user: changedData.user });
              } else {
                resolve({ status: "not_restored", user: null });
              }
              break;
            default:
              resolve({ status: "not_restored", user: null });
          }
        } else {
          resolve({ status: "not_restored", user: null });
        }
      });
    });
  }

  private postRequestHandler(action, response) {
    if (action === "add") {
      this.msgs.processingMsg = "";
      this.msgs.statusMsg = `${this.TitleCase.transform(
        this.labels.user.singular
      )} Successfully Added.`;
      this.formState = "success";

      let outgoingData = {
        action: this.action === undefined ? "add" : this.action,
        user: response.user,
      };

      this.outgoing.next(outgoingData);
    } else if (action === "update" || action === "update-self") {
      this.msgs.processingMsg = "";
      this.msgs.statusMsg = `${this.TitleCase.transform(
        this.labels.user.singular
      )} Successfully Updated.`;
      this.btnLabel.retry = "Continue Editing";
      this.formState = "success";

      let outgoingData = {
        action: this.action === undefined ? "add" : this.action,
        user: response.user,
      };

      this.outgoing.next(outgoingData);
    } else if (action === "delete") {
      let deletedUser = response.user;
      this.msgs.processingMsg = "";
      this.msgs.statusMsg = `${this.TitleCase.transform(
        this.labels.user.singular
      )} Successfully Deleted`;
      this.formState = "success";
      let outgoingData = {
        action: "delete",
        user: deletedUser,
      };
      this.outgoing.next(outgoingData);
    } else if (action === "restore") {
      this.msgs.processingMsg = "";
      this.msgs.statusMsg = `${this.TitleCase.transform(
        this.labels.user.singular
      )} Successfully Restored`;
      this.formState = "success";
      let outgoingData = {
        action: "restore",
        user: response.user,
      };
      this.outgoing.next(outgoingData);
    }

    this._notificationService.clearNotification(this.notification);
    this.userFrame.hide();
  }

  public removeUserFromGroupsInit(user_id, options, deletedUser, action) {
    this.msgs.processingMsg = `<span class='loading-msg'>Removing ${this.TitleCase.transform(
      this.labels.user.singular
    )} From ${this.TitleCase.transform(this.labels.userGroup.plural)}</span>`;

    let getTeamGroups = this._xrPlatformRestService.retrieveEntityCollection(
      "group/team",
      "users",
      this.teamID,
      options,
      true
    );

    getTeamGroups.subscribe(
      (response) => {
        if (response.members !== undefined && response.members.length) {
          let groups = this.userGroups(response.members, user_id);

          this.removeUserFromGroups(
            groups,
            user_id,
            options,
            deletedUser,
            action
          );
        } else {
          this.removeUserFromScheduledEventsInit(options, deletedUser, action);
        }
      },
      (error) => {
        //@todo - should errors here restore the user?
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = `There was an error deleting this ${this.labels.user.singular} : could not retrieve team ${this.labels.userGroup.plural}.`;
      }
    );
  }

  private userGroups(members, user_id) {
    let groupsOut = [];

    members.forEach((member) => {
      if (parseInt(member[0].other_id) === user_id) {
        groupsOut = member[0].groups;
      }
    });

    return groupsOut;
  }

  private async removeUserFromGroups(
    groups,
    user_id,
    options,
    deletedUser,
    action
  ) {
    if (!groups.length)
      return this.removeUserFromScheduledEventsInit(
        options,
        deletedUser,
        action
      );
    await Promise.all(
      groups.map(async (group) => {
        const response = await this.updateGroup(group, options, user_id);
      })
    );

    this.removeUserFromScheduledEventsInit(options, deletedUser, action);
  }

  private updateGroup(group, options, user_id) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    let thisMember = {
      members: [
        {
          reference_id: user_id,
          type: "user",
        },
      ],
    };

    let removeOptions = {
      body: JSON.stringify(thisMember),
      childEntity: {
        parentEntityID: parseInt(group.group_id),
        entityType: "members",
      },
    };

    let manageGroup = this._xrPlatformRestService.manageEntity(
      "group",
      this.teamID,
      "remove",
      removeOptions,
      headers
    );

    return manageGroup.toPromise();
  }

  private removeUserFromScheduledEventsInit(options, deletedUser, action) {
    let body = {
      deleted: false,
      invitees: [
        {
          invitee_type: "user",
          invitee_id: this.targetUser.id,
        },
      ],
    };

    let retrieveScheduledEvents =
      this._xrPlatformRestService.scheduledExperiences(
        "filtered",
        JSON.stringify(body),
        options
      );

    retrieveScheduledEvents.subscribe(
      (response) => {
        if (
          response.scheduled_events !== undefined &&
          response.scheduled_events.length
        ) {
          this.removeUserFromScheduledEvents(
            response.scheduled_events,
            options,
            deletedUser,
            action
          );
        } else {
          this.postRequestHandler(action, deletedUser);
        }
      },
      (error) => {
        //@todo - should errors here restore the user?
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = `There was an error deleting this ${this.labels.user.singular}: could not retrieve ${this.labels.event.plural}.`;
      }
    );
  }

  private async removeUserFromScheduledEvents(
    events,
    options,
    deletedUser,
    action
  ) {
    if (!events.length) return this.postRequestHandler(action, deletedUser);
    await Promise.all(
      events.map(async (event) => {
        const response = await this.updateEvent(event, options);
      })
    );

    this.postRequestHandler(action, deletedUser);
  }

  private updateEvent(event, options) {
    let removeList = [
      {
        scheduled_event_id: event.id,
        invitee_type: "user",
        invitee_id: this.targetUser.id,
      },
    ];

    let body = {
      entity: "schedule",
      entity_id: event.id,
      toRemove: removeList,
    };

    let removeUsers = this._xrPlatformRestService.scheduledExperiences(
      "remove",
      body,
      options
    );

    return removeUsers.toPromise();
  }

  public getDeletedUserData(users, userValue, targetProperty) {
    return users.filter((user) => {
      return user[targetProperty] === userValue && user.deleted;
    });
  }

  public closePopOvers() {
    if (this.popOverTrigger !== undefined) this.popOverTrigger.hide();
    if (this.popOverTriggerTop !== undefined) this.popOverTriggerTop.hide();
  }

  public closeModal(overrule?) {
    if (overrule === undefined) overrule = false;

    if (!this.isClean && !overrule) return false;

    if (this.action === "restore") {
      let outgoingData = {
        action: "restore",
        user: null,
      }

      this.outgoing.next(outgoingData);
    }

    this.userFrame.hide();
  }


  public modalClick(event) {


    let isPopover = false;

    //check to see if this click is coming from the pop up triggers
    let target = event.target;
    let parent = target.parentElement;

    //see if fa-xmark is in the parents class list (indicates fa-icon xmark)
    if (parent !== null) {
      if (parent.classList.contains("fa-xmark")) isPopover = true;
      if (parent.classList.contains("cancel-alert")) isPopover = true;
    }

    //see if target class list has the cancel-alert class
    if (target.classList.contains("cancel-alert")) isPopover = true;

    //see if target class list has the fa-xmark class
    if (target.classList.contains("fa-xmark")) isPopover = true;

    if (!isPopover) this.closePopOvers();

  }
}
