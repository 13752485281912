import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../../environments/environment";
import { ClientManagementService } from "./../../utilities/client-management.service";

import { catchError, shareReplay, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { CoolLocalStorage } from "@angular-cool/storage";
import { NeedAuthGuardService } from "../../utilities/need-auth-guard.service";
import { Router } from "@angular/router";

export type Experience = {
  name: string;
  description: string;
  scheduled_users: number;
};

@Injectable({
  providedIn: "root",
})
export class XrPlatformRestService {
  private proxyURL: string = environment.proxyURL;
  private restURL: string = this._clientManagementService.getRESTurl();
  private resetURL_v2 = this._clientManagementService.getRESTurl("v2");
  private vendorURL =
    this._clientManagementService.getVendorURL("userDataSystem");

  private getRestURL(version): string {
    return this._clientManagementService.getRESTurl(version);
  }

  constructor(
    private http: HttpClient,
    private _clientManagementService: ClientManagementService,
    private coolLocalStorage: CoolLocalStorage,
    private router: Router
  ) { }

  private getToken() {
    return this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public addUser(body: any, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .post(this.restURL + "/user/create", body, options)
      .pipe(catchError(this.handleError));
  }

  public sendResetPasswordLink(body: any, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .post(this.resetURL_v2 + "/forgot-password/send-token", body, options)
      .pipe(catchError(this.handleError));
  }

  public getUserByToken(body: any, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .post(this.restURL + "/forgot-password/lookup-user", body, options)
      .pipe(catchError(this.handleError));
  }

  public manageTeam(teamID, action, options?, headers?): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    if (headers === undefined) {
      headers = {};
    }

    return this.http
      .put(this.restURL + "/team/" + teamID + "/" + action, options, headers)
      .pipe(shareReplay(1, 1000))
      .pipe(catchError(this.handleError));
  }

  public manageUsers(userID, action, options?, headers?): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    if (headers === undefined) {
      headers = {};
    }

    return this.http
      .put(this.restURL + "/user/" + userID + "/" + action, options, headers)
      .pipe(shareReplay(1, 1000))
      .pipe(catchError(this.handleError));
  }

  public manageEntity(
    entityType,
    entityID,
    action,
    options?,
    headers?
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    if (headers === undefined) {
      headers = {};
    }

    let httpOptions = {
      headers: headers,
    };

    let restURL = this.restURL + "/" + entityType;

    if (action === "update") {
      let body = options.body;

      return this.http
        .put(
          this.restURL + "/" + entityType + "/" + entityID + "/" + action,
          body,
          httpOptions
        )
        .pipe(shareReplay(1, 1000))
        .pipe(catchError(this.handleError));
    } else if (action === "delete") {
      const deleteEndpoint =
        this.restURL + "/" + entityType + "/" + entityID + "/" + action;

      return this.http
        .delete(deleteEndpoint, httpOptions)
        .pipe(shareReplay(1, 1000))
        .pipe(catchError(this.handleError));
    } else if (action === "remove") {
      let body = options.body;

      if (options.childEntity !== undefined) {
        restURL +=
          "/" +
          options.childEntity.parentEntityID +
          "/" +
          options.childEntity.entityType;
      } else {
        restURL =
          this.restURL + "/" + entityType + "/" + entityID + "/" + action;
      }

      return this.http
        .put(restURL, body, httpOptions)
        .pipe(shareReplay(1, 1000))
        .pipe(catchError(this.handleError));
    }

    //default is add
    let body = options.body;

    if (options.childEntity !== undefined) {
      restURL +=
        "/" +
        options.childEntity.parentEntityID +
        "/" +
        options.childEntity.entityType;
    }

    return this.http
      .post(restURL, body, httpOptions)
      .pipe(shareReplay(1, 1000))
      .pipe(catchError(this.handleError));
  }

  public retrieveEntityData(
    entity_type,
    entity_id,
    options?,
    useProxy?
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    let proxyURL = "";
    if (useProxy !== undefined && useProxy) proxyURL = this.proxyURL;

    return this.http
      .get(
        proxyURL + this.restURL + "/" + entity_type + "/" + entity_id,
        options
      )
      .pipe(catchError(this.handleError));
  }

  public retrieveEntityCollection(
    entity_type,
    collection,
    entity_id,
    options?,
    includeDeleted?
  ): Observable<any> {
    if (options === undefined) options = {};

    if (includeDeleted === undefined) includeDeleted = false;

    return this.http
      .get<any[]>(
        this.restURL + "/" + entity_type + "/" + entity_id + "/" + collection,
        options
      )
      .pipe(
        map((event: any) => {
          const data = event;

          if (includeDeleted) {
            return data;
          } else {
            return data.filter((item) => {
              return item.deleted === undefined || !item.deleted;
            });
          }
        }),
        catchError(this.handleError)
      );
  }

  public retrieveJSON(path: string): Observable<any> {
    //temporary cache busting
    //@todo: replace this with something more robust
    path = path + "?v=0.0.7";

    return this.http.get(path).pipe(catchError(this.handleError));
  }

  public scheduledExperiences(
    action,
    body: any,
    options?: any
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    const endpoint = this.restURL + "/schedule/" + action;

    if (
      action === "new-scheduled-event" ||
      action === "filtered/flag" ||
      action === "filtered"
    ) {
      return this.http
        .post(endpoint, body, options)
        .pipe(catchError(this.handleError));
    } else if (action === "schedule-invitee") {
      const scheduleEndpoint =
        this.restURL + "/schedule/" + options.schedule_id + "/invitees";
      return this.http
        .post<any[]>(scheduleEndpoint, body, options)
        .pipe(catchError(this.handleError));
    } else if (action === "schedule-assets") {
      const scheduleEndpoint =
        this.restURL + "/schedule/" + options.schedule_id + "/assets";
      return this.http
        .post<any[]>(scheduleEndpoint, body, options)
        .pipe(catchError(this.handleError));
    } else if (action === "schedule-assets-remove") {
      const scheduleEndpoint =
        this.restURL + "/schedule/" + options.schedule_id + "/assets/remove";
      return this.http
        .put<any[]>(scheduleEndpoint, body, options)
        .pipe(catchError(this.handleError));
    } else if (action === "delete") {
      const deleteEndpoint =
        this.restURL + "/" + body.entity + "/" + body.entity_id;

      return this.http
        .delete(deleteEndpoint, options)
        .pipe(catchError(this.handleError));
    } else if (action === "remove") {
      const removeEndpoint =
        this.restURL + "/" + body.entity + "/" + body.entity_id + "/invitees";
      let removeBody = JSON.stringify(body.toRemove);
      return this.http
        .put<any[]>(removeEndpoint, removeBody, options)
        .pipe(catchError(this.handleError));
    } else if (action === "assets-remove") {
      const removeEndpoint =
        this.restURL +
        "/" +
        body.entity +
        "/" +
        body.entity_id +
        "/assets/remove";
      let removeBody = JSON.stringify(body.toRemove);
      return this.http
        .put<any[]>(removeEndpoint, removeBody, options)
        .pipe(catchError(this.handleError));
    } else if (action === "invitee-meta-update") {
      const updateEndpoint =
        this.restURL +
        "/" +
        body.entity +
        "/" +
        body.entity_id +
        "/invitee/update-meta";
      let updateBody = JSON.stringify(body.toUpdate);

      return this.http
        .put<any[]>(updateEndpoint, updateBody, options)
        .pipe(catchError(this.handleError));
    } else if (action === "remove-prop") {
      const updateEndpoint =
        this.restURL +
        "/schedule/" +
        body.eventId +
        "/prop/" +
        body.propId +
        "/remove";
      let updateBody = JSON.stringify(body.toUpdate);

      return this.http
        .put<any[]>(updateEndpoint, updateBody, options)
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .put(endpoint, body, options)
        .pipe(catchError(this.handleError));
    }
  }

  public logout() {
    const headers = {
      "app-skip-check": "true",
    };
    let options = {
      headers: headers,
    };

    return this.http.get(this.proxyURL + this.restURL + "/logout", options);
  }

  private handleError = (error: HttpErrorResponse | any) => {
    console.error("ApiService::handleError", error);

    if (error instanceof HttpErrorResponse) {
      console.log("handling error");
    }

    return error;
  };

  public chunkUpload(data, options): Observable<any> {
    return this.http
      .post(this.restURL + "/asset/chunking/upload", data, options)
      .pipe(catchError(this.handleError));
  }

  public chunk(body: any, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .post(this.restURL + "/asset/chunking/upload", body, options)
      .pipe(catchError(this.handleError));
  }

  public getAvailableUsers(team: number, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .get(this.restURL + "/team/" + team + "/users", options)
      .pipe(catchError(this.handleError));
  }

  public enableUserShare(
    body: any,
    user_to_add: number,
    asset_id: number,
    options?: any
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .post(
        this.restURL + "/sharelevels/enable/" + user_to_add + "/" + asset_id,
        body,
        options
      )
      .pipe(catchError(this.handleError));
  }

  public disableUserShare(
    body: any,
    user_to_disable: number,
    asset_id: number,
    options?: any
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .put(
        this.restURL +
        "/sharelevels/disable/" +
        user_to_disable +
        "/" +
        asset_id,
        body,
        options
      )
      .pipe(catchError(this.handleError));
  }

  public changeOwnership(body: any, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .put(this.restURL + "/asset/change-owner", body, options)
      .pipe(catchError(this.handleError));
  }

  public deleteAsset(uuid: any, full: string, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }
    return this.http
      .delete(this.restURL + "/asset/" + uuid + "/delete-" + full, options)
      .pipe(catchError(this.handleError));
  }

  public getRooms(team_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .get(this.restURL + "/team/" + team_id + "/rooms", options)
      .pipe(catchError(this.handleError));
  }

  public getLocations(team_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .get(this.restURL + "/team/" + team_id + "/locations", options)
      .pipe(catchError(this.handleError));
  }

  public getBrandingSpaces(team_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .get(this.restURL + "/team/" + team_id + "/branding-space", options)
      .pipe(catchError(this.handleError));
  }

  public getLegacyExperiences(team_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .get(this.restURL + "/team/" + team_id + "/legacy-experiences", options)
      .pipe(catchError(this.handleError));
  }

  public getExperienceBrandingSpaces(
    experience_id,
    options?: any
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .get(
        this.restURL + "/experience/" + experience_id + "/branding-space",
        options
      )
      .pipe(catchError(this.handleError));
  }

  public addRoom(team_id, data, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .post(this.restURL + "/team/" + team_id + "/room", data, options)
      .pipe(catchError(this.handleError));
  }

  public addLocation(team_id, data, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .post(this.restURL + "/team/" + team_id + "/location", data, options)
      .pipe(catchError(this.handleError));
  }

  public addBrandingSpace(team_id, data, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .post(
        this.restURL + "/team/" + team_id + "/branding-space",
        data,
        options
      )
      .pipe(catchError(this.handleError));
  }

  public addExperience(data, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .post(this.restURL + "/team/experience", data, options)
      .pipe(catchError(this.handleError));
  }

  public addExperienceBrandingSpace(
    experience_id,
    data,
    options?: any
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .post(
        this.restURL + "/experience/" + experience_id + "/branding-space",
        data,
        options
      )
      .pipe(catchError(this.handleError));
  }

  public editRoom(team_id, room_id, data, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .put(
        this.restURL + "/team/" + team_id + "/room/" + room_id,
        data,
        options
      )
      .pipe(catchError(this.handleError));
  }

  public editLocation(
    team_id,
    location_id,
    data,
    options?: any
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .put(
        this.restURL + "/team/" + team_id + "/location/" + location_id,
        data,
        options
      )
      .pipe(catchError(this.handleError));
  }

  public editBrandingSpace(
    team_id,
    space_id,
    data,
    options?: any
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .put(
        this.restURL + "/team/" + team_id + "/branding-space/" + space_id,
        data,
        options
      )
      .pipe(catchError(this.handleError));
  }

  public editExperience(experience_id, data, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .put(
        this.restURL + "/team/experience/" + experience_id + "/update",
        data,
        options
      )
      .pipe(catchError(this.handleError));
  }

  public deleteExperienceBrandingSpace(
    experience_id,
    space_id,
    options?: any
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .delete(
        this.restURL +
        "/experience/" +
        experience_id +
        "/branding-space/" +
        space_id +
        "/remove",
        options
      )
      .pipe(catchError(this.handleError));
  }

  public addRoomEvent(event_id, data, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .post(this.restURL + "/schedule/" + event_id + "/room", data, options)
      .pipe(catchError(this.handleError));
  }

  public addParentGroup(event_id, data, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .post(
        this.restURL + "/group/class/newmainclass/" + event_id,
        data,
        options
      )
      .pipe(catchError(this.handleError));
  }

  public addGroupChild(group_id, data, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .post(
        this.restURL + "/group/class/" + group_id + "/members",
        data,
        options
      )
      .pipe(catchError(this.handleError));
  }

  public getEventsByTeam(team_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .get(this.restURL + "/teams/events/" + team_id, options)
      .pipe(catchError(this.handleError));
  }

  public getEventsByTeamSimple(team_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .get(this.restURL + "/teams/events/" + team_id + "/simple", options)
      .pipe(catchError(this.handleError));
  }

  public retrieveTeamGroups(team_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .get(this.restURL + "/team/" + team_id + "/class/groups", options)
      .pipe(catchError(this.handleError));
  }

  public deleteParentGroup(group_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .delete(this.restURL + "/group/class/newmainclass/" + group_id, options)
      .pipe(catchError(this.handleError));
  }

  public retrieveChildrenGroups(group_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .get(this.restURL + "/group/" + group_id + "/members", options)
      .pipe(catchError(this.handleError));
  }

  public deleteChildGroup(group_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .delete(this.restURL + "/group/" + group_id + "/delete", options)
      .pipe(catchError(this.handleError));
  }

  public retrieveEventConnections(event_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .get(this.restURL + "/event/connections/" + event_id, options)
      .pipe(catchError(this.handleError));
  }

  public addEventConnection(
    main_event_id,
    other_event_id,
    options?: any
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    const data = {
      event_a: main_event_id,
      event_b: other_event_id,
    };

    return this.http
      .post(this.restURL + "/event/connection", data, options)
      .pipe(catchError(this.handleError));
  }

  public deleteEventConnection(connection_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    options.body = {
      connection_id: connection_id,
    };

    return this.http
      .delete(this.restURL + "/event/connection", options)
      .pipe(catchError(this.handleError));
  }

  public restfulAPIQuery(
    url: string,
    action: string,
    data,
    options?: any,
    version?: string,
    useProxy?: boolean
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }
    if (version === undefined) version = "v1";

    let proxyURL = "";
    if (useProxy !== undefined && useProxy) proxyURL = this.proxyURL;

    if (action == "get") {
      return this.http
        .get(proxyURL + this.getRestURL(version) + url, options)
        .pipe(catchError(this.handleError));
    } else if (action == "post") {
      return this.http
        .post(proxyURL + this.getRestURL(version) + url, data, options)
        .pipe(catchError(this.handleError));
    } else if (action == "put") {
      return this.http
        .put(proxyURL + this.getRestURL(version) + url, data, options)
        .pipe(catchError(this.handleError));
    } else if (action == "delete") {
      return this.http
        .delete(proxyURL + this.getRestURL(version) + url, options)
        .pipe(catchError(this.handleError));
    } else {
      return null;
    }
  }

  public restfulAPIQueryThirdParty(
    url: string,
    action: string,
    data,
    options?: any,
    version?: string,
    useProxy?: boolean
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }
    if (version === undefined) version = "v1";

    let proxyURL = "";
    if (useProxy !== undefined && useProxy) proxyURL = this.proxyURL;

    if (action == "get") {
      return this.http
        .get(proxyURL + url, options)
        .pipe(catchError(this.handleError));
    } else if (action == "post") {
      return this.http
        .post(proxyURL + url, data, options)
        .pipe(catchError(this.handleError));
    } else if (action == "put") {
      return this.http
        .put(proxyURL + url, data, options)
        .pipe(catchError(this.handleError));
    } else if (action == "delete") {
      return this.http
        .delete(proxyURL + url, options)
        .pipe(catchError(this.handleError));
    } else {
      return null;
    }
  }

  public retrieveLocker(user_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http
      .get(
        this.restURL + "/asset/user_assets/" + user_id + "/locker/backpack",
        options
      )
      .pipe(catchError(this.handleError));
  }

  public retrieveSurveys(team_id, options?: any): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    return this.http.get(
      this.restURL + "/surveys/" + team_id + "/team/apb",
      options
    );
  }

  public userDataHandler(url: string, action: string, data): Observable<any> {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.getToken(),
    };
    let options = {
      headers: headers,
    };

    if (action == "get") {
      return this.http
        .get(this.restURL + url, options)
        .pipe(catchError(this.handleError));
    } else if (action == "post") {
      return this.http
        .post(this.restURL + url, data, options)
        .pipe(catchError(this.handleError));
    } else if (action == "put") {
      return this.http
        .put(this.restURL + url, data, options)
        .pipe(catchError(this.handleError));
    } else if (action == "delete") {
      return this.http
        .delete(this.restURL + url, options)
        .pipe(catchError(this.handleError));
    } else {
      return null;
    }
  }
}
