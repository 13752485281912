<div class="interactive-analytics-card aia-analytics">
  <div
  *ngIf="!gridLoading" class="d-flex justify-content-between align-items-center w-100 metrics-controls analytics-info"
  >
    <p class="mb-0">{{ eventInfo }} for {{ userInfo }}</p>
  </div>
  <div
    *ngIf="!noMenus && !gridLoading"
    class="d-flex justify-content-between align-items-center w-100 metrics-controls"
  >
    <div class="back-button">
      <a
        mdbBtn
        class="btn-flat p-0 m-0 back-button"
        mdbWavesEffect
        [routerLink]="backRoute"
        [queryParams]="queryParams"
        mdbTooltip="Back to AIA Session"
        placement="bottom"
      >
        <mdb-icon fas icon="chevron-left"></mdb-icon>
        Back
      </a>
    </div>
    <div
      *ngIf="!noMenus"
      class="right-side"
      style="display: flex; align-items: center; justify-content: center"
    >
      <div class="download-csv">
        <button
          style="width: 11rem; margin-top: 0.4rem"
          class="theme-modal-button btn update-btn"
          data-dismiss="modal"
          type="button"
          mdbBtn
          mdbWavesEffect
          (click)="onDownloadCSV()"
        >
          Download CSV
        </button>
      </div>
    </div>
  </div>
  <div class="readquest-analytics-highlevel analytics-card">
    <ng-container *ngIf="gridLoading">
      <!-- if content hasnt load -->
      <div
        class="loading-card d-flex align-content-center justify-content-center"
      >
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg === ''"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg !== ''"
        >
          <div
            class="alert alert-danger"
            role="alert"
            [innerHTML]="errorRetrievingMsg"
          ></div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!gridLoading">
      <mdb-tabset
        #availableTabs
        id="availableTabs"
        [buttonClass]="'classic-tabs'"
        class="admin-tab-styles"
        [contentClass]="'remove-card'"
        tabsButtonsClass="tab-nav-fluid"
        tabsContentClass="tab-content-fluid w-100"
      >
        <mdb-tab
          *ngIf="!noMenus"
          heading="Using Assessment Criteria"
          (select)="tabSelected($event, 'assessment')"
          ><ag-grid-angular
            style="width: 100%; height: 84vh"
            [columnDefs]="columnDefs"
            [autoSizeStrategy]="autoSizeStrategy"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData"
            [class]="themeClass"
            overlayNoRowsTemplate="No assessment criteria available"
            (gridReady)="onGridReady($event, 'assessment')"
          ></ag-grid-angular
        ></mdb-tab>
        <mdb-tab
          *ngIf="!noMenus"
          heading="Using Custom Rubrics"
          (select)="tabSelected($event, 'rubric')"
          s
        >
          <ag-grid-angular
            style="width: 100%; height: 84vh"
            [columnDefs]="rubricColumnDefs"
            [autoSizeStrategy]="autoSizeStrategy"
            [defaultColDef]="defaultColDef"
            [rowData]="rubricRowData"
            [class]="themeClass"
            overlayNoRowsTemplate="No custom rubrics available"
            (gridReady)="onGridReady($event, 'rubric')"
          ></ag-grid-angular>
        </mdb-tab>
        <mdb-tab *ngIf="noMenus" heading="Using Assessment Criteria"
          ><ag-grid-angular
            style="width: 100%; height: 94vh"
            [columnDefs]="columnDefs"
            [autoSizeStrategy]="autoSizeStrategy"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData"
            [class]="themeClass"
            overlayNoRowsTemplate="No assessment criteria available"
          ></ag-grid-angular
        ></mdb-tab>
        <mdb-tab *ngIf="noMenus" heading="Using Custom Rubrics">
          <ag-grid-angular
            style="width: 100%; height: 94vh"
            [columnDefs]="rubricColumnDefs"
            [autoSizeStrategy]="autoSizeStrategy"
            [defaultColDef]="defaultColDef"
            [rowData]="rubricRowData"
            [class]="themeClass"
            overlayNoRowsTemplate="No custom rubrics available"
          ></ag-grid-angular>
        </mdb-tab>
      </mdb-tabset>
    </ng-container>
  </div>
</div>
